import { ChartRow, Direction, StsGroup, StsMarker } from "../utils/types";
import { FocusType } from "./Chart";
import { WrittenDisplayProps } from "./Written";
import { css, cx } from "@linaria/core";
import { unit } from "./styles";
import { patternStyles, motifColors } from "./styles";
import { getStsCountFromStsGroup, isWSRow } from "../utils";
import { getHumanReadableSts } from "../utils/stsRenderer";

interface Props {
  row: ChartRow;
  rowID: number;
  direction: Direction;
  display: WrittenDisplayProps;
  stsMarkers?: StsMarker[];
  focus?: FocusType | undefined | null;
  showMergedRow?: boolean;
  className?: string;
}

const styles = {
  rowRS: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${unit / 2}px;
    div {
      white-space: nowrap;
      &:not(:last-child) {
        &:after {
          content: ", ";
        }
      }
    }
  `,
  rowWS: css`
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    flex-wrap: wrap-reverse;
    gap: ${unit / 2}px;
    div {
      &:not(:first-child) {
        &:after {
          content: ", ";
        }
      }
    }
  `,
  rowRSBlock: css`
    display: flex;
    flex-direction: column;
    margin-top: ${unit}px;
    padding-left: ${unit}px;
    padding-right: ${unit}px;
    padding-bottom: ${unit}px;

    div {
      width: fit-content;
      white-space: nowrap;
    }
  `,
  rowWSBlock: css`
    display: flex;
    flex-direction: column-reverse;
    margin-top: ${unit}px;
    padding-left: ${unit}px;
    padding-right: ${unit}px;
    padding-bottom: ${unit}px;
    div {
      width: fit-content;
      white-space: nowrap;
    }
  `,
  textSts: css`
    padding-left: ${unit / 4}px;
    padding-right: ${unit / 4}px;
  `,
};

interface ValidInfo {
  stsText: string[];
  stsMarker?: StsMarker;
}
type ValidStsGroup = ValidInfo & StsGroup;

export default function WrittenRow({
  row,
  rowID,
  direction,
  display,
  focus,
  stsMarkers = [],
  className,
}: Props) {
  const isWS = isWSRow(direction, rowID);

  const validStsGroups: ValidStsGroup[] = [];

  // For marker info
  let totalStsCount = 0;
  const stsMarkerPos = stsMarkers.map((s) => s.pos);
  let currentMarkerPosIndex = 0;
  let currentMarker: StsMarker | null = null;

  row.forEach((stsGroup, j) => {
    const stsText = getHumanReadableSts(stsGroup.sts, isWS);
    const validInfo: ValidInfo = { stsText };

    // check if marker point is in between two sts groups
    totalStsCount += getStsCountFromStsGroup(stsGroup, false);
    if (
      currentMarkerPosIndex < stsMarkerPos.length - 1 &&
      totalStsCount >= stsMarkerPos[currentMarkerPosIndex] &&
      totalStsCount < stsMarkerPos[currentMarkerPosIndex + 1]
    ) {
      currentMarker = stsMarkers[currentMarkerPosIndex];
      currentMarkerPosIndex++;
    }
    if (stsText.length > 0) {
      // Add marker to stitch group
      if (currentMarker != null) {
        validInfo.stsMarker = currentMarker;
      }
      validStsGroups.push({ ...stsGroup, ...validInfo });
      currentMarker = null;
    }
  });

  return (
    <div
      className={cx(
        isWS
          ? display === "inline"
            ? styles.rowWS
            : styles.rowWSBlock
          : display === "inline"
          ? styles.rowRS
          : styles.rowRSBlock,
        className
      )}
    >
      {validStsGroups.map((stsGroup, j) => {
        const { motif, stsText, stsMarker } = stsGroup;
        return (
          <div
            key={j}
            className={cx(
              styles.textSts,
              rowID === focus?.[0] &&
                j === focus?.[1] &&
                patternStyles.selectedSts,
              motif?.id != null && motifColors[motif?.id]
            )}
          >
            {stsMarker != null && !isWS && j > 0 && (
              <strong>sm{stsMarker.part ?? ""}, </strong>
            )}
            {stsText.join(", ")}
            {stsMarker != null && isWS && j < validStsGroups.length - 1 && (
              <strong>, sm{stsMarker.part ?? ""}</strong>
            )}
          </div>
        );
      })}
    </div>
  );
}
