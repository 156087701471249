import { Flex, Text } from "@chakra-ui/react";
import { PageInfo } from "../utils/types";
import { Link } from "react-router-dom";

interface Props {
  pageInfo: PageInfo[];
  selectedMenu: number;
  setSelectedMenu: (arg: number) => void;
  searchQueries?: { term: string; value: string }[];
}

export default function PageMenu({
  pageInfo,
  selectedMenu,
  setSelectedMenu,
  searchQueries,
}: Props) {
  return (
    <Flex gap={4} align="center" flexWrap="wrap" height="min-content">
      {pageInfo.map((info, i) => {
        const { id, label } = info;
        return (
          <Flex key={i} gap={4} align="center">
            <Link
              to={`${id}${
                searchQueries != null
                  ? `?${searchQueries
                      .map((q) => `${q.term}=${q.value}`)
                      .join("&")}`
                  : ""
              }`}
              onClick={() => setSelectedMenu(i)}
            >
              <Text
                variant={i === selectedMenu ? "selected" : "link"}
                style={{ whiteSpace: "nowrap" }}
              >
                {label}
              </Text>
            </Link>
            {info.isLinear && <Text fontSize="lg">{`\u2192`}</Text>}
          </Flex>
        );
      })}
    </Flex>
  );
}
