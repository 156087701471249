/** Basic sizes, can be customizes for each project */
// Garment size
type BaseSize = "XS" | "S" | "M" | "L" | "XL";
type ExtendedSize =
  | "2XS"
  | "2XS+"
  | "XS+"
  | "S+"
  | "M+"
  | "L+"
  | "2XL"
  | "3XL"
  | "4XL"
  | "5XL";
export type Size = BaseSize | ExtendedSize;

// Body part for project intro
export type BodyPart = "bust" | "arm";
type BodyMeasurement = Partial<Record<BodyPart, number>>;
export type BodySizing = Partial<Record<Size, BodyMeasurement>>;

/** Specific construction */

const sleeveParts = [
  "sleeve_length_from_underarm",
  "wrist_circumference",
  "cuff_length",
];

// RAGLAN
export const raglanCoreParts = [
  "bust_circumference",
  "neck_circumference",
  "under_arm_circumference",
  "yoke_length",
  "neck_band_height",
  "neck_depth",
  "underarm",
  "hem",
  "total_length",
  ...sleeveParts,
];
export type RaglanCorePart = typeof raglanCoreParts[number];
export type RaglanMeasurement = Record<RaglanCorePart, number>;

// SET-IN SLEEVES
const setInSleeveCoreParts = [
  "neck_back_depth",
  "neck_depth",
  "shoulder_drop",
  "armhole_depth",
  "underarm_curve_depth",
  "bust_circumference",
  "neck_width",
  "shoulder_width",
  "total_length",
  "arm_inset_width",
  "hem",
  "neck_band_height",
];
type SetInSleeveCorePart = typeof setInSleeveCoreParts[number];
export type setInSleeveMeasurement = Record<SetInSleeveCorePart, number>;

// pullover
export const setInSleevePulloverCoreParts = [
  ...setInSleeveCoreParts,
  ...sleeveParts,
];
export type SetInSleevePulloverCorePart =
  typeof setInSleevePulloverCoreParts[number];
export type setInSleevePulloverMeasurement = Record<
  SetInSleevePulloverCorePart,
  number
>;

// vest
export const vestCoreParts = [...setInSleeveCoreParts, "arm_band_height"];
type VestCorePart = typeof vestCoreParts[number];
export type VestMeasurement = Record<VestCorePart, number>;

// Finish objects size parts, measurement, sizing
export type FOPart =
  | RaglanCorePart
  | VestCorePart
  | "underarm"
  | "armhole_depth"
  | "neck_width"
  | "underarm_to_hem_length"
  | "body_length_from_underarm";
export type FOMeasurement = Partial<Record<FOPart, number>>;
export type FOSizing = Partial<Record<Size, FOMeasurement>>;
export type CustomizedFO = Partial<Record<FOPart, (number | null)[]>>;

// Default size
export const baseBodySizing: BodySizing = {
  "2XS": { bust: 28 },
  XS: { bust: 30 },
  S: { bust: 32 },
  "S+": { bust: 34 },
  M: { bust: 36 },
  "M+": { bust: 38 },
  L: { bust: 40 },
  "L+": { bust: 42 },
  XL: { bust: 44 },
  "2XL": { bust: 48 },
  "3XL": { bust: 52 },
  "4XL": { bust: 56 },
  "5XL": { bust: 60 },
};

export const baseFOSizing: FOSizing = {
  "2XS": {
    neck_circumference: 14,
    bust_circumference: 30,
    underarm: 30 / 20,
    under_arm_circumference: 10.5,
    yoke_length: 6.5,
    neck_depth: 1.5,
    body_length_from_underarm: 8.75,
    total_length: 21,
    wrist_circumference: 6.5,
    sleeve_length_from_underarm: 13.5,
    neck_band_height: 1,
    cuff_length: 1.5,
    hem: 2,
    underarm_to_hem_length: 10,
    neck_back_depth: 0.5,
    shoulder_drop: 0.5,
    armhole_depth: 9,
    underarm_curve_depth: 2,
    neck_width: 7,
    shoulder_width: 3,
    arm_inset_width: 2,
  },
  "2XS+": {
    neck_circumference: 14.5,
    bust_circumference: 32,
    underarm: 32 / 20,
    under_arm_circumference: 11,
    yoke_length: 7,
    neck_depth: 1.75,
    body_length_from_underarm: 10.75,
    total_length: 21.5,
    wrist_circumference: 7,
    sleeve_length_from_underarm: 15,
    neck_band_height: 1,
    cuff_length: 1.5,
    hem: 2,
    underarm_to_hem_length: 11,
    neck_back_depth: 0.5,
    shoulder_drop: 0.75,
    armhole_depth: 10.25,
    underarm_curve_depth: 2,
    neck_width: 7.5,
    shoulder_width: 3,
    arm_inset_width: 2,
  },
  XS: {
    neck_circumference: 15,
    bust_circumference: 33,
    underarm: 33 / 20,
    under_arm_circumference: 11.75,
    yoke_length: 7.5,
    neck_depth: 1.75,
    body_length_from_underarm: 11.75,
    total_length: 22,
    wrist_circumference: 7.5,
    sleeve_length_from_underarm: 16,
    neck_band_height: 1,
    cuff_length: 1.5,
    hem: 2,
    underarm_to_hem_length: 12,
    neck_back_depth: 1,
    shoulder_drop: 1,
    armhole_depth: 10.75,
    underarm_curve_depth: 2.5,
    neck_width: 8,
    shoulder_width: 3.25,
    arm_inset_width: 2.25,
  },
  "XS+": {
    neck_circumference: 16,
    bust_circumference: 34,
    underarm: 34 / 20,
    under_arm_circumference: 12.5,
    yoke_length: 7.75,
    neck_depth: 1.75,
    body_length_from_underarm: 12.75,
    total_length: 22.5,
    wrist_circumference: 8,
    sleeve_length_from_underarm: 17,
    neck_band_height: 1,
    cuff_length: 1.5,
    hem: 2,
    underarm_to_hem_length: 12.5,
  },
  S: {
    neck_circumference: 17,
    bust_circumference: 35.75,
    underarm: 35.75 / 20,
    under_arm_circumference: 13.25,
    yoke_length: 8,
    neck_depth: 2,
    body_length_from_underarm: 13,
    total_length: 23,
    wrist_circumference: 9.25,
    sleeve_length_from_underarm: 17.75,
    neck_band_height: 1.25,
    cuff_length: 2,
    hem: 2.5,
    underarm_to_hem_length: 13,
    neck_back_depth: 1,
    shoulder_drop: 1,
    armhole_depth: 10.75,
    underarm_curve_depth: 2.5,
    neck_width: 8,
    shoulder_width: 3.25,
    arm_inset_width: 2.25,
  },
  "S+": {
    neck_circumference: 17.75,
    bust_circumference: 38,
    underarm: 38 / 20,
    under_arm_circumference: 13.75,
    yoke_length: 8.5,
    neck_depth: 2,
    body_length_from_underarm: 13.25,
    total_length: 24,
    wrist_circumference: 9.5,
    sleeve_length_from_underarm: 18.25,
    neck_band_height: 1.25,
    cuff_length: 2,
    hem: 2.5,
    underarm_to_hem_length: 13,
  },
  M: {
    neck_circumference: 18.25,
    bust_circumference: 40,
    underarm: 40 / 20,
    under_arm_circumference: 14,
    yoke_length: 9,
    neck_depth: 2,
    body_length_from_underarm: 13.5,
    total_length: 25,
    wrist_circumference: 10,
    sleeve_length_from_underarm: 18.75,
    neck_band_height: 1.25,
    cuff_length: 2,
    hem: 2.5,
    underarm_to_hem_length: 12.375,
    neck_back_depth: 1,
    shoulder_drop: 1,
    armhole_depth: 11.25,
    underarm_curve_depth: 2.5,
    neck_width: 9,
    shoulder_width: 3.25,
    arm_inset_width: 2.5,
    arm_band_height: 2,
  },
  "M+": {
    neck_circumference: 18.5,
    bust_circumference: 42,
    underarm: 42 / 20,
    under_arm_circumference: 14.5,
    yoke_length: 9.25,
    neck_depth: 2,
    body_length_from_underarm: 14.5,
    total_length: 26,
    wrist_circumference: 10,
    sleeve_length_from_underarm: 19,
    neck_band_height: 1.25,
    cuff_length: 2,
    hem: 2.5,
    underarm_to_hem_length: 13.375,
  },
  L: {
    neck_circumference: 19,
    bust_circumference: 44,
    underarm: 44 / 20,
    under_arm_circumference: 14.5,
    yoke_length: 9.5,
    neck_depth: 2,
    body_length_from_underarm: 15,
    total_length: 27,
    wrist_circumference: 10,
    sleeve_length_from_underarm: 19.25,
    neck_band_height: 1.25,
    cuff_length: 2,
    hem: 2.5,
    underarm_to_hem_length: 14.375,
    neck_back_depth: 1,
    shoulder_drop: 1,
    armhole_depth: 11.75,
    underarm_curve_depth: 2.5,
    neck_width: 10,
    shoulder_width: 4.25,
    arm_inset_width: 2.75,
  },
  "L+": {
    neck_circumference: 19.5,
    bust_circumference: 46,
    underarm: 46 / 20,
    under_arm_circumference: 15,
    yoke_length: 9.75,
    neck_depth: 2,
    body_length_from_underarm: 15.25,
    total_length: 27,
    wrist_circumference: 11,
    sleeve_length_from_underarm: 19.5,
    neck_band_height: 1.25,
    cuff_length: 2,
    hem: 2.5,
    underarm_to_hem_length: 15.375,
  },
  XL: {
    neck_circumference: 19.75,
    bust_circumference: 48,
    underarm: 48 / 20,
    under_arm_circumference: 16,
    yoke_length: 10,
    neck_depth: 2.5,
    body_length_from_underarm: 15.5,
    total_length: 28,
    wrist_circumference: 11,
    sleeve_length_from_underarm: 20,
    neck_band_height: 1.25,
    cuff_length: 2,
    hem: 3,
    underarm_to_hem_length: 16.375,
    neck_back_depth: 1.5,
    shoulder_drop: 1.5,
    armhole_depth: 12,
    underarm_curve_depth: 2.5,
    neck_width: 10.5,
    shoulder_width: 4.75,
    arm_inset_width: 2.75,
  },
  "2XL": {
    neck_circumference: 20,
    bust_circumference: 52,
    underarm: 52 / 20,
    under_arm_circumference: 17,
    yoke_length: 10.25,
    neck_depth: 2.5,
    body_length_from_underarm: 16,
    total_length: 28,
    wrist_circumference: 11.5,
    sleeve_length_from_underarm: 20.25,
    neck_band_height: 1.5,
    cuff_length: 2.5,
    hem: 3,
    underarm_to_hem_length: 16.375,
    neck_back_depth: 1.5,
    shoulder_drop: 1.5,
    armhole_depth: 12.5,
    underarm_curve_depth: 2.5,
    neck_width: 11,
    shoulder_width: 5.25,
    arm_inset_width: 2.75,
  },
  "3XL": {
    neck_circumference: 20.25,
    bust_circumference: 57,
    underarm: 57 / 20,
    under_arm_circumference: 18,
    yoke_length: 10.75,
    neck_depth: 2.5,
    body_length_from_underarm: 16.25,
    total_length: 28,
    wrist_circumference: 11.5,
    sleeve_length_from_underarm: 20.5,
    neck_band_height: 1.5,
    cuff_length: 2.5,
    hem: 3,
    underarm_to_hem_length: 16.375,
    neck_back_depth: 1.5,
    shoulder_drop: 1.5,
    armhole_depth: 12.5,
    underarm_curve_depth: 2.5,
    neck_width: 11,
    shoulder_width: 5.75,
    arm_inset_width: 2.75,
  },
  "4XL": {
    neck_circumference: 20.5,
    bust_circumference: 62,
    underarm: 62 / 20,
    under_arm_circumference: 19,
    yoke_length: 11.25,
    neck_depth: 2.5,
    body_length_from_underarm: 16.5,
    total_length: 28,
    wrist_circumference: 12,
    sleeve_length_from_underarm: 21,
    neck_band_height: 1.5,
    cuff_length: 2.5,
    hem: 3,
    underarm_to_hem_length: 16.375,
    neck_back_depth: 1.5,
    shoulder_drop: 1.5,
    armhole_depth: 12.5,
    underarm_curve_depth: 2.5,
    neck_width: 11,
    shoulder_width: 6.5,
    arm_inset_width: 2.75,
  },
};
