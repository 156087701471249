import { Box, Divider } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import { PageInfo } from "../utils/types";
import PageMenu from "./PageMenu";
import { useProject } from "./Project";

export interface PatternWithMenusProps {
  menu: number;
}

interface Props {
  page: string;
}

export default function PageWithMenus({ page }: Props) {
  const [pageInfo, setPageInfo] = useState<PageInfo[] | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<number>(0);
  const { projectID } = useProject();

  // Load project info
  useMemo(() => {
    (async () => {
      const info = await import(`../projects/${projectID}/${page}`);
      setPageInfo(info.default);
    })();
  }, [page, projectID]);

  if (pageInfo == null) {
    return null;
  }

  return (
    <Box
      style={{ background: "white" }}
      p={4}
      ml={2}
      mr={2}
      color="var(--oc-gray-8)"
      borderRadius={8}
    >
      <PageMenu
        pageInfo={pageInfo}
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
      />
      <Divider pt={4} mb={4} variant="dashed" />
      <Outlet context={{ projectID, menu: selectedMenu }} />
    </Box>
  );
}

export function usePatternWithMenus() {
  return useOutletContext<PatternWithMenusProps>();
}
