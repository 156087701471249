import { Card, CardBody, Heading, Text, Image, Stack } from "@chakra-ui/react";
import { useState } from "react";

interface Props {
  title: string;
  list: string[];
  photo: string;
}
export default function ProjectCard({ title, list, photo }: Props) {
  const [hasPhoto, setHasPhoto] = useState<boolean>(true);
  const photoURL = `${process.env.PUBLIC_URL}/photos/${photo}`;

  return (
    <Card
      w="2xs"
      variant="elevated"
      style={{ borderRadius: 0, backgroundColor: "white" }}
    >
      {hasPhoto && (
        <Image
          onError={() => setHasPhoto(false)}
          objectFit="cover"
          src={photoURL}
          alt={title}
        />
      )}
      <CardBody>
        <Heading as="h2" size="md">
          {title}
        </Heading>
        <Stack spacing={1} pt={3}>
          {list.map((item, i) => (
            <Text key={i}>{item}</Text>
          ))}
        </Stack>
      </CardBody>
    </Card>
  );
}
