import { Box, Flex, Text } from "@chakra-ui/react";
import { PlainTextInstruction } from "../utils/types";

export default function PlainTextRows({
  rowType = "Rnd",
  startRowID,
  rows,
  showSide = false,
  highlighted,
}: PlainTextInstruction) {
  return (
    <Flex direction="column" gap={2}>
      {rows.map((item, i) => (
        <Flex flexDirection={["column", "row"]} key={i} gap={2} align="top">
          <Text
            variant={highlighted === i ? "code-highlighted" : "code"}
            width="fit-content"
            height="fit-content"
          >
            {rowType}
            {startRowID != null ? ` ${1 + i + startRowID}` : ""}
            {showSide ? (i % 2 === 1 ? " (WS)" : " (RS)") : ""}
          </Text>
          <Text variant={highlighted === i ? "highlighted" : ""}>
            {item.text}
          </Text>
          {item.notes != null && <Text variant="notes">({item.notes})</Text>}
        </Flex>
      ))}
    </Flex>
  );
}
