import PlainTextRows from "./PlainTextRows";
import { ShortRowDiagramProps } from "./PulloverShortRowDiagram";
import { HStack, VStack, Text } from "@chakra-ui/react";

interface Props extends ShortRowDiagramProps {
  m1r?: [string, string];
  m1l?: [string, string];
  selectedRow: number | null;
}

export default function ShortRowText({
  m1r = ["RLI", "LLI-P"],
  m1l = ["LLI", "RLI-P"],
  row,
  sts,
  incSkip,
  raglanOffset,
  selectedRow,
}: Props) {
  const [bodyS, sleeveS] = raglanOffset;

  const written = Array.from({ length: row }).map((_, i) => {
    const rowPairID = Math.floor(i / 2);
    const srAdd = sts[rowPairID];

    const stsID = i % 2 === 0 ? "k" : "p";
    const bM = i % 2 === 0 ? "mA" : "mD";
    const fM = i % 2 === 0 ? "mB" : "mC";
    const srM = i % 2 === 0 ? "mR" : "mW";

    const incBack = `${stsID} till ${bodyS} sts bef ${bM}, ${
      m1r[i % 2]
    }, ${stsID}${bodyS}, s${bM}, ${stsID}${sleeveS}, ${m1l[i % 2]}`;
    const incFront = `${stsID} till ${sleeveS} sts bef ${fM}, ${
      m1r[i % 2]
    }, ${stsID}${sleeveS}, s${fM}, ${stsID}${bodyS}, ${m1l[i % 2]}, `;
    const sr = `${stsID} till ${srM}, r${srM}, ${stsID}${srAdd}, p${srM}`;

    const written = `${incBack}, ${
      rowPairID < incSkip - 1 ? "" : incFront
    }${sr}, w&t`;

    return {
      text: written,
      notes: `${
        rowPairID < incSkip - 1 ? 2 : 4
      } sts inc'd, SR ${srAdd} sts added`,
    };
  });

  return (
    <PlainTextRows
      rowType="SR"
      showSide={true}
      rows={written}
      startRowID={0}
      highlighted={selectedRow}
    />
  );
}
