import { getChartFromText } from "../../utils";
import { Motif } from "../../utils/types";
import { getMosEquivCount } from "./Moss";

const BASE_KP_COUNT = 7;
export const getMotif = (hasMB: boolean = true, kpDiff: number = 0): Motif => {
  let baseKPCount = BASE_KP_COUNT + kpDiff;
  const chart: string[] = [
    `k:3 ${Array(baseKPCount).fill("p k").join(" ")} k:2 * 2`,
  ];

  baseKPCount--;
  for (let i = 0; i < 5; i++) {
    chart.push(
      `p:${i} c21lp ${Array(baseKPCount - i)
        .fill("k p")
        .join(" ")} k c21rp p:${i}`
    );
    chart.push(
      `p:${i + 1} k:3 ${Array(baseKPCount - i)
        .fill("p k")
        .join(" ")} k:2 p:${i + 1}`
    );
  }
  if (hasMB) {
    chart.push(
      `p mb p:3 k ${Array(baseKPCount - 3)
        .fill("k p")
        .join(" ")} k:2 p:3 mb p`,
      `p:5 k:2 p ${Array(baseKPCount - 4)
        .fill("k p")
        .join(" ")} k:2 p:5`
    );
  } else {
    chart.push(
      `p:5 k ${Array(baseKPCount - 3)
        .fill("k p")
        .join(" ")} k:2 p:5 * 2`
    );
  }
  for (let i = 4; i > 0; i--) {
    chart.push(
      `p:${i} c21rp ${Array(baseKPCount - i)
        .fill("k p")
        .join(" ")} k c21lp p:${i}`
    );
    chart.push(
      `p:${i} k:4 ${Array(baseKPCount - i)
        .fill("p k")
        .join(" ")} k:3 p:${i}`
    );
  }
  chart.push(`c21rp ${Array(baseKPCount).fill("k p").join(" ")} k c21lp`);
  chart.push(`k:4 ${Array(baseKPCount).fill("p k").join(" ")} k:3`);

  return {
    title: "Diamond",
    stsCount: 19 + kpDiff * 2,
    chart: getChartFromText(chart, true),
    stEquivCount: 16.875 + getMosEquivCount(kpDiff * 2),
  };
};
