import React from "react";
import { cx } from "@linaria/core";
import {
  BASE_RADIUS,
  MARKER_RADIUS,
  OFFSET,
  WIDTH,
  styles,
} from "./PulloverShortRowDiagram";
import { getMarkerLinePoint } from "./utils/shortRowDiagram";

interface Props {
  markers: number[];
  totalSts: number;
  rsInit: number;
  wsInit: number;
}

export default function ShortRowMarkers({
  markers,
  totalSts,
  rsInit,
  wsInit,
}: Props) {
  const borPoints = getMarkerLinePoint(
    totalSts,
    0,
    BASE_RADIUS - OFFSET,
    BASE_RADIUS + OFFSET
  );

  const allSplits = [
    markers[0],
    rsInit,
    markers[1],
    markers[2],
    totalSts - wsInit,
    markers[3],
    totalSts,
  ];
  const stsSplit = allSplits.map((s, i) => {
    const curStsCount = s - (allSplits[i - 1] ?? 0);
    const stsTextProps = getMarkerLinePoint(
      totalSts,
      s - curStsCount / 2,
      BASE_RADIUS * 0.75,
      0
    );
    return (
      <text
        key={i}
        x={stsTextProps.x1}
        y={stsTextProps.y1}
        className={cx(styles.stsCount)}
      >
        {curStsCount}
      </text>
    );
  });

  const raglanMarkers = markers.map((s, i) => {
    const lineProps = getMarkerLinePoint(totalSts, s, BASE_RADIUS, WIDTH / 2);
    const { x1, y1 } = lineProps;
    return (
      <React.Fragment key={i}>
        <line key={i} {...lineProps} className={cx(styles.marker)} />
        <circle
          cx={x1}
          cy={y1}
          r={MARKER_RADIUS}
          className={cx(styles.markerbg)}
        />
        <text x={x1} y={y1} className={cx(styles.markerChar)}>
          {String.fromCharCode(i + 65)}
        </text>
      </React.Fragment>
    );
  });

  const initShortRowMarkers = [rsInit, totalSts - wsInit].map((s, i) => {
    const lineProps = getMarkerLinePoint(
      totalSts,
      s,
      BASE_RADIUS * 0.4,
      BASE_RADIUS + OFFSET / 2
    );
    const { x1, y1 } = lineProps;
    return (
      <React.Fragment key={i}>
        <line key={i} {...lineProps} className={cx(styles.marker)} />
        <circle
          cx={x1}
          cy={y1}
          r={MARKER_RADIUS}
          className={cx(styles.markerbgInv)}
        />
        <text x={x1} y={y1} className={cx(styles.markerCharInv)}>
          {i === 0 ? "R" : "W"}
        </text>
      </React.Fragment>
    );
  });
  return (
    <>
      <line {...borPoints} className={cx(styles.marker)} />
      {stsSplit}
      {raglanMarkers}
      {initShortRowMarkers}
    </>
  );
}
