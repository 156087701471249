import React, { lazy, Suspense } from "react";
import { useProject } from "./Project";

export default function PatternPart() {
  const { projectID } = useProject();

  const IntroPage = lazy(() => import(`../projects/${projectID}/IntroPage`));

  return (
    <Suspense fallback={<div>Loading instruction...</div>}>
      <IntroPage projectID={projectID} />
    </Suspense>
  );
}
