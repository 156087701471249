import { IntroSizing } from "../../utils/types";
import {
  Size,
  baseBodySizing,
  FOMeasurement,
  raglanCoreParts,
  RaglanMeasurement,
  CustomizedFO,
} from "../../utils/sizeBase";
import profile from "./profile";
import { getFOMeasurementBySize, getIntroSizingInfo } from "../../utils/misc";
import { getVNeckPatternSchema } from "../../patternMakers/raglan/cardiganVNeck";
import { VNeckCardiganPattern } from "../../patternMakers/raglan/types";

// Size list
export const sizes: Size[] = ["M"];

const customizedSize: CustomizedFO = {
  neck_circumference: [21.7],
  neck_depth: [1],
  bust_circumference: [41.04],
  under_arm_circumference: [16.52],
  wrist_circumference: [10.4],
  total_length: [24],
  sleeve_length_from_underarm: [12.5],
  underarm: [41.04 / 20],
  yoke_length: [9.25],
  neck_band_height: [1],
  cuff_length: [3],
  hem: [3],
};

export const measurements: FOMeasurement[] = getFOMeasurementBySize(
  sizes,
  raglanCoreParts,
  customizedSize
);

export const schemaBySize: VNeckCardiganPattern[] = measurements.map((m, i) => {
  return getVNeckPatternSchema(
    profile.gauge.sts_count,
    profile.gauge.row_count,
    m as RaglanMeasurement
  );
});

export const introSizing: IntroSizing = getIntroSizingInfo(
  sizes,
  baseBodySizing,
  measurements,
  schemaBySize.map((schema) => schema.totalSts),
  ["bust"],
  [
    "bust_circumference",
    "neck_circumference",
    "under_arm_circumference",
    "sleeve_length_from_underarm",
    "total_length",
  ]
);
