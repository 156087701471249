export interface Needle {
  metric: number;
  us: number | null;
}

export const needles: Needle[] = [
  { metric: 2.0, us: 0 }, // 0
  { metric: 2.25, us: 1 }, // 1
  { metric: 2.5, us: 1.5 }, // 2
  { metric: 2.75, us: 2 }, // 3
  { metric: 3, us: 2.5 }, // 4
  { metric: 3.25, us: 3 }, // 5
  { metric: 3.5, us: 4 }, // 6
  { metric: 3.75, us: 5 }, // 7
  { metric: 4, us: 6 }, // 8
  { metric: 4.5, us: 7 }, // 9
  { metric: 5, us: 8 }, // 10
  { metric: 5.5, us: 9 }, // 11
  { metric: 6, us: 10 }, // 12
  { metric: 6.5, us: 10.5 }, // 13
  { metric: 7, us: 10.75 }, // 14
  { metric: 7.5, us: null },
  { metric: 8, us: 11 },
  { metric: 9, us: 13 },
  { metric: 10, us: 15 },
  { metric: 12.5, us: 17 },
];

export function getNeedleText(needle: Needle): string {
  const { metric, us } = needle;
  return `${us != null ? `US ${us} /` : ""} ${metric} mm`;
}
