import { Motif } from "@tanyoknits/shared";
import {
  BodyPart,
  BodySizing,
  CustomizedFO,
  FOMeasurement,
  FOPart,
  Size,
  baseFOSizing,
} from "./sizeBase";
import { IntroSizing, MotifBySizeItem } from "./types";

export function getMotifBySize(motifs: MotifBySizeItem[], size: Size): Motif {
  return motifs.filter((m) => m.sizes.includes(size))[0].motif;
}

export function getIntroSizingInfo(
  sizes: Size[],
  sizing: BodySizing,
  measurement: FOMeasurement[],
  totalSts: number[],
  bodyParts: BodyPart[],
  foParts: FOPart[],
  materialBase?: { size: Size; grams: number }
): IntroSizing {
  return {
    totalSts,
    bodyParts: bodyParts.map((d) => {
      const part = d as BodyPart;
      return {
        part,
        sizes: sizes.map((size) => sizing?.[size as Size]?.[part] ?? 0),
      };
    }),
    foParts: foParts.map((d) => {
      const part = d as FOPart;
      return {
        part,
        sizes: sizes.map((size, i) => measurement?.[i]?.[part] ?? 0),
      };
    }),
    materialBase: materialBase ?? { size: "M", grams: 0 },
    sizes,
  };
}

export function getFOMeasurementBySize(
  sizes: Size[],
  coreParts: FOPart[],
  customizedFO?: CustomizedFO
): FOMeasurement[] {
  return sizes.map((size, i) => {
    const msByParts: Record<FOPart, number> = {};
    coreParts.forEach((part) => {
      msByParts[part] = baseFOSizing[size]?.[part] ?? 0;
    });
    if (customizedFO != null) {
      Object.keys(customizedFO).forEach((part) => {
        let sizeID = i;
        while (customizedFO[part]?.[sizeID] == null) {
          sizeID--;
        }
        msByParts[part] = customizedFO[part]?.[sizeID] ?? 0;
      });
    }
    return msByParts;
  });
}
