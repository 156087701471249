import { needles } from "../../utils/needles";
import { ProjectProfile } from "../../utils/types";

const profile: ProjectProfile = {
  yarn: {
    maker: "Lana Grossa",
    line: "Puno Luce",
    color: "White",
    url: "https://www.lana-grossa.de/en/yarns/detail/puno-luce/1123/",
    shape: "ball",
    yards: 273,
    grams: 50,
  },
  needles: [
    {
      type: "circular",
      size: needles[7],
      usage: "Body",
    },
    { type: "circular", size: needles[5], usage: "Bands" },
  ],
  gauge: {
    sts: "Stockinette stitch",
    sts_count: 26,
    row_count: 36,
    needle: needles[7],
  },
  notions: ["Cable Needle", "Tapestry needle", "Stitch markers"],
  difficulty: "Intermediate",
  photoCount: 0,
};

export default profile;
