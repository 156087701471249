import {
  getChartFromText,
  getRightLeftFlippedChart,
} from "../../utils/chartModifier";
import { Motif } from "../../utils/types";
import { getRound } from "../../utils";

export const getMotif = (
  stsCount: 18 | 12 | 9 | 6 | 3 = 6,
  rowCount: 16 | 12 | 8 | 6 | 4 = 8
): Motif => {
  let rightCable = null;
  switch (stsCount) {
    case 18:
      rightCable = "c12r";
      break;
    case 12:
      rightCable = "c8r";
      break;
    case 9:
      rightCable = "c6r";
      break;
    case 6:
      rightCable = "c4r";
      break;
    case 3:
      rightCable = "c2r";
      break;
    default:
      rightCable = "c6r";
      break;
  }

  const base = getChartFromText([
    `k:${stsCount} * ${rowCount / 2 - 2}`,
    `k:${stsCount / 3} ${rightCable}`,
    `k:${stsCount}`,
  ]);

  const flippedBase = getRightLeftFlippedChart(base);

  const pattern = [...base, ...flippedBase];

  return {
    title: "Braid",
    chart: pattern,
    stsCount,
    stEquivCount:
      stsCount === 18
        ? 13.5
        : getRound(((stsCount * 7) / 8) * 1000, 125) / 1000,
  };
};
