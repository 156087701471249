import { TechniqueList } from "../utils/types";

const techniques: TechniqueList = {
  "Tubular CO": {
    videos: [{ url: "https://www.youtube.com/watch?v=EYOnWwNuFRg" }],
  },
  "Tubular BO": {
    videos: [{ url: "https://www.youtube.com/watch?v=SBrGhv1_RBU&t=22s" }],
  },
  "Underarm CO": {
    videos: [{ url: "https://www.youtube.com/watch?v=cmaIYuHHlt8" }],
  },
  "Underarm pick up": {
    videos: [{ url: "https://www.youtube.com/watch?v=7D3oD5G5h1o" }],
  },
  "Wrap & Turn": {
    videos: [{ url: "https://www.youtube.com/watch?v=a3S9cl47PYw" }],
  },
};

export default techniques;
