import { Flex, Text } from "@chakra-ui/react";
import type { Chart, Direction } from "../utils/types";
import { css, cx } from "@linaria/core";
import { unit } from "./styles";
import { FocusType } from "./Chart";
import WrittenRow from "./WrittenRow";
import { getStsCountFromChartRow } from "../utils/calculator";

export type WrittenDisplayProps = "inline" | "block";

interface Props {
  chart: Chart;
  direction?: Direction;
  focus?: FocusType;
  startRowID?: number;
  showStsCountPerRow?: boolean;
  display?: WrittenDisplayProps;
}

const styles = {
  // Text instruction
  block: css`
    display: flex;
    flex-wrap: wrap;
  `,
  row: css`
    display: flex;
    align-items: baseline;
    margin-bottom: ${unit}px;
  `,
  rowBlock: css`
    margin-bottom: ${unit}px;
    margin-right: ${unit * 2}px;
    border: 1px solid var(--theme-2);
    height: fit-content;
  `,
};

export default function Written({
  chart,
  direction = "round",
  focus,
  startRowID = 0,
  showStsCountPerRow = false,
  display = "inline",
}: Props) {
  return (
    <Flex direction="column" gap={4}>
      <div className={cx(display === "block" && styles.block)}>
        {chart.map((row, i) => (
          <div
            key={i}
            className={cx(display === "inline" ? styles.row : styles.rowBlock)}
          >
            <Text variant={"code"} mr={display === "inline" ? 2 : 0}>
              {direction === "round" ? "Rnd" : "Row"} {i + startRowID + 1}
              {i === 0 && direction === "flat" ? " (RS)" : ""}
              {i === 1 && direction === "flat" ? " (WS)" : ""}
              {showStsCountPerRow &&
                ` ${getStsCountFromChartRow(row, false, true)} sts`}
            </Text>
            <WrittenRow
              row={row}
              rowID={i}
              direction={direction}
              display={display}
              focus={focus}
            />
          </div>
        ))}
      </div>
    </Flex>
  );
}
