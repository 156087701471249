import { Box, Flex, Text } from "@chakra-ui/react";

interface Props {
  overview: string;
  list: { title: string; desc: string; video?: string }[];
}
export default function ExplanationList({ overview, list }: Props) {
  return (
    <Box>
      <Text mb={6}>
        Following abbreviations are used in the pattern instruction.
      </Text>
      <Flex direction="column" gap={[4, 2]}>
        {list.map((item, i) => (
          <Flex flexDirection={["column", "row"]} key={i} gap={[1, 4]}>
            <Text variant="bold">{item.title}</Text>
            <Text maxW="4xl">{item.desc}</Text>
            {item.video != null && (
              <Text variant="notes">
                <a href={item.video} target="_blank" rel="noreferrer">
                  Youtube link
                </a>
              </Text>
            )}
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
