import { extendTheme, createMultiStyleConfigHelpers } from "@chakra-ui/react";
import OpenColor from "open-color";
import { selectAnatomy } from "@chakra-ui/anatomy";

const FONT_FAMILY =
  '"Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto","Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"';

const { definePartsStyle } = createMultiStyleConfigHelpers(selectAnatomy.keys);

const theme = extendTheme({
  fonts: {
    body: FONT_FAMILY,
    heading: FONT_FAMILY,
  },
  fontWeight: {
    heading: 700,
    text: 400,
  },
  colors: {
    gray: {
      50: OpenColor.gray[0],
      100: OpenColor.gray[1],
      200: OpenColor.gray[2],
      300: OpenColor.gray[3],
      400: OpenColor.gray[4],
      500: OpenColor.gray[5],
      600: OpenColor.gray[6],
      700: OpenColor.gray[7],
      800: OpenColor.gray[8],
      900: OpenColor.gray[9],
    },
    brown: {
      50: "var(--tk-brown-0)",
      100: "var(--tk-brown-1)",
      200: "var(--tk-brown-2)",
      300: "var(--tk-brown-3)",
      400: "var(--tk-brown-4)",
      500: "var(--tk-brown-5)",
      600: "var(--tk-brown-6)",
      700: "var(--tk-brown-7)",
      800: "var(--tk-brown-8)",
      900: "var(--tk-brown-9)",
    },
    cyan: {
      50: "var(--oc-cyan-0)",
      100: "var(--oc-cyan-1)",
      200: "var(--oc-cyan-2)",
      300: "var(--oc-cyan-3)",
      400: "var(--oc-cyan-4)",
      500: "var(--oc-cyan-5)",
      600: "var(--oc-cyan-6)",
      700: "var(--oc-cyan-7)",
      800: "var(--oc-cyan-8)",
      900: "var(--oc-cyan-9)",
    },
  },
  components: {
    Text: {
      variants: {
        code: {
          fontFamily: "var(--code)",
          fontWeight: 700,
          backgroundColor: "var(--theme-1)",
          whiteSpace: "nowrap",
          px: "2",
        },
        "code-highlighted": {
          fontFamily: "var(--code)",
          fontWeight: 700,
          color: "white",
          backgroundColor: "var(--theme-7)",
          whiteSpace: "nowrap",
          px: "2",
        },
        bold: {
          fontWeight: 600,
        },
        link: {
          textDecoration: "underline",
          fontWeight: 600,
          cursor: "pointer",
          width: "min-content",
          whiteSpace: "nowrap",
        },
        selected: {
          color: OpenColor.white,
          fontWeight: 700,
          backgroundColor: "var(--theme-9)",
          px: 2,
          width: "min-content",
          whiteSpace: "nowrap",
        },
        highlighted: {
          backgroundColor: "var(--theme-0)",
          color: "var(--theme-9)",
        },
        notes: {
          display: "inline",
          textDecoration: "underline",
          textDecorationColor: "var(--theme-6)",
          textDecorationThickness: 2,
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          borderColor: "var(--theme-4)",
          _checked: {
            background: "var(--theme-6)",
            borderColor: "var(--theme-4)",
          },
        },
        label: {
          whiteSpace: "nowrap",
        },
      },
    },
    Divider: {
      variants: {
        dashed: {
          borderColor: "gray.600",
        },
      },
    },
    Container: {
      variants: {
        page: {
          backgroundColor: "white",
          padding: 4,
          marginLeft: 2,
          marginRight: 2,
          color: "gray.800",
          borderRadius: 8,
          maxWidth: null,
        },
      },
    },
    Select: {
      variants: {
        outline: definePartsStyle({
          field: {
            borderColor: "var(--theme-6)",
            borderWidth: 2,
            _focusWithin: {
              ringColor: "var(--theme-2)",
              ring: "2px",
              ringOffsetColor: "var(--theme-1)",
              borderColor: "var(--theme-0)",
            },
          },
        }),
      },
    },
    Menu: {
      variants: {
        nav: {
          button: {
            color: "var(--theme-9)",
          },
          list: {
            color: "var(--theme-9)",
          },
          item: {
            bg: "white",
            color: "var(--theme-9)",
            _hover: {
              bg: "var(--theme-9)",
              color: "white",
            },
          },
        },
      },
    },
    Button: {
      variants: {
        link: {
          color: "var(--theme-7)",
        },
      },
    },
    Alert: {
      baseStyle: {
        container: {
          backgroundColor: "var(--theme-1)",
        },
        icon: {
          color: "var(--theme-6)",
        },
      },
    },
  },
});

export default theme;
