import { css, cx } from "@linaria/core";
import { Direction } from "../utils/types";

interface Props {
  viewBoxRatio?: number;
  neck: "round" | "v";
}

const styles = {
  svg: css`
    fill: none;
    line,
    path {
      stroke: var(--chart-color);
    }
    rect {
      fill: var(--chart-color);
    }
    circle {
      fill: var(--chart-color);
    }
    text {
      fill: white;
      text-anchor: middle;
      alignment-baseline: central;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: -0.02em;
    }
  `,
  outline: css`
    stroke-width: 2;
  `,
  partLine: css`
    stroke-dasharray: 2 2;
  `,
  innerLine: css`
    stroke-width: 1;
  `,
  label: css`
    text-anchor: middle;
    font-size: 12px;
    font-weight: bold;
    fill: var(--chart-color) !important;
  `,
  show: css`
    display: block;
  `,
  hide: css`
    display: none;
  `,
};

export default function Vest({ viewBoxRatio = 1, neck }: Props) {
  const WIDTH = 361;
  const HEIGHT = 214;

  const partsPosition: {
    x: number;
    y: number;
    label: number;
    direction: Direction;
    only?: "round" | "v";
  }[] = [
    { x: 29, y: 200, label: 1, direction: "flat" },
    { x: 139, y: 200, label: 2, direction: "flat" },
    { x: 85, y: 138, label: 3, direction: "flat" },
    { x: 207, y: 194, label: 4, direction: "flat" },
    { x: 321, y: 194, label: 5, direction: "flat" },
    { x: 264, y: 138, label: 6, direction: "flat", only: "round" },
    { x: 270, y: 130, label: 6, direction: "round", only: "v" },
    { x: 91, y: 50, label: 7, direction: "round" },
    { x: 270, y: 50, label: 7, direction: "round" },
  ];

  return (
    <svg
      width={WIDTH * viewBoxRatio}
      height={HEIGHT * viewBoxRatio}
      viewBox={`0 0 ${WIDTH} ${HEIGHT}`}
      xmlns="http://www.w3.org/2000/svg"
      className={cx(styles.svg)}
    >
      {/* outlines */}
      <g>
        <path
          d="M11.5 136V135.793L11.3536 135.646L1.5 125.793V0.5H180.5V125.793L170.646 135.646L170.5 135.793V136V210.107L125.232 212.728C123.165 209.738 118.71 207.222 112.874 205.438C106.85 203.595 99.2504 202.5 91 202.5C82.579 202.5 74.8356 203.641 68.7533 205.553C65.7122 206.509 63.075 207.661 60.9728 208.969C58.9934 210.199 57.4632 211.583 56.5295 213.092L11.5 210.178V136Z"
          className={cx(styles.outline)}
        />
        <path
          d="M190.5 136V135.793L190.354 135.646L180.5 125.793V0.5H359.5V125.793L349.646 135.646L349.5 135.793V136V210.107L305.452 212.657C304.479 194.178 288.973 179.5 270 179.5C250.901 179.5 235.314 194.374 234.531 213.027L190.5 210.178V136Z"
          className={cx(styles.outline, neck === "v" && styles.hide)}
        />
        <path
          d="M180.5 125.793V0.5H359.5V125.793L349.646 135.646L349.5 135.793V136V210.107L305.234 212.67L270.418 159.725L269.999 159.088L269.581 159.727L234.754 213.042L190.5 210.178V136V135.793L190.354 135.646L180.5 125.793Z"
          className={cx(styles.outline, neck === "round" && styles.hide)}
        />
      </g>
      {/* part line */}
      <g>
        <line
          x1="11"
          y1="201.5"
          x2="171"
          y2="201.5"
          className={cx(styles.partLine)}
        />
        <line
          x1="1"
          y1="127"
          x2="367"
          y2="127"
          className={cx(styles.partLine)}
        />
        <line
          x1="190"
          y1="178"
          x2="350"
          y2="178"
          className={cx(styles.partLine, neck === "v" && styles.hide)}
        />
        <line
          x1="200"
          y1="149"
          x2="340"
          y2="149"
          className={cx(styles.partLine, neck === "round" && styles.hide)}
        />
      </g>
      {partsPosition.map((pos, i) =>
        pos.direction === "round" ? (
          <g
            key={i}
            className={cx(
              ((neck === "round" && pos.only === "v") ||
                (neck === "v" && pos.only === "round")) &&
                styles.hide
            )}
          >
            <circle cx={pos.x} cy={pos.y} r={7} />
            <text x={pos.x} y={pos.y}>
              {pos.label}
            </text>
          </g>
        ) : (
          <g
            key={i}
            className={cx(
              ((neck === "round" && pos.only === "v") ||
                (neck === "v" && pos.only === "round")) &&
                styles.hide
            )}
          >
            <rect x={pos.x} y={pos.y} width={12} height={12} />
            <text x={pos.x + 6} y={pos.y + 6}>
              {pos.label}
            </text>
          </g>
        )
      )}
      <text x="91" y="20" className={cx(styles.label)}>
        Back
      </text>
      <text x="270" y="20" className={cx(styles.label)}>
        Front
      </text>
    </svg>
  );
}
