import { getChartFromText, getRound } from "../../utils";
import { Motif } from "../../utils/types";

export function getMosEquivCount(stsCount: number): number {
  return getRound(((stsCount * 17) / 18) * 1000, 125) / 1000;
}
export const getMotif = (count: 1 | 2 = 2): Motif => {
  const chart = getChartFromText([
    `k:${count} p:${count} * 2`,
    `p:${count} k:${count} * 2`,
  ]);
  const stsCount = count * 2;

  return {
    title: "Moss",
    stsCount,
    chart,
    stEquivCount: getMosEquivCount(stsCount),
  };
};
