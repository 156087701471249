import React, { lazy, Suspense, useDeferredValue } from "react";
import { useProject } from "./Project";
import { usePatternWithMenus } from "./PageWithMenus";

export default function Stitches() {
  const { projectID } = useProject();

  const StitchesPage = lazy(
    () => import(`../projects/${projectID}/StitchesPage`)
  );

  const { menu } = usePatternWithMenus();
  const deferredMenu = useDeferredValue(menu);

  return (
    <Suspense fallback={<div>Loading stitches & techniques...</div>}>
      {deferredMenu !== menu ? (
        <div>Loading stitch instruction...</div>
      ) : (
        <StitchesPage menu={menu} />
      )}
    </Suspense>
  );
}
