import { Flex, Text, Select } from "@chakra-ui/react";

interface Props {
  sizes: string[];
  selectedSize: number;
  onChange: (arg0: number) => void;
}

export default function SelectSize({ sizes, onChange, selectedSize }: Props) {
  return (
    <Flex alignItems="center" gap={4}>
      <Text variant="bold" style={{ whiteSpace: "nowrap" }}>
        Select size
      </Text>
      <Select
        width="fit-content"
        onChange={(e) => onChange(+e.target.value)}
        value={selectedSize}
      >
        {sizes.map((size, i) => (
          <option key={i} value={i}>
            {size}
          </option>
        ))}
      </Select>
    </Flex>
  );
}
