import { Stitch, GlyphSize } from "../../utils/types";
import { css, cx } from "@linaria/core";
import { glyph as glyphStyles } from "../styles/theme";

interface Props {
  sts: Stitch;
  size?: GlyphSize;
  className?: string;
  rectClassName?: string;
}

// Glyph size
const { size: glyphSize, viewBox: viewBoxRatio } = glyphStyles;

export const styles = {
  svg: css`
    line,
    path {
      stroke: var(--chart-color);
      stroke-width: var(--chart-stroke-width);
    }
    rect {
      fill: none;
      stroke: var(--chart-color);
      stroke-width: calc(var(--chart-stroke-width) / 2);
    }
    circle {
      stroke: var(--chart-color);
      stroke-width: var(--chart-stroke-width);
    }
  `,
  noFill: css`
    fill: none !important;
    stroke: var(--chart-color);
    stroke-width: var(--chart-stroke-width);
  `,
  nonSts: css`
    fill: var(--oc-gray-4) !important;
  `,
};

export default function GlyphWrapper({
  sts,
  size = "L",
  className,
  rectClassName,
}: Props) {
  const unit = glyphSize[size];
  const width = unit * (sts.unitWidth ?? 1);
  const height = unit;
  const viewBox = viewBoxRatio[size];

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width * viewBox} ${height * viewBox}`}
      xmlns="http://www.w3.org/2000/svg"
      className={cx(styles.svg, className)}
    >
      <rect
        x={0}
        y={0}
        width="100%"
        height="100%"
        className={cx(sts.isNonSts && styles.nonSts, rectClassName)}
      />
      {sts.glyph}
    </svg>
  );
}
