import { getEven, getFloor, getRound } from "@tanyoknits/shared";
import { RaglanMeasurement } from "../../utils/sizeBase";
import { RaglanPattern } from "./types";
import { getSleeveDecRows } from "../common/sleeves";
import { getStsCountAtRaglan } from "../../components/RaglanIncTable";

function getRaglanShortRow(
  stsCount: number,
  halfRowCount: number,
  incOffset: number,
  maxIncSkip: number
): { sts: number[]; skip: number } {
  const baseSts = Math.floor(stsCount / halfRowCount);
  const baseStsID = Math.round(halfRowCount / 2);
  const points = Array.from({ length: halfRowCount }).map((_, i) => {
    return Math.max(2, baseSts - (baseStsID - i));
  });
  let skip = 0;
  let sum = 0;
  points.forEach((sr, i) => {
    if (sum + sr <= incOffset) {
      skip = i;
      if (i === maxIncSkip - 1) {
        skip--;
        points[i] = incOffset - sum + 1;
      }
    }
    sum += points[i];
    // If the short row ends at the center, make the last SR shorter
    if (sum >= stsCount - sr / 4) {
      points[i] -= Math.floor(points[i] / 2);
    }
  });

  return { sts: points, skip: skip + 1 };
}

export function getRaglanPullOverRoundNeckPattern(
  stsPer4Inch: number,
  rowPer4Inch: number,
  measurement: RaglanMeasurement,
  incOffset: [number, number],
  ribSts: number,
  sleeveAdj?: number,
  cuffRatio?: number
): RaglanPattern {
  const stsGauge = stsPer4Inch / 4;
  const rowGauge = rowPer4Inch / 4;

  // Calculate raglan increase sts
  const yokeEndInch =
    measurement.bust_circumference +
    measurement.under_arm_circumference * 2 -
    measurement.underarm * 4;
  const baseNeckInch = yokeEndInch * (4 / 5) * (3 / 4) * (2 / 3) * (2 / 3);
  const radiusFromCenterInch = baseNeckInch / (2 * Math.PI);
  // Omitted length due to wider neck
  const omitInch =
    (measurement.neck_circumference * radiusFromCenterInch) / baseNeckInch -
    radiusFromCenterInch;
  const quarterYokeInch = measurement.yoke_length / 4 - omitInch;
  const quarterYokeRow = quarterYokeInch * rowGauge;
  // Raglan increase sts count during the entire yoke
  const raglanIncSts = getEven(quarterYokeRow) * 4;

  // Calculate initial neck increase
  const neckLineSts = measurement.neck_circumference * stsGauge;
  const expectedStsAtQuarterYoke = yokeEndInch * (4 / 5) * (3 / 4) * stsGauge;
  const incNeeded = expectedStsAtQuarterYoke - (raglanIncSts + neckLineSts);

  // CO & initial increase
  const co = getFloor(neckLineSts, ribSts);
  const incUntil = getRound(co + incNeeded, ribSts);
  const initialInc = incUntil - co;

  // Initial split
  const totalBodySts = measurement.bust_circumference * stsGauge;
  const totalArmSts = measurement.under_arm_circumference * stsGauge;
  const underarmSts = measurement.underarm * stsGauge;
  const splitNeededForBody = getEven(totalBodySts - underarmSts * 2);
  const splitNeededForSleeve = getEven(totalArmSts - underarmSts);
  const totalStsAtYokeEnd = splitNeededForBody + splitNeededForSleeve * 2;
  const totalRaglanIncTimes = (totalStsAtYokeEnd - incUntil) / 8;
  const splitSleeve =
    getEven(splitNeededForSleeve - totalRaglanIncTimes * 2) + (sleeveAdj ?? 0);
  const splitHalfBody = (incUntil - splitSleeve * 2) / 2;

  // Raglan increase
  const totalYokeRow =
    (measurement.yoke_length - omitInch - measurement.neck_band_height) *
    rowGauge;

  // Yoke
  const rawEvery2 = (totalStsAtYokeEnd - incUntil - totalYokeRow * 2) / 4;
  const rawEvery4 = (totalYokeRow - 2 * rawEvery2) / 4;
  const every2 = Math.round(rawEvery2);
  const every4 = Math.round(rawEvery4);

  // Show rows
  const srRow = getEven(measurement.neck_depth * rowGauge);
  const halfShoulder = Math.floor(splitSleeve / 2);
  const srPoints = getRaglanShortRow(
    Math.floor((splitHalfBody + splitSleeve) / 2),
    srRow / 2,
    halfShoulder + incOffset[0],
    every4
  );

  // Total sts count
  const yokeEndBody = splitHalfBody * 2 + (every2 + every4) * 4;
  const yokeEndSleeve = splitSleeve + (every2 + every4) * 2;
  const underarm = Math.round(underarmSts);
  const totalBody = yokeEndBody + underarm * 2;
  const totalSleeve = yokeEndSleeve + underarm;

  // Body waist decrease
  const waistDec = getEven(totalBody * 0.1);

  // Sleeve
  const sleeveRow = Math.round(
    measurement.sleeve_length_from_underarm * rowGauge
  );
  const cuffRow = Math.round(measurement.cuff_length * rowGauge);
  const cuffSts = getRound(measurement.wrist_circumference * stsGauge, ribSts);
  const beforeCuffSts = Math.round(cuffSts * (cuffRatio ?? 1));
  // Rows with no stitch decrease from underarm
  const noDecRow = getEven(rowGauge * 2);
  const decAvailableRow = sleeveRow - cuffRow - noDecRow - 2;
  const taperDecTimes = getEven(totalSleeve - beforeCuffSts) / 2;
  const sleeveDecRows = getSleeveDecRows(decAvailableRow, taperDecTimes);
  const sleeveFinalDecSts = totalSleeve - taperDecTimes * 2 - cuffSts;
  const sleeveFinalDecRow = sleeveRow - cuffRow - 2;

  // Total sts count
  const raglan = {
    sleeve: splitSleeve,
    halfBody: splitHalfBody,
    rowStartID: 2,
    every2,
    every4,
    incSkip: srPoints.skip,
  };
  // getStsCountAtRaglan(raglan);

  const raglanTable = getStsCountAtRaglan(raglan);
  const underArmToHem =
    measurement.total_length -
    measurement.neck_band_height -
    measurement.yoke_length -
    measurement.hem;

  const totalSts =
    co * rowGauge * measurement.neck_band_height + // neckband
    (co + initialInc / 2) + // init increase
    (co + initialInc) +
    raglanTable.reduce((agg, cur) => {
      return agg + cur.back + cur.front + cur.sleeve * 2;
    }, 0) + // yoke
    totalBody * rowGauge * underArmToHem + // body
    (totalBody - waistDec) * (2 + rowGauge * measurement.hem) + // waist decrease + hem
    [...sleeveDecRows, sleeveFinalDecRow].reduce((agg, cur, i) => {
      return agg + (totalSleeve - i * 2) * (cur - (sleeveDecRows[i - 1] ?? 0));
    }, 0) *
      2 + // sleeve both sides decrease
    (totalSleeve - sleeveDecRows.length * 2 - sleeveFinalDecSts) *
      rowGauge *
      (2 + measurement.cuff_length) *
      2; // sleeve final decrease + cuff

  return {
    co,
    initialInc,
    raglan,
    raglanTable,
    shortRow: {
      row: srRow,
      sts: srPoints.sts,
    },
    underarm,
    total: {
      body: totalBody,
      sleeve: totalSleeve,
    },
    waistDec,
    sleeve: {
      decRows: sleeveDecRows,
      finalDec: { sts: sleeveFinalDecSts, row: sleeveFinalDecRow },
    },
    totalSts,
  };
}
