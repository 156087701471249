import { RaglanPattern } from "../../patternMakers/raglan/types";
import {
  Size,
  FOSizing,
  baseFOSizing,
  RaglanCorePart,
  raglanCoreParts,
  RaglanMeasurement,
  FOPart,
  baseBodySizing,
} from "../../utils/sizeBase";
import { getIntroSizingInfo } from "../../utils/misc";
import { IntroSizing } from "../../utils/types";
import profile from "./profile";
import { getRaglanPullOverRoundNeckPattern } from "../../patternMakers/raglan/pulloverRoundNeck";

export const sizes: Size[] = [
  "2XS",
  "XS",
  "S",
  "S+",
  "M",
  "M+",
  "L",
  "L+",
  "XL",
  "2XL",
  "3XL",
  "4XL",
];

const baseMeasurement: FOSizing = {
  ...baseFOSizing,
  M: {
    ...baseFOSizing.M,
    underarm: 2.125,
    neck_circumference: 18.2,
    bust_circumference: 40.15,
    under_arm_circumference: 14.3,
    yoke_length: 9,
    neck_depth: 2.25,
    total_length: 25,
    wrist_circumference: 9.75,
    sleeve_length_from_underarm: 18.75,
    neck_band_height: 1.125,
    hem: 2.5,
  },
};

export const measurements: Partial<Record<FOPart, number>>[] = sizes.map(
  (size) => {
    const ms = baseMeasurement[size] ?? {};
    return Object.keys(ms)
      .filter((key) => raglanCoreParts.includes(key as RaglanCorePart))
      .reduce((cur, key) => {
        return Object.assign(cur, { [key]: ms?.[key as RaglanCorePart] });
      }, {});
  }
);

export const schemaBySize: RaglanPattern[] = measurements.map((m, i) => {
  return getRaglanPullOverRoundNeckPattern(
    profile.gauge.sts_count,
    profile.gauge.row_count,
    m as RaglanMeasurement,
    [2, 1],
    2,
    1,
    1.1
  );
});

export const introSizing: IntroSizing = getIntroSizingInfo(
  sizes,
  baseBodySizing,
  measurements,
  schemaBySize.map((schema) => schema.totalSts),
  ["bust"],
  [
    "bust_circumference",
    "neck_circumference",
    "under_arm_circumference",
    "sleeve_length_from_underarm",
    "total_length",
  ],
  { size: "M", grams: 499 }
);
