import { Direction } from "./types";

/** Pattern display */

export const isWSRow = (
  direction: Direction,
  rowID: number,
  ws?: "even" | "odd"
) => {
  if (direction === "round") return false;
  return rowID % 2 === (ws === "even" ? 0 : 1);
};

export * from "./types";
export * from "./calculator";
export * from "./chartModifier";
export * from "./math";
export * from "./stringModifier";
export * from "./stsRenderer";
