import { Text, Flex } from "@chakra-ui/react";
import { cx, css } from "@linaria/core";
import { Stitch } from "../utils/types";
import GlyphWrapper from "./glyphs/GlyphWrapper";
import definition from "./definition";
interface Props {
  stitches: Stitch[];
  hasWS?: boolean;
}

const styles = {
  wrapper: css`
    stroke-width: 3px !important;
  `,
};

function Description({ side, sts }: { side: string | null; sts: Stitch }) {
  return (
    <Flex gap={2} align="top">
      {side != null && (
        <Text variant="bold" height="fit-content">
          {side}
        </Text>
      )}
      <Text variant="code" height="fit-content">
        {sts.label}
      </Text>
      <Text>
        {sts.instruction}
        {side === "RS: " ? "," : ""}
      </Text>
    </Flex>
  );
}

export default function StitchList({ stitches, hasWS = false }: Props) {
  return (
    <Flex
      direction="column"
      gap={4}
      style={{ border: "1px solid var(--oc-gray-4)" }}
      padding={4}
      borderRadius={8}
      width="fit-content"
    >
      {stitches.map((sts, i) => {
        const showWS = sts.stsID === "k" || sts.stsID === "p";
        return (
          <Flex key={i} align="center" wrap="wrap" gap={2}>
            <GlyphWrapper sts={sts} rectClassName={cx(styles.wrapper)} />
            <Description side={showWS ? "RS: " : null} sts={sts} />
            {showWS && sts.ws != null && (
              <Description side="WS: " sts={definition[sts.ws]} />
            )}
          </Flex>
        );
      })}
    </Flex>
  );
}
