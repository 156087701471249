import { Flex, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { getYdAndM } from "@tanyoknits/shared";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import IntroSection from "../print/IntroSection";
import { getNeedleText } from "../utils/needles";
import { IntroSizing, ProjectProfile } from "../utils/types";
import { allProjects } from "../projects/list";

interface InfoProps {
  name: string;
  profile: ProjectProfile;
  sizing: IntroSizing;
}

export default function Print() {
  const param = useParams();
  const [info, setInfo] = useState<InfoProps | null>(null);

  // Load project info
  useMemo(() => {
    (async () => {
      const { projectID } = param;
      const project = allProjects[projectID as string];
      const info = await import(`../projects/${projectID}/profile`);
      const sizing = await import(`../projects/${projectID}/sizes`);
      setInfo({
        name: project.name,
        profile: info.default,
        sizing: sizing.introSizing,
      });
    })();
  }, [param]);

  if (info == null) {
    return null;
  }

  const { name, profile, sizing } = info;
  const { yarn, needles, gauge, notions, difficulty } = profile;
  const { sizes } = sizing;

  return (
    <Flex direction="column" p={4} gap={8}>
      <Heading>{name}</Heading>
      <Flex gap={8} flexFlow="wrap">
        <IntroSection title="🧶 Yarn">
          <>
            <a href={yarn.url} target="_parent">
              <Text variant="link">{yarn.line}</Text>
            </a>
            by {yarn.maker}
            <Text>Sample color: {yarn.color}</Text>
            <Text>
              1 {yarn.shape} = {getYdAndM(yarn.yards)} / {yarn.grams}g
            </Text>
          </>
        </IntroSection>
        <IntroSection title="🪡 Needles">
          <UnorderedList>
            {needles.map((needle, i) => (
              <ListItem key={i}>
                {getNeedleText(needle.size)} {needle.type} - {needle.usage}
              </ListItem>
            ))}
          </UnorderedList>
        </IntroSection>
        <IntroSection title="📐 Gauge">
          <Text>
            {gauge.sts_count} sts and {gauge.row_count} rows = 4" / 10cm
          </Text>
          <Text>
            in {gauge.sts} with {getNeedleText(gauge.needle)}
          </Text>
        </IntroSection>
        <IntroSection title="🧷 Notions">
          <UnorderedList>
            {notions.map((notion, i) => (
              <ListItem key={i}>{notion}</ListItem>
            ))}
          </UnorderedList>
        </IntroSection>
        <IntroSection title="🌟 Difficulty">
          <Text>{difficulty}</Text>
        </IntroSection>
        <IntroSection title="🔢 Sizing & Measurement">
          {sizes.map((size, i) => (
            <div key={i}>{size}</div>
          ))}
        </IntroSection>
        <IntroSection title="🔢 Instruction">
          <div> instruction</div>
        </IntroSection>
      </Flex>
    </Flex>
  );
}
