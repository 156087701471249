import { css } from "@linaria/core";
import { unit } from ".";
import { GlyphSize } from "../../utils/types";

type SizeValue = { [k in GlyphSize]: number };

export const glyph: {
  size: SizeValue;
  strokeWidth: SizeValue;
  viewBox: SizeValue;
} = {
  size: {
    L: unit * 3,
    M: unit * 2,
    S: unit * 1.5,
  },
  strokeWidth: {
    L: 1.5,
    M: 1,
    S: 1,
  },
  viewBox: {
    L: 1,
    M: 1.5,
    S: 2,
  },
};

export const themeBySize = {
  L: css`
    --chart-font-size: ${(unit * 2) / glyph.viewBox.L}px;
    --chart-grid-size: ${glyph.size.L}px;
    --chart-stroke-width: ${glyph.strokeWidth.L}px;
  `,
  M: css`
    --chart-font-size: ${(unit * 2) / glyph.viewBox.M}px;
    --chart-grid-size: ${glyph.size.M}px;
    --chart-stroke-width: ${glyph.strokeWidth.M}px;
  `,
  S: css`
    --chart-font-size: ${(unit * 2) / glyph.viewBox.S}px;
    --chart-grid-size: ${glyph.size.S}px;
    --chart-stroke-width: ${glyph.strokeWidth.S}px;
  `,
};

export const chartTheme = {
  light: css`
    --chart-color: var(--oc-gray-6);
  `,
  normal: css`
    --chart-color: var(--oc-gray-8);
  `,
};
