import definition from "../stitches/definition";
import {
  getStsCountFromBaseRow,
  getStsCountFromChartRow,
  getStsCountFromStsGroup,
} from "./calculator";
import { getLeastCommonMultiple } from "./math";
import { getRawStsFromBaseRow } from "./stsRenderer";
import {
  BasePattern,
  Chart,
  ChartRow,
  StsMarker,
  Motif,
  MotifColor,
  MotifInBasePattern,
  MotifInStsGroup,
  MotifInfo,
  Stitch,
  StsGroup,
  StsID,
} from "./types";

/** stitch level */
export function getRepeatedSingleStitch(sameStsList: string): Stitch[] {
  return sameStsList.split(" ").flatMap((sameSts) => {
    const [stsID, count] = sameSts.trim().split(":");
    return Array(+(count ?? 1)).fill(definition[stsID as StsID]);
  });
}

/** stitch group level */
export function getStsGroupsByRepeats(stitches: Stitch[]): StsGroup[] {
  let repeatCheckIdx = 0;
  const nested = stitches.reduce((stsGroups: StsGroup[], sts, idx) => {
    // Skip sts that's already applied to the previous repeats
    if (idx < repeatCheckIdx) {
      return stsGroups;
    }

    // Recursive: if repeats aren't found, increase the number of repeated sts
    function checkRepeats(count: number): Stitch[][] {
      const sliceIdx = idx + count;
      const repeats = [stitches.slice(idx, sliceIdx)];
      for (let i = sliceIdx; i <= stitches.length - count; i += count) {
        // check if the following repeats stsIDs the same
        if (
          JSON.stringify(repeats[0]) ===
          JSON.stringify(stitches.slice(i, i + count))
        ) {
          repeats.push(repeats[0]);
        } else {
          break;
        }
      }
      if (repeats.length > 1 || count > stitches.length / 2) {
        return repeats;
      } else {
        return checkRepeats(count + 1);
      }
    }

    const repeats = checkRepeats(1);

    if (repeats.length > 1) {
      const flatten = repeats.flat();
      stsGroups.push({ sts: flatten });
      repeatCheckIdx += flatten.length;
    } else {
      stsGroups.push({ sts: [sts] });
      // Go to the next repeat
      repeatCheckIdx++;
    }
    return stsGroups;
  }, []);

  return nested;
}

/** chart row level */
export function getRegroupedRow(stitches: Stitch[]): ChartRow {
  // Group repeated stitches
  return stitches.reduce((agg: ChartRow, cur: Stitch, i) => {
    if (i > 0 && cur.stsID === stitches[i - 1].stsID) {
      agg[agg.length - 1].sts.push(cur);
    } else {
      agg.push({ sts: [cur] });
    }
    return agg;
  }, []);
}

export function getReplacedBaseRow(
  baseRow: (Stitch | null)[],
  startID: number,
  count: number,
  filledSts: Stitch | Stitch[] = definition.empty
): { startID: number; replaced: Stitch[] } {
  const replaced = Array.isArray(filledSts)
    ? filledSts
    : Array(count).fill(filledSts);
  const rawRow = getRawStsFromBaseRow(baseRow);

  // If replaced starts with null, move to the previous sts, replace it with the raw sts
  let startNullID = startID < 0 ? rawRow.length + startID : startID;
  while (baseRow[startNullID] == null && startNullID >= 0) {
    replaced.unshift(definition[rawRow[startNullID]]);
    startNullID--;
  }

  // If multi-width stitch ends in the middle of replaced sts, move to the next sts, and replace it raw sts
  let endNullID = startID < 0 ? baseRow.length - 1 : startID + count;
  while (baseRow[endNullID] == null && endNullID < baseRow.length - 1) {
    replaced.push(definition[rawRow[endNullID]]);
    endNullID++;
  }
  return { startID: startNullID, replaced };
}

export function getFlattenRow(
  row: ChartRow,
  props?: { motif: MotifInStsGroup }
): ChartRow {
  const noGroup = row.flatMap((group) => group.sts);
  return [{ ...props, sts: noGroup }];
}

/** chart level */
export function getChartFromText(
  motifText: string[],
  groupRepeats: boolean = false
): Chart {
  // MotifText array has multiple items; each item is represents a row
  return motifText.flatMap((rowText) => {
    // Each row can be repeated by ' * n'
    const [singleRowText, rowRepeatCount] = rowText.split(" * ");
    // Stitch groups in a row
    const stsGroupText = singleRowText.trim().split(" ");
    const stitches = stsGroupText.flatMap((group) => {
      return getRepeatedSingleStitch(group);
    });
    const grouped = groupRepeats
      ? getStsGroupsByRepeats(stitches)
      : getRegroupedRow(stitches);
    return Array(+(rowRepeatCount ?? 1)).fill(grouped);
  });
}

export function getTopDownFlippedChart(
  chart: Chart,
  lastRow?: ChartRow
): Chart {
  return (
    chart
      // Even rows are bland, so reverse the last row of the original chart
      .slice(0, chart.length - 1)
      .reverse()
      .map((row) => {
        return row.map((stsGroup) => {
          return {
            ...stsGroup,
            sts: stsGroup.sts.map((s) =>
              s.topDownFlip != null ? definition[s.topDownFlip] : s
            ),
          };
        });
      })
      // Add last row, if not specified, add the original last row
      .concat([lastRow || chart[chart.length - 1]])
  );
}

export function getRightLeftFlippedChart(chart: Chart): Chart {
  return chart.slice(0).map((row) => {
    return row
      .slice(0)
      .reverse()
      .map((stsGroup) => {
        return {
          ...stsGroup,
          sts: stsGroup.sts
            .slice(0)
            .reverse()
            .map((s) =>
              s.rightLeftFlip != null ? definition[s.rightLeftFlip] : s
            ),
        };
      });
  });
}

export function getVerticalStackedChart(
  motifs: Motif[],
  showMotif: boolean = false // when there are multiple motifs exists so showing color is necessary
): Chart {
  const maxSts = Math.max(...motifs.map((motif) => motif.stsCount));
  const stacked = motifs.flatMap((motif) => {
    const { stsCount, chart, id } = motif;
    return chart.map((row) => {
      const motifRow: StsGroup[] = row.map((stsGroup) => {
        return id == null
          ? stsGroup
          : {
              ...stsGroup,
              motif: {
                id,
                stsCount,
                rowCount: chart.length,
                isFirstRow: false,
              },
            };
      });
      const paddedRow: StsGroup[] = motifRow;
      const startPadding = Math.round((maxSts - stsCount) / 2);
      if (startPadding > 0) {
        paddedRow.unshift({ sts: Array(startPadding).fill(definition.k) });
      }
      const endPadding = maxSts - stsCount - startPadding;
      if (endPadding > 0) {
        paddedRow.push({ sts: Array(endPadding).fill(definition.k) });
      }

      if (showMotif) {
        return [...paddedRow];
      } else {
        // If there's no motifs to color, just flatten the row
        const stitches = getFlattenRow(paddedRow)[0].sts;
        return getStsGroupsByRepeats(stitches);
      }
    });
  });

  return stacked;
}

// Create horizontally merged chart
export function getMergedChart(
  chartList: Chart[],
  maxRowCount?: number
): Chart {
  const rowCount =
    maxRowCount ??
    getLeastCommonMultiple(chartList.map((chart) => chart.length));
  return Array.from({ length: rowCount }).map((_, i) => {
    return chartList.flatMap((chart) => chart[i % chart.length]);
  });
}

// Create chart form motifs with gutters in between if exists
export function getChartFromMotifs(
  motifsWithGutters: (Motif | string)[]
): Chart {
  // Convert gutter in text and motifs to chart with single sts group
  const chartList = motifsWithGutters.map((item) => {
    if (typeof item === "string") {
      // gutter in string like "p:2"
      return getChartFromText([item]);
    } else {
      return item.chart.map((row, i) => {
        const motif = {
          id: item.id as MotifColor,
          stsCount: item.stsCount,
          rowCount: item.chart.length,
          stEquivCount: item.stEquivCount ?? item.stsCount,
          isFirstRow: i === 0,
        };
        return getFlattenRow(row, { motif });
      });
    }
  });
  return getMergedChart(chartList);
}

export function getMotifFilledChart(
  motifChart: Chart,
  stsCount: number,
  rowCount: number,
  edgeSts: number = 0 // sts to be filled with k at the edge of the chart
): Chart {
  const stsCountPerRow = getStsCountFromChartRow(motifChart[0]);
  const modCount = stsCount % stsCountPerRow;
  const startPadding = Math.round(modCount / 2);
  const endPadding = modCount - startPadding;

  return Array.from({ length: rowCount }).map((_, i) => {
    const chartRow = motifChart[i % motifChart.length];
    const rowRepeats: ChartRow[] = [];
    if (startPadding > 0) {
      // Slice the end of the row
      const baseRow = getBaseRowFromChartRow(chartRow);
      spliceRow(baseRow, 0, baseRow.length - startPadding);
      spliceRow(baseRow, baseRow.length - startPadding, edgeSts, definition.k);
      rowRepeats.push([
        {
          sts: baseRow
            .splice(-startPadding)
            .filter((s) => s != null) as Stitch[],
        },
      ]);
    }
    while (
      startPadding + stsCountPerRow * rowRepeats.length <=
      stsCount + endPadding
    ) {
      rowRepeats.push(chartRow);
    }
    if (endPadding > 0) {
      const baseRow = getBaseRowFromChartRow(chartRow);
      spliceRow(baseRow, null, baseRow.length - endPadding);
      spliceRow(baseRow, endPadding - edgeSts, edgeSts, definition.k);
      rowRepeats.push([
        {
          sts: baseRow
            .slice(0, endPadding)
            .filter((s) => s != null) as Stitch[],
        },
      ]);
    }

    return rowRepeats.flat();
  });
}

export function getChartWithNoGroups(chart: Chart): Chart {
  return chart.map((row) => {
    return getFlattenRow(row);
  });
}

/** pattern level */
export function spliceRow(
  baseRow: (Stitch | null)[],
  startStsID: number | null,
  count: number,
  sts: Stitch | Stitch[] = definition.empty
): void {
  const { startID, replaced } = getReplacedBaseRow(
    baseRow,
    startStsID ?? count * -1,
    count,
    sts
  );
  baseRow.splice(startID, replaced.length, ...replaced);
}

export function getPushedMotifList(
  motifList: MotifInBasePattern[],
  stsCount: number,
  maxStsCount: number
): MotifInBasePattern[] {
  return motifList
    .flatMap((motif) => {
      const startStsNum = motif.startStsNum + stsCount;
      return { ...motif, startStsNum };
    })
    .filter(
      (motif) =>
        motif.startStsNum > 0 &&
        motif.startStsNum + motif.stsCount < maxStsCount
    );
}

export function getChartFromBasePattern({
  baseChart,
  motifList = [],
  motifOffset = 0,
}: BasePattern): Chart {
  const validMotifList = motifList
    .filter((motif) => {
      return motif.startStsNum + motif.stsCount > 0;
    })
    .map((motif) => {
      // Adjust start sts number and count for the first motif if it's cut in the middle
      if (motif.startStsNum < 0) {
        return {
          ...motif,
          startStsNum: 0,
          stsCount: motif.stsCount + motif.startStsNum,
        };
      }
      return motif;
    });

  return baseChart.map((row, rowIdx) => {
    const groupedByMotif = row.reduce(
      (agg: { stsGroups: StsGroup[]; motifID: number }, cur, stsIdx) => {
        if (cur == null) {
          return agg;
        }
        const motif = validMotifList[agg.motifID] ?? {};
        const { id, stsCount, startStsNum, rowCount } = motif;
        // Motif starts at this stitch
        if (stsIdx === startStsNum) {
          agg.stsGroups.push({
            sts: [cur],
            motif: {
              id: id as MotifColor,
              stsCount,
              rowCount,
              isFirstRow: (rowIdx - motifOffset) % rowCount === 0,
            },
          });
        } else if (stsIdx === 0) {
          // first stitch
          agg.stsGroups.push({ sts: [cur] });
        } else if (stsIdx === startStsNum + stsCount) {
          // After motif ends; new stitch group without motif
          agg.stsGroups.push({ sts: [cur] });
          agg.motifID++;
        } else {
          // Append to the current sts group
          agg.stsGroups[agg.stsGroups.length - 1].sts.push(cur);
        }
        return agg;
      },
      {
        stsGroups: [],
        motifID: 0,
      }
    );

    return groupedByMotif.stsGroups;
  });
}

export function getSlicedBasePattern(
  basePattern: BasePattern,
  rowRange: [number, number | null],
  stsRange: [number, number | null]
): BasePattern {
  const rows = [rowRange[0], rowRange[1] ?? basePattern.baseChart.length];
  const sts = [stsRange[0], stsRange[1] ?? basePattern.baseChart[0].length];

  const { baseChart, motifList, motifOffset } = basePattern;
  const sliced = baseChart.slice(...rows).map((row) => {
    const dup = [...row]; // duplicate to avoid mutation
    spliceRow(dup, 0, sts[0]);
    spliceRow(dup, null, row.length - sts[1]);
    return dup.slice(...sts);
  });

  return {
    baseChart: sliced,
    motifList: getPushedMotifList(
      motifList ?? [],
      -stsRange[0],
      sts[1] - sts[0]
    ),
    motifOffset: sts[0] + (motifOffset ?? 0),
  };
}

export function getSlicedChartFromBasePattern(
  basePattern: BasePattern,
  rowRange: [number, number],
  stsRange: [number, number]
): Chart {
  return getChartFromBasePattern(
    getSlicedBasePattern(basePattern, rowRange, stsRange)
  );
}

export function getBaseRowFromChartRow(row: ChartRow): (Stitch | null)[] {
  return row.flatMap((stsGroup) => {
    return stsGroup.sts.flatMap((sts) => {
      return sts.unitWidth != null && sts.unitWidth > 1
        ? [sts, ...Array(sts.unitWidth - 1).fill(null)]
        : [sts];
    });
  });
}

export function getBasePatternFromChart(
  chart: Chart,
  patternRowCount?: number | null,
  motifOffset: number = 0,
  combinedMotifInfo?: MotifInfo
): BasePattern {
  const baseChart = Array.from({ length: patternRowCount ?? chart.length }).map(
    (_, i) => {
      const row = chart[(i + chart.length - motifOffset) % chart.length];
      return getBaseRowFromChartRow(row);
    }
  );

  const motifList: MotifInBasePattern[] = [];
  let stsSum = 0;
  chart[0].forEach((stsGroup) => {
    if (stsGroup.motif != null) {
      const { id, stsCount, rowCount } = stsGroup.motif;
      const motifInfo = combinedMotifInfo ?? { id, stsCount, rowCount };
      motifList.push({
        ...motifInfo, // override id, stsCount, and rowCount
        startStsNum: stsSum,
      });
    }
    stsSum += getStsCountFromStsGroup(stsGroup);
  });

  return {
    baseChart,
    motifList,
    motifOffset,
  };
}

export function getMergedBasePattern(
  basePatterns: BasePattern[],
  maxRowCount?: number,
  partNames?: string[]
): BasePattern {
  const baseCharts = basePatterns.map((p) => p.baseChart);
  const rowCount =
    maxRowCount ?? Math.max(...baseCharts.map((chart) => chart.length));
  const baseChart = Array.from({ length: rowCount }).map((_, i) => {
    const row: (Stitch | null)[] = baseCharts.flatMap(
      (chart) => chart[i % chart.length]
    );
    return row;
  });

  const motifList: MotifInBasePattern[] = [];
  let stsCount = 0;
  const stsMarkers: StsMarker[] = [];
  let totalStsCount = 0;
  basePatterns.forEach((p, i) => {
    const chartStsCount = p.baseChart[0].length;
    totalStsCount += chartStsCount;
    stsMarkers.push(
      partNames?.[i] != null
        ? { pos: totalStsCount, part: partNames?.[i] }
        : { pos: totalStsCount }
    );
    if (p.motifList != null) {
      motifList.push(
        ...getPushedMotifList(p.motifList, stsCount, chartStsCount + stsCount)
      );
    }
    stsCount += chartStsCount;
  });
  return {
    baseChart,
    motifList,
    stsMarkers,
  };
}
