/** Ellipse equation 
  x^2 / a^2 + y^2 / b^2 = 1; x axis: sts, y axis: row, a: stsCount, b: rowCount
  Returns sts count from 0,0 every other row
  */
export function getCurveSRCount(stsCount: number, rowCount: number): number[] {
  const curveStsCount: number[] = [];
  let sum = 0;
  for (let row = 1; row < rowCount; row += 2) {
    const sts = Math.round(
      Math.sqrt(
        (1 - (row * row) / (rowCount * rowCount)) * (stsCount * stsCount)
      )
    );
    curveStsCount.push(sts - sum);
    sum += stsCount - sts;
  }
  return curveStsCount;
}

/** Line
      Get the start count, then increase equally till the last row
      Returns sts count from the end, every other row
      */
export function getLineSRCount(stsCount: number, rowCount: number): number[] {
  const start = Math.round((stsCount / rowCount) * 2);
  const inc = Math.ceil((stsCount - start) / ((rowCount - 2) / 2));
  const lineStsCount: number[] = [];
  let sum = start;
  for (let row = 1; row < rowCount; row += 2) {
    lineStsCount.push(Math.max(stsCount - sum, 0)); // last row may have fewer inc
    sum += inc;
  }
  return lineStsCount;
}
