import { css, cx } from "@linaria/core";
import { Text } from "@chakra-ui/react";
import React, { MouseEvent } from "react";
import { themeBySize } from "./styles/theme";
import GlyphWrapper from "./glyphs/GlyphWrapper";
import { unit } from "./styles";
import {
  Chart,
  ChartRow,
  Direction,
  GlyphSize,
  Stitch,
  StsGroup,
  MotifColor,
  StsMarker,
} from "../utils/types";
import { motifColors } from "./styles";
import { getStsCountFromChartRow, getValidStsLabel, isWSRow } from "../utils";
import WrittenRow from "./WrittenRow";

export type FocusType = [number | null, number | null];
export type SetFocusType = (arg0: FocusType) => void;

export interface ChartProps {
  chart: Chart;
  direction?: Direction;
  focus?: FocusType;
  setFocus?: SetFocusType;
  size?: GlyphSize;
  startRowID?: number;
  className?: string;
  isOnlyRowClickable?: boolean;
  showStsCountPerRow?: boolean;
  stsMarkers?: StsMarker[];
}

const padding = unit / 2;

const styles = {
  wrapper: css`
    width: min-content;
  `,
  number: css`
    font-family: var(--code);
    font-weight: 700;
    font-size: var(--chart-font-size);
  `,
  chart: css`
    display: flex;
    flex-direction: column-reverse;
  `,
  chartRow: css`
    display: flex;
    flex-direction: row-reverse;
  `,
  rowNum: css`
    display: flex;
    align-items: center;
    cursor: default;
    width: calc(var(--chart-grid-size) * 2);
    :first-child {
      padding-left: ${padding}px;
    }
    :last-child {
      padding-right: ${padding}px;
      justify-content: flex-end;
    }
  `,
  chartRowSelected: css`
    margin-right: -2px;
    border: 2px solid var(--oc-gray-8);
  `,
  stitchRepeats: css`
    display: flex;
    flex-direction: row-reverse;
  `,
  selectedSts: css`
    box-shadow: 0 0 2px 2px var(--theme-8);
    background-color: var(--theme-1);
    // Show the shadow(outline) effect over the other stitches;
    z-index: 2;
  `,
  clickable: css`
    cursor: pointer;
  `,
  stsNum: css`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-around;
    width: 100%;
    cursor: default;
  `,
  stsGroupWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `,
  expanded: css`
    padding: ${unit}px calc(var(--chart-grid-size) * 2) ${unit * 2}px;
    min-width: ${unit * 50}px;
  `,
  arrow: css`
    font-weight: bold;
  `,
  stsNumWrapper: css`
    padding-top: ${padding / 2}px;
    display: flex;
    flex-direction: row-reverse;
    padding-right: calc(var(--chart-grid-size) * 2);
    padding-left: calc(var(--chart-grid-size) * 2);
    div {
      width: var(--chart-grid-size);
      text-align: center;
    }
  `,
};

const getMotifStyle = (id: MotifColor, isFirstRow: boolean): string => {
  const firstRow = css`
    border-bottom-style: solid;
    border-bottom-width: var(--chart-stroke-width);
    box-sizing: content-box;
    height: calc(var(--chart-grid-size) - var(--chart-stroke-width));
  `;
  return `${isFirstRow ? `${firstRow} ` : ""}${motifColors[id as MotifColor]}`;
};

function setRowStsFocus(
  event: MouseEvent,
  isOnlyRowClickable: boolean,
  selectedFocus: FocusType,
  setFocus: SetFocusType
): void {
  if (isOnlyRowClickable) {
    event.stopPropagation();
    setFocus([selectedFocus[0], null]);
  } else {
    setFocus(selectedFocus);
  }
}

export default function ChartComponent({
  chart,
  direction = "round",
  focus,
  setFocus,
  size = "L",
  startRowID = 0,
  isOnlyRowClickable = false,
  showStsCountPerRow = false,
  stsMarkers,
  className,
}: ChartProps) {
  return (
    <div className={cx(styles.wrapper, themeBySize[size], className)}>
      <div className={cx(styles.chart)}>
        {chart.map((row: ChartRow, i) => {
          const isRowSelected =
            focus?.[0] === i && focus?.[1] === null && setFocus != null;
          const isW = isWSRow(direction, i);
          const rowText = i + startRowID + 1;
          const dirText = direction === "round" ? "Rnd" : "Row";
          return (
            <div
              key={i}
              className={cx(isRowSelected && styles.chartRowSelected)}
            >
              <div className={cx(styles.number, styles.chartRow)}>
                <div className={cx(styles.rowNum)}>{isW ? null : rowText}</div>
                {row.map((stsGroup: StsGroup, j) => (
                  <div
                    key={j}
                    className={cx(
                      styles.stitchRepeats,
                      // Interactive motif - clicked sts group
                      i === focus?.[0] &&
                        j === focus?.[1] &&
                        styles.selectedSts,
                      setFocus && styles.clickable,
                      // Patter with motifs - parts of motifs
                      stsGroup.motif != null &&
                        getMotifStyle(
                          stsGroup.motif.id,
                          stsGroup.motif.isFirstRow
                        )
                    )}
                    onClick={(e) =>
                      setFocus &&
                      setRowStsFocus(e, isOnlyRowClickable, [i, j], setFocus)
                    }
                  >
                    {stsGroup.sts.map((sts: Stitch, k) => {
                      if (sts === undefined) {
                        console.log(chart, i, row, stsGroup, j, i);
                      }
                      return <GlyphWrapper key={k} sts={sts} size={size} />;
                    })}
                  </div>
                ))}
                <div className={cx(styles.rowNum)}>{isW ? rowText : null}</div>
              </div>
              {isRowSelected && (
                <>
                  <div className={cx(styles.number, styles.stsNumWrapper)}>
                    {getValidStsLabel(row).map((num, i) => (
                      <div key={i}>{num != null ? num % 100 : ""}</div>
                    ))}
                  </div>
                  <div
                    className={cx(styles.expanded)}
                    style={{ maxWidth: window.innerWidth - unit * 4 }}
                  >
                    <Text
                      variant="code-highlighted"
                      mb={2}
                      display="inline-block"
                    >
                      {isW ? (
                        <span className={cx(styles.arrow)}>→ </span>
                      ) : (
                        <span className={cx(styles.arrow)}>← </span>
                      )}
                      {dirText} {rowText} {isW ? "(WS)" : "(RS)"}
                      {showStsCountPerRow &&
                        ` ${getStsCountFromChartRow(row, false, true)} sts`}
                    </Text>
                    <WrittenRow
                      row={row}
                      rowID={i}
                      direction={direction}
                      stsMarkers={stsMarkers}
                      display="inline"
                    />
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      {!isOnlyRowClickable && (
        <div className={cx(styles.number, styles.stsNumWrapper)}>
          {Array.from({ length: getStsCountFromChartRow(chart[0]) }).map(
            (_, i) => (
              <div key={i}>{(i + 1) % 100}</div>
            )
          )}
        </div>
      )}
    </div>
  );
}
