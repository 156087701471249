import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { Outlet, Link, useLocation, useOutletContext } from "react-router-dom";
import { ProjectID, allProjects, projectMenus } from "../projects/list";
import { LinariaClassName, css, cx } from "@linaria/core";
import { ProjectMenu, ProjectOverview } from "../utils/types";
import { theme } from "../styles";
import Footer from "./Footer";
import ProjectNav from "./ProjectNav";

const styles: Record<ProjectID, LinariaClassName> = {
  "pine-pullover": css`
    background-color: #5c330a;
    background-image: linear-gradient(19deg, #5c330a 0%, #948bac 100%);
    min-height: 100vh;
    color: var(--oc-white);
  `,
  "tiara-vest": css`
    background-color: #907c5c;
    background-image: linear-gradient(24deg, var(--theme-8) 0%, #907c5c 100%);
    min-height: 100vh;
    color: var(--oc-white);
  `,
  "juniper-vest": css`
    background-color: #948bac;
    background-image: linear-gradient(18deg, var(--theme-8) 0%, #948bac 100%);
    min-height: 100vh;
    color: var(--oc-white);
  `,
  "salvia-cardigan": css`
    background-color: var(--theme-2);
    background-image: linear-gradient(
      18deg,
      var(--theme-8) 0%,
      var(--theme-2) 100%
    );
    min-height: 100vh;
    color: var(--oc-white);
  `,
  "serena-t-shirt": css`
    background-color: #a36a96;
    background-image: linear-gradient(18deg, var(--theme-8) 0%, #a36a96 100%);
    min-height: 100vh;
    color: var(--oc-white);
  `,
  "big-winter-sweater": css`
    background-color: #446a56;
    background-image: linear-gradient(18deg, var(--theme-9) 0%, #446a56 100%);
    min-height: 100vh;
    color: var(--oc-white);
  `,
};

type ContextType = {
  projectID: string;
};

export default function Project() {
  const [projectInfo, setProjectInfo] = useState<ProjectOverview | null>(null);
  const [menu, setMenu] = useState<number | null>(null);

  const location = useLocation();
  const [projectID, pageID] = location?.pathname.split("/").slice(1);

  useMemo(() => {
    const page = pageID as ProjectMenu;
    const project = allProjects[projectID as string];
    setProjectInfo(project);
    setMenu(project.menus.indexOf(page ?? "intro"));
  }, [projectID, pageID]);

  if (projectInfo == null) {
    return null;
  }

  const { name, menus } = projectInfo;

  return (
    <Box className={cx(theme[projectInfo.theme ?? "gray"], styles[projectID])}>
      <Box
        style={{
          minHeight: "100vh",
          backgroundImage: `url('${process.env.PUBLIC_URL}/bg.svg`,
        }}
      >
        <Flex align="center" justify="space-between" p={4}>
          <Flex>
            <Link to="/">
              <Text fontSize="2xl" pr={2}>{`\u2190`}</Text>
            </Link>
            <Heading as="h1" size="lg">
              <Link to="intro">{name}</Link>
            </Heading>
          </Flex>
          <ProjectNav menus={menus} selectedMenu={menu} />
        </Flex>
        <Outlet context={{ projectID }} />
        {menu != null && (
          <Flex p={4} gap={8}>
            {menu > 0 && (
              <Link to={menus[menu - 1]}>
                <Text fontSize="xl" pr={2} variant="link">
                  {`\u2190`}
                  {`  Prev: `}
                  {projectMenus[menus[menu - 1]]}
                </Text>
              </Link>
            )}
            {menu < menus.length - 1 && (
              <Link to={menus[menu + 1]}>
                <Text fontSize="xl" pr={2} variant="link">
                  {`Next: `}
                  {projectMenus[menus[menu + 1]]}
                  {` \u2192`}
                </Text>
              </Link>
            )}
          </Flex>
        )}
        <Footer />
      </Box>
    </Box>
  );
}

export function useProject() {
  return useOutletContext<ContextType>();
}
