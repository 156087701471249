import React from "react";

export const C21RP = (
  <>
    <line x1="24" y1="0" x2="0" y2="24" />
    <line x1="72" y1="0" x2="48" y2="24" />
    <circle cx="8" cy="8" r="3" />
  </>
);

export const C21LP = (
  <>
    <line x1="0" y1="0" x2="24" y2="24" />
    <line x1="48" y1="0" x2="72" y2="24" />
    <circle cx="64" cy="8" r="3" />
  </>
);

export const C22RP = (
  <>
    <line x1="48" y1="0" x2="0" y2="24" />
    <line x1="96" y1="0" x2="48" y2="24" />
    <circle cx="8" cy="8" r="3" />
    <circle cx="16" cy="8" r="3" />
  </>
);

export const C22LP = (
  <>
    <line x1="0" y1="0" x2="48" y2="24" />
    <line x1="48" y1="0" x2="96" y2="24" />
    <circle cx="88" cy="8" r="3" />
    <circle cx="80" cy="8" r="3" />
  </>
);

export const C12R = (
  <>
    <line x1="144" y1="0" x2="0" y2="24" />
    <line x1="288" y1="0" x2="144" y2="24" />
  </>
);

export const C12L = (
  <>
    <line x1="0" y1="0" x2="144" y2="24" />
    <line x1="144" y1="0" x2="288" y2="24" />
  </>
);

export const C8R = (
  <>
    <line x1="96" y1="0" x2="0" y2="24" />
    <line x1="192" y1="0" x2="96" y2="24" />
  </>
);

export const C8L = (
  <>
    <line x1="0" y1="0" x2="96" y2="24" />
    <line x1="96" y1="0" x2="192" y2="24" />
  </>
);

export const C6R = (
  <>
    <line x1="72" y1="0" x2="0" y2="24" />
    <line x1="144" y1="0" x2="72" y2="24" />
  </>
);

export const C6L = (
  <>
    <line x1="0" y1="0" x2="72" y2="24" />
    <line x1="72" y1="0" x2="144" y2="24" />
  </>
);

export const C4R = (
  <>
    <line x1="48" y1="0" x2="0" y2="24" />
    <line x1="96" y1="0" x2="48" y2="24" />
  </>
);

export const C4L = (
  <>
    <line x1="0" y1="0" x2="48" y2="24" />
    <line x1="48" y1="0" x2="96" y2="24" />
  </>
);

export const C2R = (
  <>
    <line x1="24" y1="0" x2="0" y2="24" />
    <line x1="48" y1="0" x2="24" y2="24" />
  </>
);

export const C2L = (
  <>
    <line x1="0" y1="0" x2="24" y2="24" />
    <line x1="24" y1="0" x2="48" y2="24" />
  </>
);
