import { ChartRow } from "./types";

export function getKebabCase(text: string): string {
  return text
    .split(" ")
    .map((t) => t.toLowerCase())
    .join("-");
}

export function getCapitalized(word: string) {
  const withSpace = word.split("_").join(" ");
  return withSpace.charAt(0).toUpperCase() + withSpace.slice(1);
}

export function getTwitterShareLink(message: string, url: string) {
  const twitterText = [...message.split(" "), "by", "@tanyoknits", url].join(
    "%20"
  );
  return `https://twitter.com/intent/tweet?text=${twitterText}`;
}

/** followings are used for button band */
export function getStsListRepeats(stsList: string[]): string[] {
  return stsList
    .reduce(
      (agg, cur) => {
        if (cur === agg[agg.length - 1].sts) {
          agg[agg.length - 1].count += 1;
        } else {
          agg.push({ sts: cur, count: 1 });
        }
        return agg;
      },
      [{ sts: stsList[0], count: 0 }]
    )
    .map((s) => (s.count > 1 ? `(${s.sts}) x ${s.count}` : s.sts));
}

export function getStringGroupRepeats(stsList: string[]): {
  groupRepeats: string[];
  groupRepeatsApplicable: boolean;
} {
  let groupRepeatsApplicable = false;

  let repeatCheckIdx = 0;

  const groupRepeats = stsList.reduce((labels: string[], sts, idx) => {
    // Skip sts that's already applied to the previous repeats
    if (idx < repeatCheckIdx) {
      return labels;
    }
    // Recursive: if repeats aren't found, increase the number of repeated sts
    // start with two (e.g., ['a', 'b'])
    function checkRepeats(count: number): string[][] {
      const repeats = [stsList.slice(idx, idx + count)];
      for (let i = idx + count; i <= stsList.length - count; i += count) {
        // check if the following repeats are the same
        if (
          JSON.stringify(repeats[0]) ===
          JSON.stringify(stsList.slice(i, i + count))
        ) {
          repeats.push(repeats[0]);
          groupRepeatsApplicable = true;
        } else {
          break;
        }
      }
      if (repeats.length > 1 || count > stsList.length / 2) {
        return repeats;
      }
      return checkRepeats(count + 1);
    }

    const repeats = checkRepeats(2);

    if (repeats.length > 1) {
      const labelRepeats = repeats[0];
      labels.push(`[${labelRepeats.join(", ")}] x ${repeats.length}`);
      // Skip all repeated ones
      repeatCheckIdx += repeats.flat().length;
    } else {
      labels.push(sts);
      // Go to the next repeat
      repeatCheckIdx++;
    }
    return labels;
  }, []);

  return {
    groupRepeats: groupRepeats,
    groupRepeatsApplicable,
  };
}

export function getValidStsLabel(row: ChartRow): (number | null)[] {
  const labelList: (number | null)[] = [];
  let count = 0;
  row.forEach((stsGroup) => {
    stsGroup.sts.forEach((sts) => {
      if (sts.isNonSts) {
        labelList.push(null);
      } else {
        const width = sts.unitWidth ?? 1;
        for (let i = 0; i < width; i++) {
          count += 1;
          labelList.push(count);
        }
      }
    });
  });
  return labelList;
}
