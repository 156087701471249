import React from "react";

export const EMPTY = null;

export const SR_WS = (
  <>
    <path
      d="M20 9C20 9 15.7252 9 10.1679 9C4.61069 9 4.61069 18 10.1679 18C15.7252 18 20 18 20 18"
      fill="none"
    />
    <path d="M17 6L20 9L17 12" />
  </>
);

export const SR_RS = (
  <>
    <path
      d="M6 9C6 9 10.2748 9 15.8321 9C21.3893 9 21.3893 18 15.8321 18C10.2748 18 6 18 6 18"
      fill="none"
    />
    <path d="M9 6L6 9L9 12" />
  </>
);

export const CY_RS = (
  <>
    <path d="M13 5L21 13" />
    <path d="M10 8L18 16" />
    <path d="M25 1L6 20" />
  </>
);

export const CY_WS = (
  <>
    <path d="M13 5L5 13" />
    <path d="M16 8L8 16" />
    <path d="M1 1L20 20" />
  </>
);

export const NY_WS = (
  <>
    <line
      x1="24"
      x2="0"
      y1="0"
      y2="24"
      strokeWidth="2"
      className="onlyOverview"
    />
    <path d="M24 0 v 12 l -12 -12 z" className="onlyOverview" />
  </>
);

export const NY_RS = (
  <>
    <line
      x1="0"
      x2="24"
      y1="0"
      y2="24"
      strokeWidth="2"
      className="onlyOverview"
    />
  </>
);
