import { AbbKey } from "../utils/types";
import { abbreviations } from "../stitches/abbreviation";
import ExplanationList from "./ExplanationList";

interface Props {
  list: AbbKey[];
}
export default function Abbr({ list }: Props) {
  const abbList = list
    .sort((a, b) => a.localeCompare(b, "en", { sensitivity: "base" }))
    .map((abbr, i) => {
      return {
        title: abbr,
        desc: abbreviations[abbr as AbbKey],
      };
    });

  return (
    <ExplanationList
      overview="Following abbreviations are used in the pattern instruction."
      list={abbList}
    />
  );
}
