// General
type NoteType = "cut_yarn" | "no_cut_yarn";
const partNotes: { [k in NoteType]: string } = {
  cut_yarn: "✂️ Cut yarn at the end",
  no_cut_yarn: "🔜  Do not cut yarn, continue to next part",
};

export function getPartNotes(type: NoteType): string {
  return partNotes[type];
}

export function getCONote(stsCount: number): string {
  return `🎬 CO ${stsCount} sts`;
}

export function getBONote(stsCount: number, method?: string): string {
  return `🏁 BO ${stsCount} sts${
    method != null ? ` with ${method} method` : ""
  }`;
}

// Increase or Decrease
export function getEveryXChangeNote(
  type: "decrease" | "increase",
  startRow: number,
  first: [number, number],
  second: [number, number],
  isBothSides: boolean = false
): string {
  const decreaseNode = `${
    type === "decrease" ? "➖ Decrease" : "➕ Increase"
  } 1 sts${isBothSides ? " on each side" : ""} ${
    first[0] > 0
      ? `${first[0]} time${first[0] > 1 ? "s" : ""} every ${
          first[1]
        } rows from row ${startRow}`
      : ""
  }${first[0] > 0 && second[0] > 0 ? ", then " : ""}${
    second[0] > 0
      ? `${second[0]} time${second[0] > 1 ? "s" : ""} every ${
          second[1]
        } rows from row ${startRow + first[0] * first[1]}`
      : ""
  }`;
  return decreaseNode;
}

// Increase
export function getLastCOIncNote(
  coSts: number,
  side: "both" | "ws" | "rs"
): string {
  return `➕ Increase ${coSts} sts${side === "both" ? " on each side" : ""} ${
    side === "both"
      ? "in the last two rows"
      : `at the end of ${side === "rs" ? "second " : ""}last row`
  }`;
}

// Top down notes
export function getTopDownNotes(style: string): string[] {
  return [
    `This ${style} is worked top down as in following parts in order.`,
    `Top/Down, Front/Back, Right/Left are based on the viewpoint of wearing a ${style}.`,
    "Parts in circle are done in the round, those in square are done flat.",
  ];
}
