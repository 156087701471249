import { Box, Text } from "@chakra-ui/react";
import { MultiLevelText } from "../utils/types";
import React from "react";
import PlainTextList from "./PlainTextList";

interface Props {
  list: MultiLevelText[];
}
export default function MultiLevelTextList({ list }: Props) {
  return (
    // Box is needed for bullet point indent
    <Box maxW="4xl">
      {list.map((mainItem, i) => (
        <React.Fragment key={i}>
          <Text mt={i > 0 ? 4 : 0} mb={2}>
            {mainItem.main}
          </Text>
          {mainItem.sub != null && (
            <PlainTextList list={mainItem.sub} hasIndent={true} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
}
