export interface Arc {
  x: number;
  y: number;
  a: number;
}

export function getAngle(totalSts: number, sts: number): number {
  return (Math.PI * 2 * sts) / totalSts;
}

export function getMarkerLinePoint(
  totalSts: number,
  sts: number,
  innerRadius: number,
  outerRadius: number
): { x1: number; y1: number; x2: number; y2: number } {
  const a = getAngle(totalSts, sts);
  const x1 = Math.sin(a) * -innerRadius;
  const y1 = Math.cos(a) * -innerRadius;
  const x2 = Math.sin(a) * -outerRadius;
  const y2 = Math.cos(a) * -outerRadius;
  return { x1, y1, x2, y2 };
}

export function getShorRowArcPoints(
  radius: number,
  startP: number,
  endP: number,
  totalSts: number
): { start: Arc; end: Arc } {
  const radian = (sts: number): number =>
    (Math.PI * 2 * sts) / totalSts - Math.PI / 2;
  const startA = radian(endP);
  const endA = radian(startP);
  return {
    start: {
      x: Math.cos(startA) * radius,
      y: Math.sin(startA) * radius,
      a: radian(endP),
    },
    end: {
      x: Math.cos(endA) * radius,
      y: Math.sin(endA) * radius,
      a: radian(startP),
    },
  };
}
