import {
  BasePattern,
  definition,
  getDec1StsIDList,
  getRawStsFromBaseRow,
  spliceRow,
} from "@tanyoknits/shared";
import { VNeckCardiganPattern } from "../raglan/types";

const { k, empty, k2tog, ssk } = definition;

export function getSleeveDecRows(
  decAvailableRow: number,
  taperDecTimes: number,
  decOffset?: number
): number[] {
  const taperDecEveryXRow = Math.floor(decAvailableRow / taperDecTimes);
  const oneThirdDecRow = Math.floor(taperDecTimes / 3);
  // Decrease with more acceleration towards the the end
  const sleeveDecRows = [1 + taperDecEveryXRow];
  for (let i = 1; i < taperDecTimes; i++) {
    let everyX = taperDecEveryXRow;
    if (i > 0 && i <= oneThirdDecRow) {
      everyX += 2;
    } else if (i >= taperDecTimes - oneThirdDecRow) {
      everyX -= 2;
    }
    sleeveDecRows.push(sleeveDecRows[sleeveDecRows.length - 1] + everyX);
  }

  return sleeveDecRows.map(
    (n) => n + (decOffset ?? sleeveDecRows[0]) - sleeveDecRows[0]
  );
}

export function getSleeveBasePattern(
  basePattern: BasePattern,
  schema: VNeckCardiganPattern
): BasePattern {
  const split = schema.rows.split;
  const { cuffDecRow, decRows, cuffSts } = schema.arm;
  const baseChart = basePattern.baseChart.slice(
    split - 1,
    split - 1 + cuffDecRow
  );
  decRows.forEach((decRow, i) => {
    const baseRow = baseChart[decRow];
    const emptyCount = i + 1;
    const emptySts = Array(emptyCount).fill(empty);
    spliceRow(baseRow, 0, emptyCount + 3, [...emptySts, k, k, k2tog]);
    spliceRow(baseRow, null, emptyCount + 3, [ssk, k, k, ...emptySts]);
    const nextRowID = i < decRows.length - 1 ? decRows[i + 1] : cuffDecRow;
    for (let j = decRow + 1; j < nextRowID; j++) {
      const row = baseChart[j];
      spliceRow(row, 0, emptyCount);
      spliceRow(row, null, emptyCount);
    }
  });
  const cuffDecStsList = getDec1StsIDList(
    schema.total.sleeve - decRows.length * 2,
    cuffSts
  );

  // Cuff sts decrease
  const baseRow = baseChart[cuffDecRow - 1];
  const baseRowSts = getRawStsFromBaseRow(baseRow);
  spliceRow(
    baseRow,
    0,
    baseRow.length,
    baseRowSts.map((s) => definition[s])
  );
  cuffDecStsList.forEach((decStsID, i) => {
    const stsID = decStsID + decRows.length;
    spliceRow(baseRow, stsID, 2, [k2tog, empty]);
  });

  return { ...basePattern, baseChart };
}
