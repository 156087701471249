import {
  Table,
  Thead,
  Tbody,
  TableContainer,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import { Direction } from "@tanyoknits/shared";
import { RaglanCalc, RaglanTableRow } from "../patternMakers/raglan/types";

interface Props extends RaglanCalc {
  direction: Direction;
}

export function getStsCountAtRaglan(raglan: RaglanCalc): RaglanTableRow[] {
  const { rowStartID, halfBody, sleeve, every2, every4, incSkip } = raglan;
  const incList = [
    {
      row: rowStartID,
      front: halfBody,
      sleeve,
      back: halfBody,
    },
  ];

  for (let i = 0; i < every2 * 2 + every4 * 4; i++) {
    const lastItem = incList[incList.length - 1];
    const { row, front, sleeve, back } = lastItem;
    const isFrontInc =
      (i >= incSkip * 2 && i <= every2 * 2 + incSkip * 4) ||
      (i > (every2 + incSkip) * 2 && i % 4 === (every2 * 2) % 4);
    const isBackInc = i < every2 * 2 || i % 4 === (every2 * 2) % 4;

    incList.push({
      row: row + 1,
      front: front + (i % 2 === 0 && isFrontInc ? 2 : 0),
      back: back + (i % 2 === 0 && isBackInc ? 2 : 0),
      sleeve:
        sleeve +
        (i % 2 === 0 && isFrontInc ? 1 : 0) +
        (i % 2 === 0 && isBackInc ? 1 : 0),
    });
  }
  return incList;
}

export function RaglanIncTable(props: Props) {
  const incList = getStsCountAtRaglan(props);

  return (
    <TableContainer width="fit-content">
      <Table size="sm" variant="striped" colorScheme="gray">
        <Thead>
          <Tr>
            <Th />
            <Th textAlign="center">Back</Th>
            <Th textAlign="center">Sleeve</Th>
            <Th textAlign="center">Front</Th>
          </Tr>
        </Thead>
        <Tbody>
          {incList.map((row, i) => (
            <Tr key={i}>
              <Td textAlign="center">
                {props.direction === "round" ? "Rnd" : "Row"}{" "}
                {i === 0 ? "start" : row.row}
              </Td>
              <Td
                isNumeric
                fontWeight={i > 0 && row.back > incList[i - 1].back ? 700 : 400}
              >
                {row.back}
              </Td>
              <Td
                isNumeric
                fontWeight={
                  i > 0 && row.sleeve > incList[i - 1].sleeve ? 700 : 400
                }
              >
                {row.sleeve}
              </Td>
              <Td
                isNumeric
                fontWeight={
                  i > 0 && row.front > incList[i - 1].front ? 700 : 400
                }
              >
                {row.front}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
