import { Box, Heading } from "@chakra-ui/react";
import ToolHelp from "./ToolHelp";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
interface Props {
  title: string;
  subtitle?: string;
  message: string;
  url: string;
  children: React.ReactNode;
}

const FONT_FAMILY =
  '"Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto","Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue"';
const theme = extendTheme({
  fonts: {
    body: FONT_FAMILY,
    heading: FONT_FAMILY,
    mono: '"Inconsolata", source-code-pro, Menlo, Monaco, Consolas, monospace',
  },
  fontWeight: {
    heading: 700,
    text: 400,
  },
  styles: {
    global: {
      "body, heading": {
        color: "gray.800",
        lineHeight: "tall",
      },
    },
  },
});

export default function ToolWrapper({
  title,
  subtitle,
  message,
  url,
  children,
}: Props) {
  return (
    <ChakraProvider theme={theme}>
      <Box padding={4}>
        <Heading as="h1" pb={subtitle != null ? 1 : 4}>
          {title}
        </Heading>
        {subtitle && (
          <Heading as="h2" size="md" fontWeight={400} pb={4}>
            {subtitle}
          </Heading>
        )}
        {children}
        <ToolHelp message={message} url={url} />
      </Box>
    </ChakraProvider>
  );
}
