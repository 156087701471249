import { needles } from "../../utils/needles";
import { ProjectProfile } from "../../utils/types";

const profile: ProjectProfile = {
  yarn: {
    maker: "Manos del Uruguay",
    line: "Serena",
    color: "7458 Mulled Wine",
    url: "https://www.ravelry.com/yarns/library/manos-del-uruguay-serena",
    shape: "skein",
    yards: 170,
    grams: 50,
  },
  needles: [
    {
      type: "circular",
      size: needles[8],
      usage: "Body",
    },
    { type: "circular", size: needles[6], usage: "Bands" },
  ],
  gauge: {
    sts: "Stockinette stitch",
    sts_count: 25,
    row_count: 35,
    needle: needles[8],
  },
  notions: ["Tapestry needle", "Stitch markers"],
  difficulty: "Intermediate",
  photoCount: 0,
};

export default profile;
