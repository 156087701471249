import { needles } from "../../utils/needles";
import { ProjectProfile } from "../../utils/types";

const profile: ProjectProfile = {
  yarn: {
    maker: "Knit Picks",
    line: "Wool of Andes Superwash",
    color: "Haze Heather",
    url: "https://www.knitpicks.com/haze-heather/p/26320",
    shape: "skein",
    yards: 110,
    grams: 50,
  },
  needles: [
    { type: "circular", size: needles[10], usage: "main body" },
    { type: "circular", size: needles[0], usage: "rib" },
  ],
  gauge: {
    sts: "St st",
    sts_count: 20,
    row_count: 25,
    needle: needles[10],
  },
  notions: ["Stitch markers", "Tapestry needle"],
  difficulty: "Intermediate",
  photoCount: 1,
};

export default profile;
