import {
  getEven,
  getRound,
  BasePattern,
  spliceRow,
  definition,
} from "@tanyoknits/shared";
import { RaglanMeasurement } from "../../utils/sizeBase";
import { getLineSRCount } from "../shortRow";
import { getSleeveDecRows } from "../common/sleeves";
import { VNeckCardiganPattern } from "./types";

const { k, rli, lli, m1r, m1l, sr_rs, sr_ws, empty } = definition;

export const vNeckRaglanCardiganOverview: string[] = [
  "After CO, starting from the front left neck, work from the top down flat.",
  "Short row shaping is worked as you increase stitches in raglan yoke.",
  "As you keep working on raglan yoke, incorporate stitch patterns",
  "After you finish the raglan increase, separate body and sleeves, put sleeve stitches in scrap yarn or stitch holder.",
  "Work the body flat as you keep working stitch patterns. Work two sleeves",
  "Work button band along the two sides of front and neck, add button holes on the front right as you go.",
  "Sew buttons on the other left side of button band.",
];

export function getVNeckPatternSchema(
  stsPer4Inch: number,
  rowPer4Inch: number,
  measurement: RaglanMeasurement,
  sleeveAdj: number = 0,
  cuffRatio: number = 1.2
): VNeckCardiganPattern {
  const stsGauge = stsPer4Inch / 4;
  const rowGauge = rowPer4Inch / 4;

  const totalNeck = measurement.neck_circumference * stsGauge;
  const totalEachSleeve = measurement.under_arm_circumference * stsGauge;
  const underarm = measurement.underarm * stsGauge;
  const sleeveYorkEnd = totalEachSleeve - underarm;
  const totalBust = measurement.bust_circumference * stsGauge;
  const bodyYorkEnd = totalBust - underarm * 2;
  const totalYork = measurement.yoke_length * rowGauge;
  const totalYorkEnd = sleeveYorkEnd * 2 + bodyYorkEnd;
  const yokeBase = {
    sleeve: getRound(sleeveYorkEnd),
    body: getRound(bodyYorkEnd),
  };

  // CO & Increase
  const totalInc8 = getRound((totalYorkEnd - totalNeck) / 8);
  const start = {
    sleeve: getEven(yokeBase.sleeve - totalInc8 * 2),
    back: getEven((yokeBase.body - totalInc8 * 4) / 2),
    frontOneSide: 2,
  };
  const co = start.sleeve * 2 + start.back + start.frontOneSide * 2;
  const every2Rows = Math.round((totalYorkEnd - totalNeck - 2 * totalYork) / 4);
  const every4Rows = totalInc8 - every2Rows;
  const frontIncSkip = getEven((measurement.neck_depth * rowGauge) / 2);
  const raglan = { every2Rows, every4Rows, frontIncSkip };

  // Front V increase
  const buttonBandSts = getRound(measurement.neck_band_height * stsGauge) - 2;
  const frontSts = getRound(start.back / 2) - getRound(buttonBandSts / 2);
  const ragIncRowEnd = 1 + every2Rows * 2 + every4Rows * 4;
  const every4RowWithRaglan = Math.ceil(ragIncRowEnd / 4) - frontIncSkip / 2;
  const every4RowAfterRaglan = frontSts - every4RowWithRaglan;
  const yokeSplitBack = start.back + totalInc8 * 2;
  const yokeSplitFrontOneSide =
    start.frontOneSide + every4RowWithRaglan + totalInc8;
  const yokeSplit = {
    sleeve: start.sleeve + totalInc8 * 2,
    back: yokeSplitBack,
    frontOneSide: yokeSplitFrontOneSide,
    body: yokeSplitBack + yokeSplitFrontOneSide * 2,
    underarm: getEven(underarm),
    sleeveAdj,
  };

  // Total
  const endBody = yokeSplit.body + yokeSplit.underarm * 2 - sleeveAdj * 2;
  const endSleeve = yokeSplit.sleeve + yokeSplit.underarm + sleeveAdj;
  const endBodyWithVInc = endBody + every4RowAfterRaglan * 2;
  const total = {
    bodyBeforeVInc: endBody,
    sleeve: endSleeve,
    body: endBodyWithVInc,
  };

  // Rows
  const startRow = 1;
  const every2RowIncStart = startRow;
  const every4RowIncStart = every2RowIncStart + raglan.every2Rows * 2;
  const splitRow = every4RowIncStart + raglan.every4Rows * 4;
  const afterRaglanIncStart = splitRow + 2;
  const hemRow = measurement.hem * rowGauge;
  const hemDecStart = getEven(measurement.total_length * rowGauge - hemRow) - 1;

  // Sleeve
  const cuffRow = getRound(measurement.cuff_length * rowGauge);
  const cuffSts = getEven(measurement.wrist_circumference * stsGauge);
  const decOffset = getRound(rowGauge * 2); // 2 inch
  const sleeveRow = getRound(
    measurement.sleeve_length_from_underarm * rowGauge
  );
  const beforeCuffSts = getRound(cuffSts * 1.4); // cuff ratio
  // Rows with no stitch decrease from underarm
  const decAvailableRow = sleeveRow - cuffRow - decOffset - 2;
  const taperDecTimes = getEven(total.sleeve - beforeCuffSts) / 2;
  const decRows = getSleeveDecRows(decAvailableRow, taperDecTimes, decOffset);
  const cuffDecSts = total.sleeve - taperDecTimes * 2 - cuffSts;
  const cuffDecRow = sleeveRow - cuffRow - 2;
  const cuffBO = cuffDecRow + 2 + getRound(cuffRow * 1.2);

  return {
    co,
    start,
    raglan,
    vNeck: {
      every4RowWithRaglan,
      every4RowAfterRaglan,
    },
    yokeSplit,
    total,
    rows: {
      every2RowIncStart,
      every4RowIncStart,
      split: splitRow,
      afterRaglanIncStart,
      lastNeckInc: afterRaglanIncStart + every4RowAfterRaglan * 4 - 2,
      hemDecStart,
      bo: hemDecStart + getRound(hemRow * cuffRatio),
    },
    arm: {
      cuffSts,
      decOffset,
      decRows,
      cuffDecRow,
      cuffDecSts,
      cuffBO,
    },
    totalSts: 0,
  };
}

export function getVNeckYokeBasePatternByParts(
  leftBasePattern: BasePattern,
  rightBasePattern: BasePattern,
  backBasePattern: BasePattern,
  yokeBasePattern: BasePattern,
  schema: VNeckCardiganPattern
): BasePattern[] {
  // Base chart
  const leftBaseChart = leftBasePattern.baseChart;
  const rightBaseChart = rightBasePattern.baseChart;
  const backBaseChart = backBasePattern.baseChart;
  const leftSleeveYokeBaseChart = [
    ...yokeBasePattern.baseChart.map((row) => {
      return [...row];
    }),
  ];
  const rightSleeveYokeBaseChart = [
    ...yokeBasePattern.baseChart.map((row) => {
      return [...row];
    }),
  ];

  const { start, raglan, vNeck, yokeSplit } = schema;
  const { sleeve } = start;
  const { every4RowWithRaglan, every4RowAfterRaglan } = vNeck;
  const { every2Rows, every4Rows, frontIncSkip } = raglan;
  const { frontOneSide } = yokeSplit;

  // Each front side stitch count
  const stsCount = frontOneSide + every4RowAfterRaglan;
  const vNeckInc = every4RowWithRaglan + every4RowAfterRaglan;
  const raglanInc = every2Rows + every4Rows;

  // Short row
  for (let i = 0; i < frontIncSkip * 2; i++) {
    spliceRow(leftBaseChart[i], 0, stsCount);
    spliceRow(rightBaseChart[i], 0, stsCount);
    spliceRow(leftSleeveYokeBaseChart[i], 0, raglanInc);
    spliceRow(rightSleeveYokeBaseChart[i], null, raglanInc);
    if (i === 0) {
      spliceRow(leftBaseChart[i], vNeckInc, 2, k);
    }
    if (i === frontIncSkip * 2 - 2) {
      spliceRow(rightBaseChart[i], stsCount - vNeckInc - 2, 2, k);
    }
    if (i === frontIncSkip * 2 - 1) {
      spliceRow(leftBaseChart[i], vNeckInc, 2, k);
      spliceRow(rightBaseChart[i], stsCount - vNeckInc - 2, 2, k);
    }
  }
  const necklineSR = getLineSRCount(sleeve, frontIncSkip * 2);
  necklineSR.slice(0, frontIncSkip - 1).forEach((count, i) => {
    spliceRow(leftSleeveYokeBaseChart[i * 2 + 1], raglanInc, count, [
      ...Array(count - 1).fill(empty),
      sr_rs,
    ]);
    spliceRow(leftSleeveYokeBaseChart[i * 2 + 2], raglanInc, count);
    spliceRow(rightSleeveYokeBaseChart[i * 2], null, raglanInc + count, [
      sr_ws,
      ...Array(count - 1).fill(empty),
    ]);
    spliceRow(rightSleeveYokeBaseChart[i * 2 + 1], null, raglanInc + count);
  });

  // Neck line increase
  for (let i = 0; i < vNeckInc; i++) {
    const baseRowID = frontIncSkip * 2 + i * 4;
    const emptyCount = vNeckInc - i - 1;
    const emptySts = Array(emptyCount).fill(empty);
    const k2 = [k, k];
    if (leftBaseChart[baseRowID] == null) {
      break;
    }
    spliceRow(leftBaseChart[baseRowID], 0, emptyCount + 4, [
      ...emptySts,
      k,
      m1r,
      ...k2,
    ]);
    spliceRow(rightBaseChart[baseRowID], null, emptyCount + 4, [
      ...k2,
      m1l,
      k,
      ...emptySts,
    ]);
    for (let j = 1; j < 4; j++) {
      const leftRow = leftBaseChart[baseRowID + j];
      const rightRow = rightBaseChart[baseRowID + j];
      if (leftRow == null || rightRow == null) {
        break;
      }
      spliceRow(leftRow, 0, emptyCount + 2, [...emptySts, ...k2]);
      spliceRow(rightRow, null, emptyCount + 2, [...k2, ...emptySts]);
    }
  }

  // Raglan increase
  function increaseRaglan(
    everyXRows: number,
    times: number,
    startEmptyCount: number,
    startRowID: number,
    side: "front" | "back"
  ): void {
    for (let i = 0; i < times; i++) {
      const baseRowID = startRowID + i * everyXRows;
      const emptyCount = startEmptyCount - i - 1;
      const emptySts = Array(emptyCount).fill(empty);
      for (let j = 0; j < everyXRows; j++) {
        const lb = leftBaseChart[baseRowID + j];
        const rb = rightBaseChart[baseRowID + j];
        const ls = leftSleeveYokeBaseChart[baseRowID + j];
        const rs = rightSleeveYokeBaseChart[baseRowID + j];
        const bb = backBaseChart[baseRowID + j];
        const parts = side === "front" ? [lb, rb, ls, rs] : [bb, bb, rs, ls];
        if (j === 0) {
          spliceRow(parts[0], null, emptyCount + 2, [rli, k, ...emptySts]);
          spliceRow(parts[1], 0, emptyCount + 2, [...emptySts, k, lli]);
          spliceRow(parts[2], 0, emptyCount + 2, [...emptySts, k, lli]);
          spliceRow(parts[3], null, emptyCount + 2, [rli, k, ...emptySts]);
        } else {
          spliceRow(parts[0], null, emptyCount + 1, [k, ...emptySts]);
          spliceRow(parts[1], 0, emptyCount + 1, [...emptySts, k]);
          spliceRow(parts[2], 0, emptyCount + 1, [...emptySts, k]);
          spliceRow(parts[3], null, emptyCount + 1, [k, ...emptySts]);
        }
      }
    }
  }

  increaseRaglan(
    2,
    every2Rows + frontIncSkip,
    raglanInc,
    frontIncSkip * 2,
    "front"
  );
  increaseRaglan(
    4,
    every4Rows - frontIncSkip,
    raglanInc - every2Rows - frontIncSkip,
    frontIncSkip * 2 + (every2Rows + frontIncSkip) * 2,
    "front"
  );
  increaseRaglan(2, every2Rows, raglanInc, 0, "back");
  increaseRaglan(4, every4Rows, raglanInc - every2Rows, every2Rows * 2, "back");

  return [
    leftBasePattern,
    {
      ...yokeBasePattern,
      baseChart: leftSleeveYokeBaseChart,
    },
    backBasePattern,
    {
      ...yokeBasePattern,
      baseChart: rightSleeveYokeBaseChart,
    },
    rightBasePattern,
  ];
}
