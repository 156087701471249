import { cx } from "@linaria/core";
import { Arc, getAngle, getShorRowArcPoints } from "./utils/shortRowDiagram";
import { M1_RADIUS, styles } from "./PulloverShortRowDiagram";

interface Props {
  rsInit: number;
  wsInit: number;
  initRadius: number;
  rowPadding: number;
  totalSts: number;
  sts: number[];
  row: number;
  incSkip: number;
  raglanOffset: [number, number];
  markers: number[];
  selectedRow: number | null;
}

export default function ShortRowPath({
  rsInit,
  wsInit,
  initRadius,
  rowPadding,
  totalSts,
  sts,
  row,
  incSkip,
  raglanOffset,
  markers,
  selectedRow,
}: Props) {
  // First wrap & turn points on RS and WS
  const rs: [number, number][] = [];
  const ws: [number, number][] = [];
  sts.forEach((s, i) => {
    if (i === 0) {
      // For arc path, starting point should be smaller than end point, thus
      // on right side, the actual knitting start & end points are reversed
      rs.push([-rsInit - s, 0]);
      ws.push([-rsInit - s, wsInit + s]);
    } else {
      const rsStart = ws[ws.length - 1][1];
      const wsStart = rs[rs.length - 1][0];
      rs.push([wsStart - s, rsStart]);
      ws.push([wsStart - s, rsStart + s]);
    }
  });

  //   const rowPadding = (WIDTH / 2 - OFFSET - BASE_RADIUS) / (row + 1);
  const bothSides = rs
    .flatMap((r, i) => [r, ws[i]])
    // Additional rs work till BOR after the last w& t
    .concat([[0, ws[ws.length - 1][1]]]);

  const bothSidesPath = bothSides.map((points, i) => {
    const radius = initRadius + rowPadding * i;
    const { start, end } = getShorRowArcPoints(
      radius,
      points[0],
      points[1],
      totalSts
    );
    const d = [
      "M",
      start.x,
      start.y,
      "A",
      radius,
      radius,
      0,
      start.a - end.a <= Math.PI ? "0" : "1",
      0,
      end.x,
      end.y,
    ].join(" ");

    // wrap & turn
    const wt: Arc = {
      x: i % 2 === 0 ? end.x : start.x,
      y: i % 2 === 0 ? end.y : start.y,
      a: i % 2 === 0 ? end.a : start.a,
    };

    // increase around raglan
    const [markerA, markerB, markerC, markerD] = markers;
    const m1rMarkers =
      i % 2 === 0 ? [markerA - raglanOffset[0]] : [markerD - raglanOffset[1]];
    const m1lMarkers =
      i % 2 === 0 ? [markerA + raglanOffset[1]] : [markerD + raglanOffset[0]];
    if (i >= incSkip * 2) {
      m1rMarkers.push(
        i % 2 === 0 ? markerB - raglanOffset[1] : markerC - raglanOffset[0]
      );
      m1lMarkers.push(
        i % 2 === 0 ? markerB + raglanOffset[0] : markerC + raglanOffset[1]
      );
    }
    const m1List = [...m1rMarkers, ...m1lMarkers].map((marker, i) => {
      const m1 = getAngle(totalSts, marker) - Math.PI;
      return {
        cx: Math.sin(m1) * radius,
        cy: Math.cos(m1) * radius,
      };
    });

    return (
      <g
        key={i}
        className={cx(styles.shortRowPath, i % 2 === 0 ? styles.rs : styles.ws)}
      >
        {i < row && ( // wrap & turn display
          <line
            x1={wt.x}
            y1={wt.y}
            x2={wt.x + Math.cos(wt.a) * rowPadding}
            y2={wt.y + Math.sin(wt.a) * rowPadding}
          />
        )}
        <path d={d} className={cx(selectedRow === i && styles.selectedPath)} />
        {i < row &&
          m1List.map((m1, j) => (
            <circle
              key={j}
              {...m1}
              r={selectedRow === i ? M1_RADIUS * 1.5 : M1_RADIUS}
              className={cx(
                i % 2 === 0 ? styles.m1rs : styles.m1ws,
                selectedRow === i && styles.selectedCircle
              )}
            />
          ))}
      </g>
    );
  });

  return <>{bothSidesPath}</>;
}
