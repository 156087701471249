import { HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { ProjectMenu } from "../utils/types";
import { projectMenus } from "../projects/list";
import { Link } from "react-router-dom";

interface Props {
  menus: ProjectMenu[];
  selectedMenu: number | null;
}

export default function ProjectNav({ menus, selectedMenu }: Props) {
  return (
    <>
      <Box display={["block", "none"]}>
        <Menu variant="nav">
          <MenuButton
            as={IconButton}
            role="button"
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="flush"
          />
          <MenuList>
            {menus.map((m, i) => (
              <MenuItem key={i}>
                <Link to={m}>{projectMenus[m]}</Link>
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      </Box>
      <Flex direction={["column", "row"]} gap={6} display={["none", "flex"]}>
        {menus.map((m, i) => (
          <Link key={i} to={m}>
            <Text
              variant={selectedMenu === i ? "selected" : "link"}
              fontSize="lg"
            >
              {projectMenus[m]}
            </Text>
          </Link>
        ))}
      </Flex>
    </>
  );
}
