import { getSum } from "./math";
import { ChartRow, Stitch, StsGroup } from "./types";

/** Get increase or decrease points in a row. 
This is used for getting a pick up points */
export function getChangeStsList(startSts: number, endSts: number): number[] {
  const diff = Math.abs(endSts - startSts);
  const everyXsts = Array(diff).fill(startSts / diff);
  everyXsts.forEach((val, i) => {
    const rounded = Math.round(val);
    const diff = rounded - val;
    everyXsts[i] = rounded;
    if (i < everyXsts.length - 1) {
      everyXsts[i + 1] -= diff;
    }
  });
  return everyXsts;
}

export function getDec1StsIDList(
  startSts: number,
  endSts: number,
  avoidEnd: boolean = true
): number[] {
  const idList: number[] = [];
  const stsList = getChangeStsList(startSts, endSts);
  let sum = avoidEnd ? -Math.floor(stsList[0] / 2) : 0;
  stsList.forEach((sts) => {
    sum += sts;
    idList.push(sum);
  });
  return idList;
}

// For 2 sts dec, merge two stitch groups into one
export function getDec2StsList(startSts: number, endSts: number): number[] {
  const dec1List = getChangeStsList(startSts, endSts);
  return dec1List.reduce((agg: number[], cur, i) => {
    if (i % 2 === 0) {
      agg.push(cur);
    } else {
      agg[agg.length - 1] += cur;
    }
    return agg;
  }, []);
}

export function getStsCountFromStsGroup(
  stsGroup: StsGroup,
  isSideTrimmed: boolean = false
): number {
  return stsGroup.sts.reduce(
    (agg, cur) =>
      (agg += isSideTrimmed && cur.isNonSts ? 0 : cur.unitWidth ?? 1),
    0
  );
}

export function getStsCountFromChartRow(
  chartRow: ChartRow,
  isStEquiv: boolean = false,
  isSideTrimmed: boolean = false
): number {
  const stsCounts = chartRow.map((stsGroup) =>
    isStEquiv && stsGroup.motif?.stEquivCount != null
      ? stsGroup.motif?.stEquivCount
      : getStsCountFromStsGroup(stsGroup, isSideTrimmed)
  );
  return getSum(stsCounts);
}

export function getStsCountFromBaseRow(
  baseChartRow: (Stitch | null)[]
): number {
  return baseChartRow.reduce(
    (agg, cur) => (agg += cur == null || cur.isNonSts ? 0 : cur.unitWidth ?? 1),
    0
  );
}
