import { Flex, Select, VStack, Wrap } from "@chakra-ui/react";
import { css } from "@linaria/core";
import ShortRowPath from "./ShortRowPath";
import ShortRowLabels from "./ShortRowLabels";
import ShortRowLegends from "./ShortRowLegends";
import ShortRowText from "./ShortRowText";
import ShortRowMarkers from "./ShortRowMarkers";
import { useState } from "react";

export interface ShortRowDiagramProps {
  row: number;
  sts: number[];
  incSkip: number;
  sleeve: number;
  halfBody: number;
  // sts count on each side of raglan before M1 [from body, inside sleeve]
  raglanOffset: [number, number];
}

export const styles = {
  base: css`
    fill: none;
    stroke: var(--oc-gray-8);
  `,
  marker: css`
    stroke: var(--oc-gray-8);
  `,
  markerbg: css`
    fill: var(--oc-gray-8);
  `,
  markerbgInv: css`
    fill: var(--oc-white);
    stroke-width: 2;
    stroke: var(--oc-gray-8);
  `,
  markerChar: css`
    text-anchor: middle;
    alignment-baseline: central;
    fill: var(--oc-white);
    font-weight: 700;
  `,
  markerCharInv: css`
    text-anchor: middle;
    alignment-baseline: central;
    fill: var(--oc-gray-8);
    font-weight: 700;
  `,
  stsCount: css`
    text-anchor: middle;
    alignment-baseline: central;
    fill: var(--oc-gray-5);
  `,
  label: css`
    font-family: var(--code);
    text-anchor: middle;
  `,
  shortRowPath: css`
    fill: none;
    stroke-width: 2;
    stroke: var(--oc-gray-5);
    shape-rendering: geometricprecision;
  `,
  rs: css``,
  ws: css`
    stroke-dasharray: 4 0 2;
  `,
  selectedPath: css`
    stroke: var(--highlight);
    stroke-width: 4;
  `,
  m1rs: css`
    fill: var(--oc-gray-8);
    stroke: none;
  `,
  m1ws: css`
    fill: var(--oc-white);
    stroke: var(--oc-gray-8);
    stroke-width: 2;
    stroke-dasharray: 0;
  `,
  selectedCircle: css`
    stroke: var(--highlight);
    stroke-width: 3;
  `,
};

export const WIDTH = 600;
export const MARGIN = 40;
export const HEIGHT = 600;
export const BASE_RADIUS = (WIDTH / 2) * 0.25;
export const OFFSET = 10;
export const MARKER_RADIUS = 12;
export const M1_RADIUS = 4;

export default function PulloverShortRowDiagram({
  row,
  sts,
  incSkip,
  sleeve,
  halfBody,
  raglanOffset,
}: ShortRowDiagramProps) {
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const totalSts = sleeve * 2 + halfBody * 2;

  /** markers  */
  const markerA = Math.round(halfBody / 2);
  const markerB = markerA + sleeve;
  const markerC = markerB + halfBody;
  const markerD = markerC + sleeve;
  const markers = [markerA, markerB, markerC, markerD];

  /** show rows */
  // First wrap & turn points on RS and WS
  const rsInit = markerA + Math.round(sleeve / 2);
  const wsInit = totalSts - markerD + Math.round(sleeve / 2);
  // distance between two rows
  const rowPadding = (WIDTH / 2 - OFFSET - BASE_RADIUS) / (row + 1);

  return (
    <VStack align="left">
      <Select
        placeholder="Select SR to highlight"
        width="fit-content"
        onChange={(e) => setSelectedRow(+e.target.value)}
      >
        {Array.from({ length: row }).map((_, i) => {
          return (
            <option key={i} value={i}>
              SR {i + 1} ({i % 2 === 0 ? "RS" : "WS"})
            </option>
          );
        })}
      </Select>
      <Wrap spacing={8}>
        <VStack align="left" width={{ md: "100%", lg: "2xl" }}>
          <svg
            width="100%"
            height="100%"
            viewBox={`0 0 ${WIDTH + MARGIN * 2} ${HEIGHT + MARGIN * 2}`}
          >
            <g
              transform={`translate(${WIDTH / 2 + MARGIN}, ${
                HEIGHT / 2 + MARGIN
              })`}
            >
              <ShortRowLabels />
              <ShortRowPath
                rsInit={rsInit}
                wsInit={wsInit}
                initRadius={BASE_RADIUS + OFFSET * 2}
                rowPadding={rowPadding}
                totalSts={totalSts}
                sts={sts}
                row={row}
                incSkip={incSkip}
                raglanOffset={raglanOffset}
                markers={markers}
                selectedRow={selectedRow}
              />
              <ShortRowMarkers
                markers={markers}
                totalSts={totalSts}
                rsInit={rsInit}
                wsInit={wsInit}
              />
            </g>
          </svg>
          <ShortRowLegends />
        </VStack>
        <Wrap maxW={{ md: "100%", lg: "2xl" }}>
          <ShortRowText
            row={row}
            sts={sts}
            incSkip={incSkip}
            sleeve={sleeve}
            halfBody={halfBody}
            raglanOffset={raglanOffset}
            selectedRow={selectedRow}
          />
        </Wrap>
      </Wrap>
    </VStack>
  );
}
