import {
  getChartFromText,
  getTopDownFlippedChart,
} from "../../utils/chartModifier";
import { Chart, Motif } from "../../utils/types";

/** Default text
  [
    "c2l k c2l k c2l k:3 c2r k c2r k c2r",
    "k:19",
    "k c2l k c2l k c2l k c2r k c2r k c2r k",
    "k:19",
    "k:2 c2l k c2l c2r k c2l c2r k c2r k:2",
    "k:19",
    "k:3 c2l k c2l k:3 c2r k c2r k:3",
    "k:19",
    "k:4 c2l k c2l k c2r k c2r k:4",
    "k:19",
    "k:5 c2l c2r k c2l c2r k:5",
    "k:19",
    "k:6 c2l k:3 c2r k:6",
    "k:19",
    "k:7 c2l k c2r k:7",
    "k:19",
  ]
*/

export function getChart(size = 3, isTopDownReversed = false): Chart {
  // create chart
  const text = Array.from({ length: size }).flatMap((_, i) => {
    const count = size - i;
    const row1 = [
      ...Array(i * 3).fill("k"),
      ...Array(count).fill("c2l k"),
      "k",
      ...Array(count).fill("k c2r"),
      ...Array(i * 3).fill("k"),
    ].join(" ");
    const rowEven = `k:${size * 2 * 3 + 1}`;
    const row3 = [
      ...Array(i * 3 + 1).fill("k"),
      ...Array(count).fill("c2l k"),
      ...Array(count).fill("c2r k"),
      ...Array(i * 3).fill("k"),
    ].join(" ");
    const row5 = [
      ...Array(i * 3 + 1).fill("k"),
      ...Array(count - 1).fill("k c2l"),
      "c2r",
      "k",
      "c2l",
      ...Array(count - 1).fill("c2r k"),
      ...Array(i * 3 + 1).fill("k"),
    ].join(" ");

    const rows = [row1, rowEven, row3, rowEven];
    if (i < size - 1) {
      rows.push(row5, rowEven);
    }
    return rows;
  });

  const pyramid = getChartFromText(text);

  return isTopDownReversed ? getTopDownFlippedChart(pyramid) : pyramid;
}

export function getMotif(size = 3, isTopDownReversed = false): Motif {
  return {
    title: "Pyramid",
    stsCount: size * 6 + 1,
    chart: getChart(size, isTopDownReversed),
  };
}
