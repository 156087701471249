import { PageInfo, SchemaPartInstruction } from "../../utils/types";

const commonPatternBySizePageInfo: PageInfo[] = [
  {
    id: "overview",
    label: "Overview",
    isLinear: true,
  },
  {
    id: "back",
    label: "Back top",
    isLinear: true,
  },
  {
    id: "front",
    label: "Front top",
    isLinear: true,
  },
  {
    id: "body",
    label: "Body from underarm",
    isLinear: true,
  },
];

export const vestPatternBySizePageInfo: PageInfo[] = [
  ...commonPatternBySizePageInfo,
  {
    id: "bands",
    label: "Bands",
  },
];

export const pulloverPatternBySizePageInfo: PageInfo[] = [
  ...commonPatternBySizePageInfo,
  {
    id: "sleeves",
    label: "Sleeves",
    isLinear: true,
  },
  {
    id: "neckband",
    label: "Neckband",
  },
];

export const commonPartsInstruction: SchemaPartInstruction[] = [
  {
    group: commonPatternBySizePageInfo[1].label,
    instruction: "Right half of back top",
    direction: "flat",
  },
  { instruction: "Left half of the back top", direction: "flat" },
  {
    instruction: "Back to armholes",
    direction: "flat",
  },
  {
    group: commonPatternBySizePageInfo[2].label,
    instruction: "Left half of front left",
    direction: "flat",
  },
  {
    instruction: "Right half of front top",
    direction: "flat",
  },
  { instruction: "Front to armholes", direction: "flat" },
  {
    group: commonPatternBySizePageInfo[3].label,
    instruction: "Front and back bottom",
    direction: "round",
  },
];

export const commonLastPartInstruction: SchemaPartInstruction = {
  instruction: "Neckband",
  direction: "round",
};

export const vestPartsInstruction: SchemaPartInstruction[] = [
  ...commonPartsInstruction,
  {
    group: vestPatternBySizePageInfo[4].label,
    instruction: "Right armhole band",
    direction: "round",
  },
  { instruction: "Left armhole band", direction: "round" },
  commonLastPartInstruction,
];

export const pulloverPartsInstruction: SchemaPartInstruction[] = [
  ...commonPartsInstruction,
  {
    group: pulloverPatternBySizePageInfo[4].label,
    instruction: "Right sleeve",
    direction: "round",
  },
  { instruction: "Left sleeve", direction: "round" },
  {
    group: pulloverPatternBySizePageInfo[5].label,
    ...commonLastPartInstruction,
  },
];
