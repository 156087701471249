import { css } from "@linaria/core";

export const appTheme = {
  styles: {
    global: {
      "html, body": {
        fontFamily: "var(--body)",
        letterSpacing: "0.02em",
      },
    },
  },
};

export const theme = {
  brown: css`
    --theme-0: var(--tk-brown-0);
    --theme-1: var(--tk-brown-1);
    --theme-2: var(--tk-brown-2);
    --theme-3: var(--tk-brown-3);
    --theme-4: var(--tk-brown-4);
    --theme-5: var(--tk-brown-5);
    --theme-6: var(--tk-brown-6);
    --theme-7: var(--tk-brown-7);
    --theme-8: var(--tk-brown-8);
    --theme-9: var(--tk-brown-9);
  `,
  cyan: css`
    --theme-0: var(--oc-cyan-0);
    --theme-1: var(--oc-cyan-1);
    --theme-2: var(--oc-cyan-2);
    --theme-3: var(--oc-cyan-3);
    --theme-4: var(--oc-cyan-4);
    --theme-5: var(--oc-cyan-5);
    --theme-6: var(--oc-cyan-6);
    --theme-7: var(--oc-cyan-7);
    --theme-8: var(--oc-cyan-8);
    --theme-9: var(--oc-cyan-9);
  `,
  gray: css`
    --theme-0: var(--oc-gray-0);
    --theme-1: var(--oc-gray-2); // background a bit darker
    --theme-2: var(--oc-gray-2);
    --theme-3: var(--oc-gray-3);
    --theme-4: var(--oc-gray-6);
    --theme-5: var(--oc-gray-6);
    --theme-6: var(--oc-gray-6);
    --theme-7: var(--oc-gray-7);
    --theme-8: var(--oc-gray-8);
    --theme-9: var(--oc-gray-9);
  `,
};
