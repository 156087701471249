import { getChartFromText } from "../../utils/chartModifier";
import { Motif } from "../../utils/types";
import { getRound } from "../../utils";

export const getMotif = (
  stsCount: 8 | 6 | 4 | 2 = 2,
  rowCount: 12 | 8 | 6 | 4 | 2 = 2,
  direction: "left" | "right" = "left",
  rowOffset: number = 0
): Motif => {
  let cable = null;
  switch (stsCount) {
    case 8:
      cable = direction === "right" ? "c8r" : "c8l";
      break;
    case 6:
      cable = direction === "right" ? "c6r" : "c6l";
      break;
    case 4:
      cable = direction === "right" ? "c4r" : "c4l";
      break;
    case 2:
      cable = direction === "right" ? "c2r" : "c2l";
      break;
    default:
      cable = direction === "right" ? "c2r" : "c2l";
      break;
  }

  const chartText = [cable, `k:${stsCount} * ${rowCount - rowOffset - 1}`];
  if (rowOffset > 0) {
    chartText.unshift(`k:${stsCount} * ${rowOffset}`);
  }

  return {
    title: stsCount === 2 ? "Twist" : "Cable",
    chart: getChartFromText(chartText),
    stsCount,
    stEquivCount: getRound(((stsCount * 9) / 10) * 1000, 125) / 1000,
  };
};
