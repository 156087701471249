import React from "react";

export const K2TOG = (
  <>
    <line x1="20" y1="4" x2="4" y2="20" />
    <line x1="12" y1="12" x2="20" y2="20" />
  </>
);

export const SSK = (
  <>
    <line x1="4" y1="4" x2="20" y2="20" />
    <line x1="12" y1="12" x2="4" y2="20" />
  </>
);

export const P2TOG = (
  <>
    <line x1="20" y1="4" x2="4" y2="20" />
    <line x1="12" y1="12" x2="20" y2="20" />
    <circle cx="12" cy="18" r="3" />
  </>
);

export const SSP = (
  <>
    <line x1="4" y1="4" x2="20" y2="20" />
    <line x1="12" y1="12" x2="4" y2="20" />
    <circle cx="12" cy="18" r="3" />
  </>
);

export const S2KP2 = (
  <>
    <line x1="12" y1="12" x2="4" y2="20" />
    <line x1="12" y1="12" x2="20" y2="20" />
    <line x1="12" x2="12" y1="4" y2="20" />
  </>
);

export const S2PP2 = (
  <>
    <line x1="12" y1="12" x2="4" y2="20" />
    <line x1="12" y1="12" x2="20" y2="20" />
    <line x1="12" x2="12" y1="4" y2="20" />
    <circle cx="12" cy="18" r="3" />
  </>
);
