import { Heading, VStack, Text, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { allProjects } from "../projects/list";
import { ProjectCard } from "@tanyoknits/shared";
import Footer from "./Footer";

export default function Main() {
  return (
    <VStack py={10} px={4}>
      <Heading as="h1" textAlign="center">
        Interactive knitting patterns
      </Heading>
      <Heading
        as="h2"
        textAlign="center"
        size="md"
        fontWeight={400}
        color="gray.700"
      >
        Follow stitches in both visual and written instructions
      </Heading>
      <Text fontSize="xl">by tanyoknits</Text>
      <Flex flexDirection={["column", "row"]} p={1} pt={5} align="top" gap={4}>
        {Object.keys(allProjects).map((project, i) => {
          const { name, features, sizes, isWIP, isPrivate } =
            allProjects[project];
          const photo = `${project}-01.jpg`;
          if (isPrivate) {
            return null;
          }
          return isWIP ? (
            <ProjectCard
              key={i}
              title={name}
              list={["🔜 Coming soon"]}
              photo={photo}
            />
          ) : (
            <Link key={i} to={`${project}`}>
              <ProjectCard
                title={name}
                list={features.concat(`📏 ${sizes.length} sizes`)}
                photo={photo}
              />
            </Link>
          );
        })}
      </Flex>
      <Footer mode="light" />
    </VStack>
  );
}
