import { getChartFromText } from "../../utils";
import { Motif } from "../../utils/types";

export const getMotif = (
  p: number = 3,
  padding: number = 2,
  diagonalRow: number = 6
): Motif => {
  const stsCount = diagonalRow + p - 1 + padding * 2;
  const baseRowsText = Array.from({ length: diagonalRow }).map((_, i) => {
    const startKCount = i + padding;
    return `k:${startKCount} p:${p} k:${stsCount - p - startKCount}`;
  });
  const base = getChartFromText(baseRowsText);
  const baseFlippedRowsText = Array.from({ length: diagonalRow }).map(
    (_, i) => {
      return `k:${stsCount - p - i - padding} p:${p} k:${i + padding}`;
    }
  );
  const baseFlipped = getChartFromText(baseFlippedRowsText);
  const chart = [...base, ...baseFlipped];

  return {
    title: "Zig Zag",
    stsCount,
    chart,
  };
};
