import {
  BaseChart,
  Stitch,
  getRawStsFromBaseRow,
  getRound,
  spliceRow,
} from "@tanyoknits/shared";
import { definition } from "@tanyoknits/shared";
import { getLineSRCount } from "./shortRow";

const { empty, koco, sr_rs, sr_ws, lli, rli, k } = definition;

/** Stitch & Row level */

export function getReplacedBaseRow(
  baseRow: (Stitch | null)[],
  startID: number,
  count: number,
  filledSts: Stitch | Stitch[] = empty
): { startID: number; replaced: Stitch[] } {
  const replaced = Array.isArray(filledSts)
    ? filledSts
    : Array(count).fill(filledSts);
  const rawRow = getRawStsFromBaseRow(baseRow);

  // If replaced starts with null, move to the previous sts, replace it with the raw sts
  let startNullID = startID < 0 ? rawRow.length + startID : startID;
  while (baseRow[startNullID] == null && startNullID >= 0) {
    replaced.unshift(definition[rawRow[startNullID]]);
    startNullID--;
  }

  // If multi-width stitch ends in the middle of replaced sts, move to the next sts, and replace it raw sts
  let endNullID = startID < 0 ? baseRow.length - 1 : startID + count;
  while (baseRow[endNullID] == null && endNullID < baseRow.length - 1) {
    replaced.push(definition[rawRow[endNullID]]);
    endNullID++;
  }
  return { startID: startNullID, replaced };
}

/** Shaping */

export function shapeShoulderDrop(
  baseChart: BaseChart,
  shoulder: number,
  shoulderDrop: number,
  side: number,
  armInset: number
): void {
  const backShoulderLine = getLineSRCount(shoulder, shoulderDrop);

  // shoulder drop - right
  for (let i = 0; i < shoulderDrop; i++) {
    // First two rows: remove cables or other stitches into k/p stitches
    if (i < 2) {
      const rawSts = getRawStsFromBaseRow(baseChart[i]);
      spliceRow(
        baseChart[i],
        0,
        rawSts.length,
        rawSts.map((s) => definition[s])
      );
    }
    const sr = backShoulderLine[Math.ceil(i / 2) - 1] ?? 0;
    const rightStartSts = side - shoulder;
    spliceRow(baseChart[i], rightStartSts, sr);
    if (i > 0 && i % 2 === 1) {
      spliceRow(baseChart[i], rightStartSts + sr - 1, 1, sr_rs);
    }
  }
  // shoulder drop - left
  for (let i = 0; i < shoulderDrop; i++) {
    const leftStartSts =
      armInset + (backShoulderLine[Math.floor(i / 2) - 1] ?? 0);
    spliceRow(baseChart[i], null, leftStartSts);
    if (i > 1 && i % 2 === 0) {
      spliceRow(baseChart[i], null, leftStartSts, [
        sr_ws,
        ...Array(leftStartSts - 1).fill(empty),
      ]);
    }
  }
}

export function shapeRoundNeck(
  baseChart: BaseChart,
  neck: number,
  side: number,
  neckDepth: number,
  neckCurve: number[]
): void {
  const sideSR = neckCurve[0];

  for (let i = 0; i < neckDepth; i++) {
    const srStsRight = sideSR - neckCurve[Math.floor(i / 2)];
    const startSts = side + srStsRight;
    const stStsLeft = sideSR - neckCurve[Math.max(0, Math.ceil(i / 2) - 1)];
    const frontNeckCutCount = neck - srStsRight - stStsLeft;
    // Neck curve filled with empty sts
    spliceRow(baseChart[i], startSts, frontNeckCutCount);

    // Right curve: show koco(+)
    if (i > 1 && i % 2 === 0) {
      const coSts =
        neckCurve[Math.floor((i - 2) / 2)] - neckCurve[Math.floor(i / 2)];
      spliceRow(baseChart[i], startSts - coSts, coSts, koco);
    }
    // Left curve: show koco(+)
    if (i > 1 && i % 2 === 1) {
      const coSts =
        neckCurve[Math.max(0, Math.ceil((i - 2) / 2) - 1)] -
        neckCurve[Math.max(0, Math.ceil(i / 2) - 1)];
      spliceRow(baseChart[i], startSts + frontNeckCutCount, coSts, koco);
    }
  }
}

export function getArmIncVals(armInset: number): {
  armCO: number;
  armInc: number;
} {
  const armCO = getRound(armInset / 3);
  const armInc = armInset - armCO * 2;
  return { armCO, armInc };
}

export function getIncEveryXandYRows(
  rowCount: number,
  stsCount: number,
  everyNRow: number
): {
  everyX: number;
  everyY: number;
  firstDecRow: number;
  secondDecRow: number;
  armCOAdj: number;
} {
  let a = everyNRow;
  let b = everyNRow - 2;
  function getDecCount(
    a: number,
    b: number
  ): {
    everyX: number;
    everyY: number;
    firstDecRow: number;
    secondDecRow: number;
    armCOAdj: number;
  } {
    const everyX = getRound((rowCount - b * stsCount) / (a - b));
    const everyY = stsCount - everyX;
    return { everyX, everyY, firstDecRow: a, secondDecRow: b, armCOAdj: 0 };
  }
  const { everyX, everyY } = getDecCount(a, b);
  if (everyX < 0) {
    // If the bigger number is negative, reduce row numbers
    return getDecCount(a - 2, b - 2);
  } else if (everyY < 0) {
    // If the smaller number is negative, ignore and reduce every X
    return {
      everyX: everyX + everyY,
      everyY: -1 * everyY,
      firstDecRow: a,
      secondDecRow: b,
      armCOAdj: everyY,
    };
  } else {
    return { everyX, everyY, firstDecRow: a, secondDecRow: b, armCOAdj: 0 };
  }
}

export function shapeArmInset(
  baseChart: BaseChart,
  shoulderToArmInset: number,
  armInset: number,
  armCO: number,
  armCOAdj: number,
  everyX: number,
  everyY: number,
  decRow: number
): void {
  // arm inset - fill with empty sts from the top till arm curve start
  for (let i = 0; i < shoulderToArmInset; i++) {
    spliceRow(baseChart[i], 0, armInset);
    spliceRow(baseChart[i], null, armInset);
  }

  let rowID = shoulderToArmInset;
  function increaseArm(everyX: number, x: number, emptyCount: number): void {
    for (let i = 0; i < everyX * x; i++) {
      const count = emptyCount - Math.floor(i / x) - 1;
      if (count < 0) {
        break;
      }
      spliceRow(baseChart[rowID], 0, count);
      spliceRow(baseChart[rowID], null, count);
      if (i % x === 0) {
        spliceRow(baseChart[rowID], count + 2, 1, rli);
        spliceRow(baseChart[rowID], null, count + 3, [
          lli,
          k,
          k,
          ...Array(count).fill(empty),
        ]);
      }
      rowID++;
    }
  }

  increaseArm(everyX, decRow, armInset);
  increaseArm(everyY, decRow - 2, armInset - everyX);

  // Arm inset CO - last two rows if CO sts count is 2 or more;
  if (armCO * 2 + armCOAdj > 1) {
    spliceRow(baseChart[rowID], 0, armCO * 2 + armCOAdj);
    spliceRow(baseChart[rowID], null, armCO * 2 + armCOAdj, [
      ...Array(armCO + armCOAdj).fill(koco),
      ...Array(armCO).fill(empty),
    ]);
    rowID++;
    spliceRow(baseChart[rowID], 0, armCO);
    spliceRow(baseChart[rowID], armCO, armCO + armCOAdj, koco);
    spliceRow(baseChart[rowID], null, armCO);
    rowID++;
  } else {
    // only one sts increase, do usual increase
    increaseArm(1, 2, 1);
  }
  // Last CO; sliced out to be in the entire body
  spliceRow(baseChart[rowID], 0, armCO, koco);
  spliceRow(baseChart[rowID], null, armCO, koco);
}

// Get sts count from row
export function getStsFromRow(
  rowCount: number,
  stsGauge: number,
  rowGauge: number
): number {
  const rowLength = rowCount / rowGauge;
  return getRound(rowLength * stsGauge);
}

export function getPickUpStsFromCurve(curvePoints: number[]): {
  straightRow: number;
  curveRow: number;
  curveSts: number;
} {
  const firstPoint = curvePoints[0];
  let straightRow = 0;
  let curveSts = 0;
  let curveRow = 0;
  curvePoints.forEach((point, i) => {
    if (point === firstPoint) {
      straightRow += 2;
    } else {
      const prevPoint = curvePoints[i - 1] ?? firstPoint;
      curveSts += prevPoint - point;
      curveRow += 2;
    }
  });
  return { straightRow, curveRow, curveSts };
}
