import { Box, ListItem, UnorderedList } from "@chakra-ui/react";

interface Props {
  list: string[];
  hasIndent?: boolean;
}
export default function PlainTextList({ list, hasIndent = false }: Props) {
  return (
    // Box is needed for bullet point indent
    <Box ml={hasIndent ? 6 : 0}>
      <UnorderedList maxWidth="4xl">
        {list.map((item, i) => (
          <ListItem key={i}>{item}</ListItem>
        ))}
      </UnorderedList>
    </Box>
  );
}
