import { needles } from "../../utils/needles";
import { ProjectProfile } from "../../utils/types";

const profile: ProjectProfile = {
  yarn: {
    maker: "Knit Picks",
    line: "Swish bulky",
    color: "Pinecone Heather",
    url: "https://www.knitpicks.com/pinecone-heather/p/29622",
    shape: "hank",
    yards: 137,
    grams: 100,
  },
  needles: [
    { type: "circular", size: needles[14], usage: "main body & sleeves" },
    { type: "circular", size: needles[12], usage: "ribs" },
    { type: "circular", size: needles[10], usage: "tubular CO" },
  ],
  gauge: {
    sts: "Stockinette stitch",
    sts_count: 14,
    row_count: 20,
    needle: needles[14],
  },
  notions: ["Tapestry needle", "Stitch markers", "scrap yarn or stitch holder"],
  difficulty: "Intermediate",
  photoCount: 3,
  // photos: [
  //   "pine-pullover-01.jpg",
  //   "pine-pullover-02.jpg",
  //   "pine-pullover-03.jpg",
  // ],
  // theme: "brown",
};

export default profile;
