import { Flex, Heading, Text, VStack } from "@chakra-ui/react";
import PlainTextList from "./PlainTextList";
import { PlainTextSection } from "../utils/types";
import PlainTextRows from "./PlainTextRows";

export default function SectionInstruction({
  title,
  rowType,
  startRowID,
  rows,
  intro,
  beforeList,
  afterList,
  notes,
  showSide,
}: PlainTextSection) {
  return (
    <Flex direction="column" maxW="4xl" gap={2}>
      {title && (
        <Heading as="h3" size="md" mb={2}>
          {title}
        </Heading>
      )}
      {intro && <Text>{intro}</Text>}
      {beforeList != null && beforeList.length > 0 && (
        <PlainTextList list={beforeList} />
      )}
      {rows.length > 0 && (
        <PlainTextRows
          rowType={rowType}
          rows={rows}
          startRowID={startRowID}
          showSide={showSide}
        />
      )}
      {afterList != null && afterList.length > 0 && (
        <PlainTextList list={afterList} />
      )}
      {notes && <Text>{notes}</Text>}
    </Flex>
  );
}
