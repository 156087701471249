import React from "react";

export const K = null;

export const P = (
  <>
    <circle cx="12" cy="12" r="3" />
  </>
);

export const MB = (
  <>
    <circle cx="12" cy="12" r="8" />
  </>
);

export const KTBL = (
  <>
    <path d="M5 20C18.5 16 20.5 4 12 4C3.5 4 5.5 16 19 20" />
  </>
);

export const KOCO = (
  <>
    <line x1="12" x2="12" y1="6" y2="18" />
    <line x1="6" x2="18" y1="12" y2="12" />
  </>
);
