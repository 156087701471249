import { Box, Flex, Heading } from "@chakra-ui/react";

interface Props {
  title: string;
  children: React.ReactNode;
}
export default function IntroSection({ title, children }: Props) {
  return (
    <Flex direction="column" gap={2}>
      <Heading as="h1" size="md" style={{ whiteSpace: "nowrap" }}>
        {title}
      </Heading>
      <Box>{children}</Box>
    </Flex>
  );
}
