import { AbbKey } from "../utils/types";

export const abbreviations: Record<AbbKey, string> = {
  bef: "before",
  BO: "bind off",
  beg: "beginning",
  BOR: "beginning of the round or row",
  CO: "cast on",
  dec: "decrease",
  foll: "follows, following",
  inc: "increase",
  patt: "pattern",
  "pm*":
    "place marker, (pmA: place marker A, pmB: place Marker B, etc in case of multiple markers are used)",
  "sm*":
    "slip marker, (smA: slip marker A, smB: slip Marker B, etc in case of multiple markers are used)",
  "rm*":
    "remove marker, (rmR: remove marker R, rmW: remove Marker W, etc in case of multiple markers are used)",
  rep: "repeat",
  rnd: "round",
  SR: "short row",
  "st(s)": "stitch(es)",
  "St st": "Stockinette stitch",
  tog: "together",
  WS: "wrong side",
  RS: "right side",
  rm: "remove",
  cont: "continue",
  incl: "includes, including",
  appx: "approximately",
  pu: "Pick up",
  koco: "Knit On Case On",
  "w & t": "Wrap and Turn",
};
