import { Motif, StsID, getChartFromText } from "../../utils";

const TITLE = "Leaf";

export const getMotif = (
  yoCount: 3 | 4 | 5 = 5,
  everyXRow: 2 | 4 = 2,
  hasBobble: boolean = true,
  gutter: StsID = "p"
): Motif => {
  const halfStsCount = yoCount + 3;
  const rowCount = yoCount * everyXRow;
  const evenRow = `k:${halfStsCount} ${gutter} k:${halfStsCount}`;
  const leaf = Array.from({ length: rowCount }).map((_, i) => {
    if (i % everyXRow === 0) {
      const startSts = Math.floor(i / everyXRow);
      const kCount = halfStsCount - startSts - 4;
      const plainSts = kCount === 3 && hasBobble ? "k mb k" : `k:${kCount}`;
      return `k:${startSts} yo k:2 ssk ${plainSts} ${
        kCount === 2 && hasBobble ? "mb" : gutter
      } ${plainSts} k2tog k:2 yo k:${startSts}`;
    } else {
      return evenRow;
    }
  });
  leaf.push(`k:${yoCount} yo k ssk ${gutter} k2tog k yo k:${yoCount}`, evenRow);

  return {
    chart: getChartFromText(leaf),
    stsCount: halfStsCount * 2 + 1,
    title: TITLE,
  };
};
