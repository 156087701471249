import definition from "../stitches/definition";
import { Chart, Stitch, StsID, allStsIDs } from "./types";

const { empty, k, p } = definition;

export function getStsRepeats(stitches: Stitch[]): StsID[][] {
  return stitches.reduce((agg: StsID[][], sts, i) => {
    if (sts.stsID === "empty") {
      return agg;
    } else if (agg[agg.length - 1]?.[0] === sts.stsID) {
      agg[agg.length - 1].push(sts.stsID);
    } else {
      agg.push([sts.stsID]);
    }
    return agg;
  }, []);
}

export function getWSStsList(stitches: Stitch[], isWS?: boolean): Stitch[] {
  const stsWithDir = isWS
    ? stitches
        .slice()
        .reverse()
        .map((sts: Stitch) => {
          const { stsID } = sts;
          const { ws } = definition[stsID];
          return ws != null ? definition[ws] : sts;
        })
    : stitches;

  return stsWithDir;
}

export function getLabelRepeats(stsIDs: StsID[]): string {
  const id = stsIDs[0];
  const count = stsIDs.length;
  const { label } = definition[id];
  return count === 1
    ? label
    : `${label}${id !== "k" && id !== "p" ? " x " : ""}${count}`;
}

export function getHumanReadableSts(
  stitches: Stitch[],
  isWS: boolean = false
): string[] {
  // Nest repeats at the highest level [k, k, p, p] => [[k, k], [p, p]]
  const stsRepeats = getStsRepeats(getWSStsList(stitches, isWS));

  let repeatCheckIdx = 0;

  const nested = stsRepeats.reduce((labels: string[], stsIDs, idx) => {
    // Skip sts that's already applied to the previous repeats
    if (idx < repeatCheckIdx) {
      return labels;
    }

    // Recursive: if repeats aren't found, increase the number of repeated sts
    // start with two (e.g., [k, k][p, p])
    function checkRepeats(count: number): StsID[][][] {
      const repeats = [stsRepeats.slice(idx, idx + count)];
      for (let i = idx + count; i <= stsRepeats.length - count; i += count) {
        // check if the following repeats are the same
        if (
          JSON.stringify(repeats[0]) ===
          JSON.stringify(stsRepeats.slice(i, i + count))
        ) {
          repeats.push(repeats[0]);
        } else {
          break;
        }
      }
      if (repeats.length > 1 || count > stsRepeats.length / 2) {
        return repeats;
      } else {
        return checkRepeats(count + 1);
      }
    }

    const repeats = checkRepeats(2);
    if (repeats.length > 1) {
      const labelRepeats = repeats[0].map((stsIDs) => getLabelRepeats(stsIDs));
      labels.push(`(${labelRepeats.join(", ")}) x ${repeats.length}`);
      // Skip all repeated ones
      repeatCheckIdx += repeats.flat().length;
    } else {
      labels.push(getLabelRepeats(stsIDs));
      // Go to the next repeat
      repeatCheckIdx++;
    }
    return labels;
  }, []);

  return nested;
}

export function getRawStsFromBaseRow(baseRow: (Stitch | null)[]): StsID[] {
  return baseRow.flatMap((sts) => {
    if (sts == null) return [];
    const { unitWidth, rawSts, stsID } = sts;
    return rawSts ?? Array.from({ length: unitWidth ?? 1 }).map((_) => stsID);
  });
}

export function getRibBaseRow(
  stsCount: number,
  emptyStsIdx: number[]
): Stitch[] {
  let validRibCount = 0;
  const ribRow = Array.from({ length: stsCount }).map((_, i) => {
    if (!emptyStsIdx.includes(i)) {
      validRibCount++;
      return validRibCount % 2 === 1 ? k : p;
    } else {
      return empty;
    }
  });
  return ribRow;
}

export function getUniqueStsFromChart(chart: Chart): Stitch[] {
  const allSts: Stitch[] = chart
    .flatMap((row) => {
      return row.flatMap((stsGroup) => stsGroup.sts);
    })
    .filter((sts, idx, list) => list.indexOf(sts) === idx)
    .sort((a, b) => allStsIDs.indexOf(a.stsID) - allStsIDs.indexOf(b.stsID));
  return allSts;
}
