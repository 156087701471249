/** Single Stitch */

// Single stitch IDs
const BASIC_IDS = ["k", "p", "mb", "ktbl"];
const CABLE_IDS = [
  "c21lp",
  "c21rp",
  "c22lp",
  "c22rp",
  "c2l",
  "c2r",
  "c4l",
  "c4r",
  "c6l",
  "c6r",
  "c8l",
  "c8r",
  "c12l",
  "c12r",
];
const INC_IDS = ["lli", "rli", "llip", "rlip", "yo", "m1r", "m1l"];
const DEC_IDS = ["k2tog", "ssk", "p2tog", "ssp", "s2kp2", "s2pp2"];
const NEW_STS_IDS = ["koco"];
const NON_STS_IDS = [
  "empty",
  "sr_rs",
  "sr_ws",
  "cy_rs",
  "cy_ws",
  "nc",
  "ny_rs",
  "ny_ws",
];

export const allStsIDs = [
  ...BASIC_IDS,
  ...CABLE_IDS,
  ...INC_IDS,
  ...DEC_IDS,
  ...NEW_STS_IDS,
  ...NON_STS_IDS,
] as const;

export type StsID = typeof allStsIDs[number];

// Stitch definition
export interface Stitch {
  stsID: StsID;
  label: string;
  instruction: string;
  glyph?: React.ReactNode;
  unitWidth?: number;
  ws?: StsID;
  topDownFlip?: StsID;
  rightLeftFlip?: StsID;
  isNonSts?: boolean;
  rawSts?: StsID[];
  videos?: { url: string; notes?: string }[];
}

/** Stitch pattern aka Motif */

export type GlyphSize = "S" | "M" | "L";

// Colors for stitch pattern
export const MOTIF_COLORS = [
  "pink",
  "blue",
  "orange",
  "green",
  "grape",
  "red",
  "violet",
  "indigo",
  "lime",
  "cyan",
  "teal",
] as const;
type colorTuple = typeof MOTIF_COLORS;
export type MotifColor = colorTuple[number];

export interface Motif {
  chart: Chart;
  stsCount: number;
  // Stockinette equivalent stitch count
  stEquivCount?: number;
  id?: MotifColor;
  title?: string;
  glyphSize?: GlyphSize;
}

// Used in Motif in stitch group or in base pattern
export interface MotifInfo {
  id: MotifColor;
  stsCount: number;
  rowCount: number; // this is only used for isFirstRow, the actual value isn't really used
}

/** Stitch Group */

export interface MotifInStsGroup extends MotifInfo {
  isFirstRow: boolean;
  stEquivCount?: number;
}

export type StsGroup = {
  sts: Stitch[];
  motif?: MotifInStsGroup;
};

/** Chart row */

export type ChartRow = StsGroup[];

/** Chart */

export type Chart = ChartRow[];
export type Direction = "round" | "flat";

/** Pattern */

export type BaseChart = (Stitch | null)[][];
export interface MotifInBasePattern extends MotifInfo {
  startStsNum: number;
}

export interface StsMarker {
  pos: number;
  part?: string;
}
export interface BasePattern {
  baseChart: BaseChart;
  motifList?: MotifInBasePattern[];
  motifOffset?: number;
  stEquivCount?: number;
  stsMarkers?: StsMarker[];
}

/** Pattern Instruction  */

export type RowType =
  | "CO"
  | "Rnd"
  | "Row"
  | "SR"
  | "Next rnd"
  | "Next row"
  | "Pick up"
  | string;

export interface PlainTextInstruction {
  rowType?: RowType;
  startRowID?: number;
  rows: { text: string; notes?: string }[];
  showSide?: boolean;
  highlighted?: number | null;
}

export interface MultiLevelText {
  main: string;
  sub?: string[];
}
export interface PlainTextSection extends PlainTextInstruction {
  title?: string;
  intro?: string;
  beforeList?: string[];
  afterList?: string[];
  notes?: string;
}

/** Other types */

// abbreviations
export type AbbKey =
  | "bef"
  | "BO"
  | "beg"
  | "BOR"
  | "CO"
  | "dec"
  | "foll"
  | "inc"
  | "patt"
  | "pm*"
  | "rep"
  | "rnd"
  | "RS"
  | "sm*"
  | "rm*"
  | "SR"
  | "st(s)"
  | "St st"
  | "tog"
  | "WS"
  | "rm"
  | "cont"
  | "incl"
  | "appx"
  | "pu"
  | "koco"
  | "w & t";

// techniques
export type Technique = StsID | string;
export type TechniqueList = Partial<
  Record<
    Technique,
    { usage?: string; videos: { url: string; notes?: string }[] }
  >
>;
