import { styles } from "./PulloverShortRowDiagram";
import { cx } from "@linaria/core";
import {
  WIDTH,
  HEIGHT,
  MARGIN,
  BASE_RADIUS,
  OFFSET,
} from "./PulloverShortRowDiagram";

export default function ShortRowLabels() {
  return (
    <>
      <circle cx={0} cy={0} r={BASE_RADIUS} className={cx(styles.base)} />
      <text
        x={0}
        y={-BASE_RADIUS + OFFSET * 2 + 8}
        className={cx(styles.label)}
      >
        BOR
      </text>
      <text x={0} y={-(HEIGHT + MARGIN) / 2} className={cx(styles.label)}>
        Back
      </text>
      <text x={0} y={(HEIGHT + MARGIN) / 2} className={cx(styles.label)}>
        Front
      </text>
      <text
        x={-WIDTH / 2 + MARGIN / 2}
        y={0}
        className={cx(styles.label)}
        transform={`rotate(270, ${-WIDTH / 2}, ${MARGIN / 2})`}
      >
        Right sleeve
      </text>
      <text
        x={WIDTH / 2 - MARGIN / 2}
        y={0}
        className={cx(styles.label)}
        transform={`rotate(90, ${WIDTH / 2}, ${MARGIN / 2})`}
      >
        Left sleeve
      </text>
    </>
  );
}
