import { IntroSizing } from "../../utils/types";
import {
  Size,
  baseBodySizing,
  vestCoreParts,
  VestMeasurement,
  FOMeasurement,
  CustomizedFO,
} from "../../utils/sizeBase";
import profile from "./profile";
import { getTotalSts } from "../../patternMakers/setInSleeve/vest";
import { getFOMeasurementBySize, getIntroSizingInfo } from "../../utils/misc";

// Size list
export const sizes: Size[] = [
  "2XS",
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "2XL",
  "3XL",
  "4XL",
];

const customizedSize: CustomizedFO = {
  bust_circumference: [30, 32.5, 35, 39, 43, 47, 52, 56, 60],
  total_length: [22, 23, 24, 25, 26, 27, null],
  neck_depth: [3.75, 4, 4.25, 4.5, 4.75, null],
  neck_band_height: [1.5, null, null, null, null, 2, null],
  arm_band_height: [1.5, null, null, null, null, 2, null],
  hem: [2.5, null],
};

export const measurements: FOMeasurement[] = getFOMeasurementBySize(
  sizes,
  vestCoreParts,
  customizedSize
);

const totalStsBySize: number[] = measurements.map((m, i) => {
  const schema = getTotalSts(
    m as VestMeasurement,
    profile.gauge.sts_count,
    profile.gauge.row_count
  );

  return schema;
});

export const introSizing: IntroSizing = getIntroSizingInfo(
  sizes,
  baseBodySizing,
  measurements,
  totalStsBySize,
  ["bust"],
  ["bust_circumference", "total_length"],
  { size: "S", grams: 260 }
);
