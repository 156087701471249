export const LLI = (
  <>
    <line x1="18" y1="4" x2="18" y2="21" />
    <line x1="5.67572" y1="5.26285" x2="17.6757" y2="16.2628" />
  </>
);

export const RLI = (
  <>
    <line
      y1="-1"
      x2="17"
      y2="-1"
      transform="matrix(4.37114e-08 1 1 -4.37114e-08 7 4)"
    />
    <line
      y1="-1"
      x2="16.2788"
      y2="-1"
      transform="matrix(-0.737154 0.675725 0.675725 0.737154 19 6)"
    />
  </>
);

export const M1R = (
  <text x="6" y="20" fontSize="24">
    R
  </text>
);

export const M1L = (
  <text x="6" y="20" fontSize="24">
    L
  </text>
);

export const YO = <circle cx="12" cy="12" r="8" fill="none" />;
