import {
  definition,
  getRound,
  spliceRow,
  getSlicedChartFromBasePattern,
  BasePattern,
  GlyphSize,
  getMergedBasePattern,
  getSlicedBasePattern,
  getStsCountFromBaseRow,
  getEven,
  getDec1StsIDList,
  getRawStsFromBaseRow,
  getRibBaseRow,
  getChartFromBasePattern,
  getChangeStsList,
  Chart,
  getBasePatternFromChart,
} from "@tanyoknits/shared";
import { setInSleeveMeasurement } from "../../utils/sizeBase";
import { SetInSleeveGaugeProps, SetInSleeveVestGaugeProps } from "./types";
import {
  getArmIncVals,
  getIncEveryXandYRows,
  getPickUpStsFromCurve,
  getStsFromRow,
  shapeArmInset,
  shapeRoundNeck,
  shapeShoulderDrop,
} from "../common";
import { getCurveSRCount } from "../shortRow";
import { COProps, PatternPart, PatternPartGroup } from "../../utils/types";
import { Needle, getNeedleText } from "../../utils/needles";
import {
  getBONote,
  getEveryXChangeNote,
  getLastCOIncNote,
  getPartNotes,
} from "../text";
import {
  commonLastPartInstruction,
  commonPartsInstruction,
  pulloverPartsInstruction,
  vestPartsInstruction,
} from ".";
import { RIB_DEC_RATIO, RIB_MULTIPLIER } from "../constants";

const { koco } = definition;

export function getCoreGaugeItems(
  ms: setInSleeveMeasurement,
  stsGauge: number,
  rowGauge: number,
  hasOddSts: boolean = false,
  adjHalfBody: number | undefined
): SetInSleeveGaugeProps {
  // width
  const neck = getRound(ms.neck_width * stsGauge);
  const shoulder = getRound(ms.shoulder_width * stsGauge);

  let halfBody =
    adjHalfBody ?? getRound((ms.bust_circumference * stsGauge) / 2);
  if (hasOddSts && halfBody % 2 === 0) {
    halfBody -= 1;
  }
  // if center pattern is an odd number, so neck width should be odd sts too.
  const adjNeck = getRound(neck, 2) - (hasOddSts ? 1 : 0);
  const adjArmInset = (halfBody - adjNeck - shoulder * 2) / 2;
  const side = Math.floor((halfBody - adjNeck) / 2);

  // depth
  const neckBack = getRound(ms.neck_back_depth * rowGauge, 2);
  const neckFront = getRound(ms.neck_depth * rowGauge, 2);
  const shoulderDrop = getRound(ms.shoulder_drop * rowGauge, 2);
  const shoulderToArm = getRound(ms.armhole_depth * rowGauge, 2);
  const armCurve = getRound(ms.underarm_curve_depth * rowGauge, 2);
  const shoulderToArmInset = shoulderToArm - armCurve;

  // band
  const neckBand = getRound(ms.neck_band_height * rowGauge);
  const hem = getRound(ms.hem * rowGauge);

  const totalRowCount = getRound(ms.total_length * rowGauge);

  const adjustedSize: SetInSleeveGaugeProps = {
    // Since motifs have their own St equiv stitch count, adjust half body sts
    halfBody,
    neck: adjNeck,
    armInset: adjArmInset,
    shoulder,
    side,
    neckBack,
    neckFront,
    shoulderDrop,
    shoulderToArm,
    armCurve,
    shoulderToArmInset,
    neckBand,
    hem,
    totalRowCount,
  };

  return adjustedSize;
}

export function getPattern(
  patternType: "vest" | "pullover",
  backBasePattern: BasePattern,
  frontBasePattern: BasePattern,
  stsGauge: number,
  rowGauge: number,
  gauges: SetInSleeveGaugeProps,
  coNeedle: Needle,
  ribNeedle: Needle
): PatternPartGroup[] {
  const { baseChart: backBaseChart } = backBasePattern;
  const { baseChart: frontBaseChart } = frontBasePattern;

  const {
    halfBody,
    neck,
    armInset,
    shoulder,
    side,
    neckBack,
    neckFront,
    shoulderDrop,
    shoulderToArm,
    armCurve,
    shoulderToArmInset,
    hem,
    neckBand,
    totalRowCount,
  } = gauges;

  /** shoulder drop */

  shapeShoulderDrop(backBaseChart, shoulder, shoulderDrop, side, armInset);
  shapeShoulderDrop(frontBaseChart, shoulder, shoulderDrop, side, armInset);

  /** back neck curve */

  const backNeckCurve = getCurveSRCount(Math.round(neck / 5), neckBack);
  shapeRoundNeck(backBaseChart, neck, side, neckBack, backNeckCurve);

  // Connect
  const backCurveInc =
    backNeckCurve[0] - backNeckCurve[backNeckCurve.length - 1];
  const centerCO = neck - backCurveInc * 2;
  spliceRow(backBaseChart[neckBack], side + backCurveInc, centerCO, koco);

  // Pattern Parts
  const coInfo: COProps = {
    needle: getNeedleText(coNeedle),
    direction: "flat",
    coCount: shoulder,
  };

  const part1startSts = side + backCurveInc + centerCO;
  const part1: PatternPart = {
    chart: getSlicedChartFromBasePattern(
      backBasePattern,
      [0, shoulderDrop],
      [part1startSts, part1startSts + shoulder + backCurveInc]
    ),
    co: coInfo,
    afterList: [getPartNotes("cut_yarn")],
    ...commonPartsInstruction[0],
    size: "L" as GlyphSize,
  };
  const part2: PatternPart = {
    chart: getSlicedChartFromBasePattern(
      backBasePattern,
      [0, shoulderDrop],
      [armInset, armInset + shoulder + backCurveInc]
    ),
    co: coInfo,
    afterList: [getPartNotes("no_cut_yarn")],
    ...commonPartsInstruction[1],
    size: "L" as GlyphSize,
  };

  /** front neck curve */

  // Curve short row for round neck
  const curveBaseSts = Math.floor(neck / (patternType === "vest" ? 5.5 : 2.5));
  const halfNeck = Math.floor(neck / 2);
  // Straight rows
  const curveOffsetRow = getRound(
    shoulderDrop - (patternType === "vest" ? 0 : 2),
    2
  );
  const frontNeckCurveBase = getCurveSRCount(
    curveBaseSts,
    neckFront - curveOffsetRow
  );
  const frontNeckCurveExtended = Array(curveOffsetRow / 2)
    .fill(curveBaseSts)
    .concat(...frontNeckCurveBase)
    .map((s) => s + (halfNeck - curveBaseSts));

  const frontBody = frontBaseChart[0].length;
  const adjSide = side + getRound((frontBody - halfBody) / 2);

  shapeRoundNeck(
    frontBaseChart,
    neck,
    adjSide,
    neckFront,
    frontNeckCurveExtended
  );
  const lastSRCount = frontNeckCurveExtended[frontNeckCurveExtended.length - 1];
  const centerConnect = lastSRCount * 2 + (neck - halfNeck * 2);
  spliceRow(
    frontBaseChart[neckFront],
    armInset + shoulder + halfNeck - lastSRCount + (adjSide - side),
    centerConnect,
    koco
  );

  const topPartSts =
    shoulder + halfNeck - Math.floor(centerConnect / 2) + (adjSide - side);
  const part4StartSts =
    Math.floor(frontBody / 2) + Math.ceil(centerConnect / 2);
  const part4: PatternPart = {
    chart: getSlicedChartFromBasePattern(
      frontBasePattern,
      [0, neckFront],
      [part4StartSts, part4StartSts + topPartSts]
    ),
    beforeList: [
      `Pick up ${
        shoulder + (adjSide - side)
      } sts from part 2, work down for the left neckline. ${
        adjSide - side > 0
          ? `You need to pick up 
        ${adjSide - side} sts from the side of the back shoulder side`
          : ""
      }`,
    ],
    afterList: [getPartNotes("cut_yarn")],
    ...commonPartsInstruction[3],
    size: "M" as GlyphSize,
    isOnlyRowClickable: true,
  };

  const part5: PatternPart = {
    chart: getSlicedChartFromBasePattern(
      frontBasePattern,
      [0, neckFront],
      [armInset, armInset + topPartSts]
    ),
    beforeList: [
      `Pick up ${
        shoulder + (adjSide - side)
      } sts from part 2, work down for the right neckline. ${
        adjSide - side > 0
          ? `You need to pick up 
        ${adjSide - side} sts from the side of the back shoulder side`
          : ""
      }`,
    ],
    afterList: [getPartNotes("no_cut_yarn")],
    ...commonPartsInstruction[4],
    size: "M" as GlyphSize,
    isOnlyRowClickable: true,
  };

  /** Arm inset */

  const { armCO, armInc } = getArmIncVals(armInset);
  const { everyX, everyY, firstDecRow, armCOAdj } = getIncEveryXandYRows(
    armCurve - 2,
    armInc,
    4
  );

  shapeArmInset(
    backBaseChart,
    shoulderToArmInset,
    armInset,
    armCO,
    armCOAdj,
    everyX,
    everyY,
    firstDecRow
  );
  shapeArmInset(
    frontBaseChart,
    shoulderToArmInset,
    armInset,
    armCO,
    armCOAdj,
    everyX,
    everyY,
    firstDecRow
  );

  /** Back & Front top together */

  const part3: PatternPart = {
    chart: getSlicedChartFromBasePattern(
      backBasePattern,
      [shoulderDrop, shoulderToArm],
      [armCO, halfBody - armCO]
    ),
    startRowID: shoulderDrop,
    beforeList: [
      `🪡 Start with ${halfBody - armInset * 2} sts`,
      getEveryXChangeNote(
        "increase",
        shoulderToArmInset + 1,
        [everyX, firstDecRow],
        [everyY, firstDecRow - 2],
        true
      ),
      getLastCOIncNote(armCO + armCOAdj, "both"),
    ],
    ...commonPartsInstruction[2],
    size: "M" as GlyphSize,
    isOnlyRowClickable: true,
  };

  const part6: PatternPart = {
    chart: getSlicedChartFromBasePattern(
      frontBasePattern,
      [neckFront, shoulderToArm],
      [armCO, frontBody - armCO]
    ),
    startRowID: neckFront,
    beforeList: [
      `🪡 Start with ${frontBody - armInset * 2} sts`,
      getEveryXChangeNote(
        "increase",
        shoulderToArmInset + 1,
        [everyX, firstDecRow],
        [everyY, firstDecRow - 2],
        true
      ),
      getLastCOIncNote(armCO + armCOAdj, "both"),
    ],
    ...commonPartsInstruction[5],
    size: "M" as GlyphSize,
    isOnlyRowClickable: true,
  };

  /** rest body together */
  const mergedBasePattern = getMergedBasePattern([
    getSlicedBasePattern(
      frontBasePattern,
      [shoulderToArm, totalRowCount - hem],
      [armCO, frontBody]
    ),
    getSlicedBasePattern(
      backBasePattern,
      [shoulderToArm, totalRowCount - hem],
      [0, halfBody]
    ),
    getSlicedBasePattern(
      frontBasePattern,
      [shoulderToArm, totalRowCount - hem],
      [0, armCO]
    ),
  ]);
  const { baseChart: mergedBaseChart } = mergedBasePattern;

  // Decrease before hem
  const decRowID = totalRowCount - hem - shoulderToArm - 1;
  const decRow = mergedBaseChart[decRowID];
  const restBodyStsCount = getStsCountFromBaseRow(decRow);
  const frontDecEndSts =
    getEven(halfBody * RIB_DEC_RATIO) - (halfBody % 2 === 0 ? 0 : -1);
  const backDecEndSts =
    getEven((restBodyStsCount - frontBody) * RIB_DEC_RATIO) -
    ((restBodyStsCount - frontBody) % 2 === 0 ? 0 : -1);
  const frontDecList = getDec1StsIDList(frontBody, frontDecEndSts);
  const backDecList = getDec1StsIDList(
    restBodyStsCount - frontBody,
    backDecEndSts
  );
  const decList = [...frontDecList, ...backDecList.map((d) => d + frontBody)];

  const decRowSts = getRawStsFromBaseRow(decRow)
    .map((stsID, i) => {
      if (decList.includes(i + 1)) {
        return "k2tog";
      } else if (decList.includes(i)) {
        return "empty";
      }
      return stsID;
    })
    .map((stsID) => definition[stsID]);
  spliceRow(decRow, 0, restBodyStsCount, decRowSts);

  // Add 1x1 rib
  const ribBaseRow = getRibBaseRow(restBodyStsCount, decList);
  const ribRowCount = getRound(hem * RIB_MULTIPLIER);
  const ribChart = Array.from({
    length: ribRowCount,
  }).map((_) => [{ sts: ribBaseRow }]);

  const part7: PatternPart = {
    chart: [...getChartFromBasePattern(mergedBasePattern), ...ribChart],
    startRowID: shoulderToArm,
    beforeList: [
      `➕ CO ${armCO * 2} sts as front and back connect on each underarm`,
      `➖ At row ${totalRowCount - hem}, dec ${
        halfBody === frontBody
          ? `evenly total ${
              restBodyStsCount - (frontDecEndSts + backDecEndSts)
            } sts`
          : `evenly ${frontBody - frontDecEndSts} sts for front and ${
              restBodyStsCount - frontBody - backDecEndSts
            } sts for back`
      }`,
      `🪡 Change to ${getNeedleText(
        ribNeedle
      )}, rep k1p1 rib for ${ribRowCount} rows`,
      getBONote(frontDecEndSts + backDecEndSts, "Sewn On"),
    ],
    ...commonPartsInstruction[6],
    size: "S" as GlyphSize,
    isOnlyRowClickable: true,
    direction: "round",
  };

  // sleeve or arm band
  const straightRow = shoulderToArm - shoulderDrop - armCurve + 2;
  const straightSts = getStsFromRow(straightRow, stsGauge, rowGauge);
  const pickUpPoints = getChangeStsList(straightRow, straightSts);
  const curveSts = getStsFromRow(armCurve, stsGauge, rowGauge) + armInc;
  const underArmIncSts = armCO * 2 + armCOAdj + 2;
  const oneSide = straightSts + curveSts + underArmIncSts;
  const sleevePickup = [
    { main: `🪡 Change to ${getNeedleText(ribNeedle)}` },
    {
      main: `🎬 Pu total ${oneSide * 2} sts around the armhole`,
      sub: [
        `On one side, along the straight part pu ${straightSts} sts, skipping one row after ${pickUpPoints.join(
          ", "
        )} row`,
        `On one side, Around the curve, pu ${curveSts} sts`,
        `On underarm koco part, pu ${underArmIncSts * 2} sts`,
      ],
    },
  ];

  if (patternType === "vest") {
    const { armBand } = gauges as SetInSleeveVestGaugeProps;
    const armBandInstruction = [
      {
        main: `🪡 Work kp1p rib for ${getRound(armBand * RIB_MULTIPLIER)} rows`,
        sub: [`Make underarm center sts to be k`],
      },
      {
        main: `➖ Dec 2sts at underarm center at row 3 and 5 with sl2-k1-p2sso`,
      },
      {
        main: getBONote(oneSide * 2 - 4, `Sewn On`),
      },
    ];
    sleevePickup.push(...armBandInstruction);
  }

  const part8: PatternPart = {
    chart: [],
    details: sleevePickup,
    instruction:
      patternType === "vest"
        ? vestPartsInstruction[7].instruction
        : pulloverPartsInstruction[7].instruction,
  };
  const part9: PatternPart = {
    chart: [],
    beforeList: ["Work same as the prev part"],
    instruction:
      patternType === "vest"
        ? vestPartsInstruction[8].instruction
        : pulloverPartsInstruction[8].instruction,
  };

  // neck band
  const neckBackPickUp = getEven(
    neck + getStsFromRow(neckBack, stsGauge, rowGauge) * 2
  );
  const frontCurve = getPickUpStsFromCurve(frontNeckCurveExtended);
  const frontNeckPickUp = getEven(
    getStsFromRow(
      frontCurve.straightRow + frontCurve.curveRow,
      stsGauge,
      rowGauge
    ) *
      2 +
      frontCurve.curveSts +
      centerConnect
  );
  const neckBandInstruction = [
    { main: `🪡 Use ${getNeedleText(ribNeedle)}` },
    {
      main: `🎬 Pu total ${
        neckBackPickUp + frontNeckPickUp
      } sts around the neck`,
      sub: [
        `Along the back neck, pu ${neckBackPickUp} sts`,
        `Along the front neck, pu ${frontNeckPickUp} sts`,
      ],
    },
    {
      main: `🪡 Work kp1p rib for ${getRound(neckBand * RIB_MULTIPLIER)} rows`,
    },
    {
      main: `➖  Dec 2 sts at row ${getRound(neckBand / 2)} around`,
      sub: [`both sides of front neck curve (k1p1k1 to k1), total 4 sts dec'd`],
    },
    {
      main: getBONote(neckBackPickUp + frontNeckPickUp - 4, `Sewn On`),
    },
  ];

  const part10: PatternPart = {
    chart: [],
    details: neckBandInstruction,
    ...commonLastPartInstruction,
  };

  const bodyParts = [
    {
      overviewChart: getSlicedChartFromBasePattern(
        backBasePattern,
        [0, shoulderToArm],
        [0, halfBody]
      ),
      parts: [part1, part2, part3],
    },
    {
      overviewChart: getSlicedChartFromBasePattern(
        frontBasePattern,
        [0, shoulderToArm],
        [0, frontBody]
      ),
      parts: [part4, part5, part6],
    },
    {
      parts: [part7],
    },
  ];

  return patternType === "vest"
    ? [
        ...bodyParts,
        {
          parts: [part8, part9, part10],
        },
      ]
    : [
        ...bodyParts,
        {
          parts: [part8, part9],
        },
        {
          parts: [part10],
        },
      ];
}

export function getPatternByPartGroup(
  patternType: "vest" | "pullover",
  backChart: Chart,
  frontChart: Chart,
  stsGauge: number,
  rowGauge: number,
  gaugeItems: SetInSleeveGaugeProps,
  coNeedle: Needle,
  ribNeedle: Needle,
  motifOffset: number = 0
): PatternPartGroup[] {
  // make a base pattern from motif repeats
  const backBasePattern = getBasePatternFromChart(
    backChart,
    gaugeItems.totalRowCount,
    motifOffset
  );
  const frontBasePattern = getBasePatternFromChart(
    frontChart,
    gaugeItems.totalRowCount,
    motifOffset
  );

  const chartByPart = getPattern(
    patternType,
    backBasePattern,
    frontBasePattern,
    stsGauge,
    rowGauge,
    gaugeItems,
    coNeedle,
    ribNeedle
  );
  return chartByPart;
}
