import { getRound } from "@tanyoknits/shared";
import { VestMeasurement } from "../../utils/sizeBase";
import { SetInSleeveVestGaugeProps } from "./types";
import { getCoreGaugeItems } from "./common";

export function getGaugeItems(
  ms: VestMeasurement,
  stsGauge: number,
  rowGauge: number,
  hasOddSts: boolean = false,
  adjHalfBody?: number
): SetInSleeveVestGaugeProps {
  const coreItems = getCoreGaugeItems(
    ms,
    stsGauge,
    rowGauge,
    hasOddSts,
    adjHalfBody
  );

  return { ...coreItems, armBand: getRound(ms.arm_band_height * rowGauge) };
}

export function getTotalSts(
  ms: VestMeasurement,
  stsGauge: number,
  rowGauge: number
): number {
  const bodyRect =
    ms.total_length * rowGauge * ms.bust_circumference * stsGauge;
  // neck triangle
  const neck = (ms.neck_back_depth * rowGauge * ms.neck_width * stsGauge) / 2;
  const arm =
    (ms.armhole_depth - ms.arm_band_height) *
    rowGauge *
    (ms.arm_inset_width - ms.arm_band_height) *
    stsGauge;

  return bodyRect - neck - arm * 2;
}
