import { ProjectMenu, ProjectOverview } from "../utils/types";
import { sizes as pinePullOverSizes } from "./pine-pullover/sizes";
import { sizes as tiaraVestSizes } from "./tiara-vest/sizes";
import { sizes as JuniperVestSizes } from "./juniper-vest/sizes";
import { sizes as SalviaCardiganSizes } from "./salvia-cardigan/sizes";
import { sizes as SerenaTShirtSizes } from "./serena-t-shirt/sizes";

const PROJECT_IDS = [
  "pine-pullover",
  "tiara-vest",
  "juniper-vest",
  "salvia-cardigan",
];

type projectTuple = typeof PROJECT_IDS;
export type ProjectID = projectTuple[number];

export const allProjects: Record<ProjectID, ProjectOverview> = {
  "pine-pullover": {
    name: "Pine Pullover",
    features: ["🧶 Bulky", "⬇️ Top down", "✨ Twist"],
    sizes: pinePullOverSizes,
    menus: ["intro", "stitches", "pattern-by-size"],
    theme: "brown",
  },
  "tiara-vest": {
    name: "Tiara Vest",
    features: ["🧶 Worsted", "⬇️ Top down", "✨ Cables"],
    sizes: tiaraVestSizes,
    menus: ["intro", "stitches", "pattern-by-size"],
  },
  "juniper-vest": {
    name: "Juniper Vest",
    features: ["🧶 Worsted", "⬇️ Top down", "✨ Lace"],
    sizes: JuniperVestSizes,
    menus: ["intro", "stitches", "pattern-by-size"],
    isWIP: true,
  },
  "salvia-cardigan": {
    name: "Salvia Cardigan",
    features: ["🧶 Sport", "⬇️ Top down", "✨ Cable & Lace"],
    sizes: SalviaCardiganSizes,
    menus: ["intro", "stitches", "pattern-by-size"],
    isPrivate: true,
  },
  "serena-t-shirt": {
    name: "Serena T-shirt",
    features: ["🧶 DK", "⬇️ Top down", "✨ Lace"],
    sizes: SerenaTShirtSizes,
    menus: ["intro", "stitches", "pattern-by-size"],
    isPrivate: true,
  },
  "big-winter-sweater": {
    name: "Big Winter Sweater",
    features: ["🧶 Bulky", "⬇️ Top down", "✨ Cables"],
    sizes: SerenaTShirtSizes,
    menus: ["intro", "stitches", "pattern-by-size"],
    theme: "cyan",
    isPrivate: true,
  },
};

export const projectMenus: Record<ProjectMenu, string> = {
  intro: "Introduction",
  stitches: "Stitches & Techniques",
  pattern: "Pattern",
  "pattern-by-size": "Pattern by Size",
};
