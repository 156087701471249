import { Stitch, StsID } from "../utils/types";
import { K, P, MB, KOCO } from "./glyphs/BasicSts";
import {
  C6L,
  C6R,
  C21LP,
  C21RP,
  C2L,
  C2R,
  C4R,
  C4L,
  C22LP,
  C22RP,
  C8R,
  C8L,
  C12L,
  C12R,
} from "./glyphs/Cable";
import { LLI, M1L, M1R, RLI, YO } from "./glyphs/Increase";
import { EMPTY, SR_RS, SR_WS } from "./glyphs/NonSts";
import { K2TOG, P2TOG, S2KP2, S2PP2, SSK, SSP } from "./glyphs/Decrease";

const definition: Record<Partial<StsID>, Stitch> = {
  empty: {
    stsID: "empty",
    label: "No sts",
    instruction: "skip",
    unitWidth: 1,
    glyph: EMPTY,
    isNonSts: true,
  },
  k: {
    stsID: "k",
    label: "k",
    instruction: "Knit",
    glyph: K,
    ws: "p",
  },
  p: {
    stsID: "p",
    label: "p",
    instruction: "Purl",
    glyph: P,
    ws: "k",
  },
  mb: {
    stsID: "mb",
    label: "MB",
    instruction: "Make Bobble",
    unitWidth: 1,
    glyph: MB,
  },
  koco: {
    stsID: "koco",
    label: "koco",
    instruction: "Cast On with Knit-On method",
    unitWidth: 1,
    glyph: KOCO,
    ws: "koco",
  },
  c21lp: {
    stsID: "c21lp",
    label: "2/1-LPC",
    instruction:
      "2/1 Left Purl Cross: Slip 2 st to cn, hold to front, p1, k2 from cn",
    glyph: C21LP,
    unitWidth: 3,
    rightLeftFlip: "c21rp",
    topDownFlip: "c21rp",
    rawSts: ["p", "k", "k"],
  },
  c21rp: {
    stsID: "c21rp",
    label: "2/1-RPC",
    instruction:
      "2/1 Right Purl Cross: Slip 1 st to cn, hold to back, k2, p1 from cn",
    glyph: C21RP,
    unitWidth: 3,
    rightLeftFlip: "c21lp",
    topDownFlip: "c21lp",
    rawSts: ["k", "k", "p"],
  },
  c22lp: {
    stsID: "c22lp",
    label: "2/2-LPC",
    unitWidth: 4,
    glyph: C22LP,
    instruction:
      "2/2 Left Purl Cross: Slip 2 st to cn, hold to front, p1, k2 from cn",
    rightLeftFlip: "c22rp",
    topDownFlip: "c22rp",
    rawSts: ["p", "p", "k", "k"],
  },
  c22rp: {
    stsID: "c22rp",
    label: "2/2-RPC",
    unitWidth: 4,
    glyph: C22RP,
    instruction:
      "2/2 Right Purl Cross: Slip 2 st to cn, hold to back, k2, p2 from cn",
    rightLeftFlip: "c22lp",
    topDownFlip: "c22lp",
    rawSts: ["k", "k", "p", "p"],
  },
  c2l: {
    stsID: "c2l",
    label: "LT",
    unitWidth: 2,
    instruction: "Left twist",
    glyph: C2L,
    rightLeftFlip: "c2r",
    topDownFlip: "c2r",
    rawSts: ["k", "k"],
    videos: [{ url: "https://www.youtube.com/watch?v=rdke9a0fbng" }],
  },
  c2r: {
    stsID: "c2r",
    label: "RT",
    unitWidth: 2,
    instruction: "Right twist",
    glyph: C2R,
    rightLeftFlip: "c2l",
    topDownFlip: "c2l",
    rawSts: ["k", "k"],
  },
  c4l: {
    stsID: "c4l",
    label: "2/2-LC",
    unitWidth: 4,
    instruction:
      "2/2 Left Cross: Slip 2 st to cn, hold to front, k2, k2 from cn",
    glyph: C4L,
    rightLeftFlip: "c4r",
    topDownFlip: "c4r",
    rawSts: ["k", "k", "k", "k"],
  },
  c4r: {
    stsID: "c4r",
    label: "2/2-RC",
    unitWidth: 4,
    instruction:
      "2/2 Right Cross: Slip 2 st to cn, hold to back, k2, k2 from cn",
    glyph: C4R,
    rightLeftFlip: "c4l",
    topDownFlip: "c4l",
    rawSts: ["k", "k", "k", "k"],
  },
  c6l: {
    stsID: "c6l",
    label: "3/3-LC",
    instruction:
      "3/3 Left Cross: Slip 3 st to cn, hold to front, k3, k3 from cn",
    glyph: C6L,
    unitWidth: 6,
    rightLeftFlip: "c6r",
    topDownFlip: "c6r",
    rawSts: ["k", "k", "k", "k", "k", "k"],
  },
  c6r: {
    stsID: "c6r",
    label: "3/3-RC",
    instruction:
      "3/3 Right Cross - Slip 3 st to cn, hold to back, k3, k3 from cn",
    glyph: C6R,
    unitWidth: 6,
    rightLeftFlip: "c6l",
    topDownFlip: "c6l",
    rawSts: ["k", "k", "k", "k", "k", "k"],
  },
  c8l: {
    stsID: "c8l",
    label: "4/4-LC",
    instruction:
      "4/4 Left Cross: Slip 4 st to cn, hold to front, k4, k4 from cn",
    glyph: C8L,
    unitWidth: 8,
    rightLeftFlip: "c8r",
    topDownFlip: "c8r",
    rawSts: ["k", "k", "k", "k", "k", "k", "k", "k"],
  },
  c8r: {
    stsID: "c8r",
    label: "4/4-RC",
    instruction:
      "4/4 Right Cross: Slip 4 st to cn, hold to back, k4, k4 from cn",
    glyph: C8R,
    unitWidth: 8,
    rightLeftFlip: "c8l",
    topDownFlip: "c8l",
    rawSts: ["k", "k", "k", "k", "k", "k", "k", "k"],
  },
  c12l: {
    stsID: "c12l",
    label: "6/6-LC",
    instruction:
      "6/6 Left Cross: Slip 6 st to cn, hold to front, k6, k6 from cn",
    glyph: C12L,
    unitWidth: 12,
    rightLeftFlip: "c12r",
    topDownFlip: "c12r",
    rawSts: ["k", "k", "k", "k", "k", "k", "k", "k", "k", "k", "k", "k"],
  },
  c12r: {
    stsID: "c12r",
    label: "6/6-RC",
    instruction:
      "6/6 Right Cross: Slip 6 st to cn, hold to back, k6, k6 from cn",
    glyph: C12R,
    unitWidth: 12,
    rightLeftFlip: "c12l",
    topDownFlip: "c12l",
    rawSts: ["k", "k", "k", "k", "k", "k", "k", "k", "k", "k", "k", "k"],
  },
  lli: {
    stsID: "lli",
    label: "LLI",
    instruction: "Make 1 Left by lifting the previous knit sts",
    unitWidth: 1,
    glyph: LLI,
    ws: "rlip",
    rightLeftFlip: "rli",
    topDownFlip: "rli",
    videos: [{ url: "https://youtu.be/aTVKc4EzO-w" }],
  },
  rli: {
    stsID: "rli",
    label: "RLI",
    instruction: "Make 1 Right by lifting the next knit sts",
    unitWidth: 1,
    glyph: RLI,
    ws: "llip",
    rightLeftFlip: "lli",
    topDownFlip: "lli",
  },
  llip: {
    stsID: "llip",
    label: "LLI-P",
    instruction: "Make 1 Left by lifting the previous purl sts",
    unitWidth: 1,
    glyph: RLI,
    ws: "lli",
    rightLeftFlip: "rlip",
    topDownFlip: "rlip",
    videos: [{ url: "https://www.youtube.com/watch?v=M6Zcgs83eVI" }],
  },
  rlip: {
    stsID: "rlip",
    label: "RLI-P",
    instruction: "Make 1 Right by lifting the next purl sts",
    unitWidth: 1,
    glyph: RLI,
    ws: "rli",
    rightLeftFlip: "llip",
    topDownFlip: "llip",
  },
  yo: {
    stsID: "yo",
    label: "YO",
    instruction: "Yarn over",
    glyph: YO,
    rawSts: ["k"],
  },
  k2tog: {
    stsID: "k2tog",
    label: "k2tog",
    instruction: "Knit two stitches together",
    glyph: K2TOG,
    rightLeftFlip: "ssk",
    ws: "p2tog",
    videos: [{ url: "https://www.youtube.com/watch?v=JSTQjOCyfhI" }],
    rawSts: ["k"],
  },
  ssk: {
    stsID: "ssk",
    label: "ssk",
    instruction: "Slip, Slip, knit",
    glyph: SSK,
    rightLeftFlip: "k2tog",
    ws: "ssp",
    rawSts: ["k"],
  },
  p2tog: {
    stsID: "p2tog",
    label: "p2tog",
    instruction: "Purl two stitches together",
    glyph: P2TOG,
    rightLeftFlip: "ssp",
    ws: "k2tog",
  },
  ssp: {
    stsID: "ssp",
    label: "ssp",
    instruction: "Slip, Slip, Purl",
    glyph: SSP,
    rightLeftFlip: "p2tog",
    ws: "ssk",
  },
  s2kp2: {
    stsID: "s2kp2",
    label: "s2kp2",
    instruction:
      "Slip 2 stitches, knit next, pass over the slipped stitches to the next stitch",
    glyph: S2KP2,
    ws: "s2pp2",
  },
  s2pp2: {
    stsID: "s2pp2",
    label: "s2pp2",
    instruction:
      "Slip 2 stitches, purl next, pass over the slipped stitches to the next stitch",
    glyph: S2PP2,
    ws: "s2kp2",
  },
  ktbl: {
    stsID: "ktbl",
    label: "k-tbl",
    unitWidth: 1,
    instruction: "Knit through the back loop",
  },
  sr_rs: {
    stsID: "sr_rs",
    label: "w & t",
    unitWidth: 1,
    glyph: SR_RS,
    instruction: "Wrap and turn to WS",
    rightLeftFlip: "sr_ws",
    isNonSts: true,
  },
  sr_ws: {
    stsID: "sr_ws",
    label: "w & t",
    unitWidth: 1,
    instruction: "Wrap and turn to RS",
    glyph: SR_WS,
    rightLeftFlip: "sr_rs",
    isNonSts: true,
  },
  cy_rs: {
    stsID: "cy_rs",
    label: "cut yarn",
    unitWidth: 1,
    instruction: "Cut yarn",
    ws: "cy_ws",
    isNonSts: true,
  },
  cy_ws: {
    stsID: "cy_ws",
    label: "cut yarn",
    unitWidth: 1,
    instruction: "Cut yarn",
    rightLeftFlip: "cy_rs",
    isNonSts: true,
  },
  nc: {
    stsID: "nc",
    label: "leave the yarn",
    unitWidth: 0,
    instruction: "Do not cut yarn, leave it for later use",
  },
  ny_rs: {
    stsID: "ny_rs",
    label: "New Yarn",
    unitWidth: 1,
    instruction: "New yarn starts here",
  },
  ny_ws: {
    stsID: "ny_ws",
    label: "New Yarn",
    unitWidth: 1,
    instruction: "New yarn starts here",
  },
  m1r: {
    stsID: "m1r",
    label: "m1r",
    glyph: M1R,
    unitWidth: 1,
    instruction: "Make 1 sts on the right",
  },
  m1l: {
    stsID: "m1r",
    label: "m1r",
    glyph: M1L,
    unitWidth: 1,
    instruction: "Make 1 sts on the right",
    videos: [{ url: "https://www.youtube.com/watch?v=XygPZ2cWBeA" }],
  },
};

export default definition;
