import ExplanationList from "./ExplanationList";

interface Props {
  list: {
    title: string;
    desc: string;
    video: string | undefined;
  }[];
}
export default function Techniques({ list }: Props) {
  return (
    <ExplanationList
      overview="Following techniques are used in the pattern instruction."
      list={list}
    />
  );
}
