import React from "react";
import ReactDOM from "react-dom";
import Main from "./layout/Main";
import { HashRouter, Route, Routes } from "react-router-dom";
import Project from "./layout/Project";
import "./index.css";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@tanyoknits/shared";
import { appTheme } from "./styles";
import Introduction from "./layout/Introduction";
import PageWithMenus from "./layout/PageWithMenus";
import PatternBySize from "./layout/PatternBySize";
import PatternPart from "./layout/PatternPart";
import Print from "./layout/Print";
import Stitches from "./layout/Stitches";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={{ ...theme, ...appTheme }}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/:projectID/print" element={<Print />} />
          <Route path="/:projectID" element={<Project />}>
            <Route index element={<Introduction />} />
            <Route path="intro" element={<Introduction />} />
            <Route path="stitches" element={<PageWithMenus page="stitches" />}>
              <Route index element={<Stitches />} />
              <Route path=":menu" element={<Stitches />} />
            </Route>
            <Route path="pattern-by-size" element={<PatternBySize />}>
              <Route index element={<PatternPart />} />
              <Route path=":part" element={<PatternPart />} />
            </Route>
          </Route>
          <Route path="*" element={<div>No match</div>} />
        </Routes>
      </HashRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
