import { needles } from "../../utils/needles";
import { ProjectProfile } from "../../utils/types";

const profile: ProjectProfile = {
  yarn: {
    maker: "Knit Picks",
    line: "Wool of the Andes Superwash Worsted",
    color: "Camel Heather",
    url: "https://www.knitpicks.com/camel-heather/p/26305",
    shape: "skein",
    yards: 110,
    grams: 50,
  },
  needles: [
    {
      type: "circular",
      size: needles[10],
      usage: "Body",
    },
    { type: "circular", size: needles[8], usage: "Bands" },
  ],
  gauge: {
    sts: "Stockinette stitch",
    sts_count: 20,
    row_count: 25,
    needle: needles[10],
  },
  notions: ["Cable Needle", "Tapestry needle", "Stitch markers"],
  difficulty: "Intermediate",
  photoCount: 1,
};

export default profile;
