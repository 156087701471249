import { RadioGroup, Radio, Flex } from "@chakra-ui/react";

interface Props {
  menu: string;
  setMenu: (arg0: string) => void;
  options: string[];
}

export default function DisplayOptions({ menu, setMenu, options }: Props) {
  return (
    <RadioGroup onChange={setMenu} value={menu.toString()}>
      <Flex gap={4}>
        {options.map((option, i) => (
          <Radio key={i} value={(i + 1).toString()}>
            {option}
          </Radio>
        ))}
      </Flex>
    </RadioGroup>
  );
}
