export function getRound(value: number, roundBy: number = 1): number {
  return Math.round(value / roundBy) * roundBy;
}

export function getEven(value: number): number {
  return getRound(value, 2);
}

export function getFloor(value: number, roundBy: number): number {
  return Math.floor(value / roundBy) * roundBy;
}

function getCMFromInch(
  inch: number,
  cmRoundedBy: number = 0.5
): [number, number] {
  const inchRounded = getRound(inch, 0.25);
  const cm = getRound(2.54 * inchRounded, cmRoundedBy);
  return [inchRounded, cm];
}

export function getInchAndCm(inch: number, whitespace: string = " / "): string {
  if (inch === 0) {
    return "N/A";
  }
  const [inchRounded, cm] = getCMFromInch(inch);
  return `${inchRounded}"${whitespace}${cm}cm`;
}

type SizeRange = [number | null, number | null];

export function getInchRange(inches: number[]): SizeRange[] {
  return inches.map((inch, i) => {
    let prev = i > 0 ? inches[i - 1] : null;
    let next = i < inches.length - 1 ? inches[i + 1] : null;
    if (prev == null) {
      prev = inch - ((next ?? inch) - inch);
    }
    if (next == null) {
      next = inch + (inch - (prev ?? inch));
    }
    return [inch - (inch - prev) / 2, inch + (next - inch) / 2];
  });
}

export function getInchAndCmRange(range: SizeRange): string[] {
  const prevVals = range[0] != null ? getCMFromInch(range[0], 1) : "";
  const nextVals = range[1] != null ? getCMFromInch(range[1], 1) : "";
  return [
    `${prevVals[0]}" – ${nextVals[0]}"`,
    `${prevVals[1]}cm - ${nextVals[1]}cm`,
  ];
}

export function getYdAndM(yd: number, whitespace: string = " / "): string {
  return `${Math.round(yd)}yd${whitespace}${Math.round(yd * 0.9144)}m`;
}

export function getSum(values: number[]): number {
  return values.reduce((agg, cur) => (agg += cur), 0);
}

export function getLeastCommonMultiple(values: number[]): number {
  const max = Math.max(...values);
  // recursive until all mods are 0
  function getModSum(multiplier: number): number {
    for (let i = 0; i < values.length; i++) {
      if ((max * multiplier) % values[i] > 0) {
        return getModSum(multiplier + 1);
      }
    }
    return max * multiplier;
  }
  return getModSum(1);

  // const maxNumber = Math.max(...values);
  // let multiplier = 1;
  // while (
  //   // eslint-disable-next-line no-loop-func
  //   values.reduce((agg, cur) => agg + ((maxNumber * multiplier) % cur), 0) > 0
  // ) {
  //   multiplier += 1;
  // }
  // return maxNumber * multiplier;
}
