import { HStack, VStack, Text } from "@chakra-ui/react";
import { cx } from "@linaria/core";
import { M1_RADIUS, MARKER_RADIUS, styles } from "./PulloverShortRowDiagram";

const SYMBOL_WIDTH = 40;
const SYMBOL_HEIGHT = 20;

export default function ShortRowLegends() {
  return (
    <VStack
      align="left"
      style={{ border: "1px solid var(--oc-gray-4)" }}
      padding={4}
      borderRadius={8}
    >
      <HStack spacing={4}>
        <svg width={SYMBOL_WIDTH} height={SYMBOL_HEIGHT}>
          <line
            x1={0}
            y1={SYMBOL_HEIGHT / 2}
            x2={SYMBOL_WIDTH}
            y2={SYMBOL_HEIGHT / 2}
            className={cx(styles.shortRowPath, styles.rs)}
          />
        </svg>
        <Text>Short row (odd): work on RS</Text>
      </HStack>
      <HStack spacing={4}>
        <svg width={SYMBOL_WIDTH} height={SYMBOL_HEIGHT}>
          <line
            x1={0}
            y1={SYMBOL_HEIGHT / 2}
            x2={SYMBOL_WIDTH}
            y2={SYMBOL_HEIGHT / 2}
            className={cx(styles.shortRowPath, styles.ws)}
          />
        </svg>
        <Text>Short row (even): work on WS</Text>
      </HStack>
      <HStack spacing={4}>
        <svg
          width={SYMBOL_WIDTH}
          height={SYMBOL_HEIGHT}
          style={{ overflow: "visible" }}
        >
          <circle
            cx={SYMBOL_WIDTH / 2}
            cy={SYMBOL_HEIGHT / 2}
            r={MARKER_RADIUS}
            className={cx(styles.markerbg)}
          />
          <text
            x={SYMBOL_WIDTH / 2}
            y={SYMBOL_HEIGHT / 2 - 1}
            className={cx(styles.markerChar)}
          >
            A
          </text>
        </svg>
        <Text>
          Body & sleeve split markers at raglan inc (A, B, C, D): do not move
        </Text>
      </HStack>
      <HStack spacing={4}>
        <svg
          width={SYMBOL_WIDTH}
          height={SYMBOL_HEIGHT}
          style={{ overflow: "visible" }}
        >
          <circle
            cx={SYMBOL_WIDTH / 2}
            cy={SYMBOL_HEIGHT / 2}
            r={MARKER_RADIUS}
            className={cx(styles.markerbgInv)}
          />
          <text
            x={SYMBOL_WIDTH / 2}
            y={SYMBOL_HEIGHT / 2 - 1}
            className={cx(styles.markerCharInv)}
          >
            R
          </text>
        </svg>
        <Text>
          SR guide markers on each side (RS & WS): moves as SR sts are added
        </Text>
      </HStack>
      <HStack spacing={4}>
        <svg
          width={SYMBOL_WIDTH}
          height={SYMBOL_HEIGHT}
          style={{ overflow: "visible" }}
        >
          <circle
            cx={SYMBOL_WIDTH / 2}
            cy={SYMBOL_HEIGHT / 2}
            r={M1_RADIUS}
            className={cx(styles.m1rs)}
          />
        </svg>
        <Text>M1 on RS</Text>
      </HStack>
      <HStack spacing={4}>
        <svg
          width={SYMBOL_WIDTH}
          height={SYMBOL_HEIGHT}
          style={{ overflow: "visible" }}
        >
          <circle
            cx={SYMBOL_WIDTH / 2}
            cy={SYMBOL_HEIGHT / 2}
            r={M1_RADIUS}
            className={cx(styles.m1ws)}
          />
        </svg>
        <Text>M1 on WS</Text>
      </HStack>
    </VStack>
  );
}
