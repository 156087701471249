import React, { lazy, Suspense, useDeferredValue } from "react";
import { useProject } from "./Project";
import { usePatternBySize } from "./PatternBySize";

export default function PatternPart() {
  const { projectID } = useProject();

  const Instruction = lazy(
    () => import(`../projects/${projectID}/Instruction`)
  );
  const { menu, size } = usePatternBySize();
  const deferred = useDeferredValue({ menu, size });

  return (
    <Suspense fallback={<div>Loading patterns for all sizes...</div>}>
      {deferred.menu !== menu || deferred.size !== size ? (
        <div>Loading pattern...</div>
      ) : (
        <Instruction menu={menu} size={size} />
      )}
    </Suspense>
  );
}
