import { css, LinariaClassName } from "@linaria/core";
import { MotifColor } from "../../utils/types";

export const motifColors: Record<MotifColor, LinariaClassName> = {
  red: css`
    border-color: var(--oc-red-6) !important;
    background-color: var(--oc-red-1);
  `,
  pink: css`
    border-color: var(--oc-pink-6) !important;
    background-color: var(--oc-pink-1);
  `,
  grape: css`
    border-color: var(--oc-grape-6) !important;
    background-color: var(--oc-grape-1);
  `,
  violet: css`
    border-color: var(--oc-violet-6) !important;
    background-color: var(--oc-violet-1);
  `,
  indigo: css`
    border-color: var(--oc-indigo-6) !important;
    background-color: var(--oc-indigo-1);
  `,
  blue: css`
    border-color: var(--oc-blue-6) !important;
    background-color: var(--oc-blue-1);
  `,
  cyan: css`
    border-color: var(--oc-cyan-1) !important;
    background-color: var(--oc-cyan-1);
  `,
  teal: css`
    border-color: var(--oc-teal-6) !important;
    background-color: var(--oc-teal-1);
  `,
  green: css`
    border-color: var(--oc-green-6) !important;
    background-color: var(--oc-green-1);
  `,
  lime: css`
    border-color: var(--oc-lime-6) !important;
    background-color: var(--oc-lime-1);
  `,
  orange: css`
    border-color: var(--oc-orange-6) !important;
    background-color: var(--oc-orange-1);
  `,
};

export const patternStyles = {
  selectedSts: css`
    box-shadow: 0 0 2px 2px var(--theme-8);
    // Show the shadow(outline) effect over the other stitches;
    background-color: var(--theme-1);
    z-index: 2;
  `,
};

export const unit = 8;
