import { useMemo, useState } from "react";
import { PageInfo, PatternInfo } from "../utils/types";
import {
  useParams,
  Outlet,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import { Box, Divider, Flex } from "@chakra-ui/react";
import PageMenu from "./PageMenu";
import SelectSize from "../components/SelectSize";
import { useProject } from "./Project";

export interface PatternBySizeProps {
  menu: number;
  size: number;
}

export default function PatternBySize() {
  const [patternInfo, setPatternInfo] = useState<PatternInfo | null>(null);
  const [selectedSize, setSelectedSize] = useState<number | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<number | null>(null);

  const params = useParams();
  const { projectID } = useProject();
  const [searchParams, setSearchParams] = useSearchParams();

  // Load project info
  useMemo(() => {
    (async () => {
      // Store pattern into to context
      const info = await import(`../projects/${projectID}/pattern`);
      setPatternInfo(info.patternInfo);

      const { sizes, pageInfo } = info.patternInfo;

      // Size from URL
      // since + isn't recognized by searchParams, use location
      let size = searchParams.get("size");
      if (size?.charAt(size.length - 1) === " ") {
        size = `${size?.slice(0, size.length - 1)}+`;
      }
      if (size == null) {
        // If size isn't set in URL, set the first size in the URL
        setSearchParams({ size: sizes[0] });
      } else {
        // If size exists in the URL, update the Select value
        setSelectedSize(Math.max(sizes.indexOf(size), 0));
      }

      // Menu from URL
      const part = params.part ?? pageInfo[0].id;
      setSelectedMenu(
        // Update part menu
        pageInfo.map((p: PageInfo) => p.id).indexOf(part)
      );
    })();
  }, [params.part, projectID, searchParams, setSearchParams]);

  if (patternInfo == null || selectedMenu == null || selectedSize == null) {
    return null;
  }

  const { sizes, pageInfo } = patternInfo;

  const onChange = (id: number) => {
    setSelectedSize(id);
    setSearchParams({ size: sizes[id] });
  };

  return (
    <Box
      style={{ background: "white" }}
      p={4}
      ml={2}
      mr={2}
      color="var(--oc-gray-8)"
      borderRadius={8}
    >
      <Flex gap={6} flexWrap="wrap" align="center">
        <SelectSize
          sizes={sizes}
          selectedSize={selectedSize}
          onChange={onChange}
        />
        <PageMenu
          pageInfo={pageInfo}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
          searchQueries={[
            { term: "size", value: patternInfo.sizes[selectedSize] },
          ]}
        />
      </Flex>
      <Divider pt={4} mb={4} variant="dashed" />
      <Outlet
        context={{
          projectID,
          menu: selectedMenu,
          size: selectedSize,
        }}
      />
    </Box>
  );
}

export function usePatternBySize() {
  return useOutletContext<PatternBySizeProps>();
}
