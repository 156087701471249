import { useState } from "react";
import { Direction, Motif } from "../utils/types";
import { cx } from "@linaria/core";
import Chart, { FocusType } from "./Chart";
import Written from "./Written";
import {
  Flex,
  Text,
  VStack,
  HStack,
  StackDivider,
  Box,
} from "@chakra-ui/react";
import DisplayOptions from "../../../interactive-patterns/src/components/DisplayOptions";

interface Props extends Motif {
  direction?: Direction;
  className?: string;
}

export default function InteractiveMotif({
  title,
  stsCount,
  chart,
  stEquivCount,
  direction,
  glyphSize = "L",
  className,
}: Props) {
  const [menu, setMenu] = useState<string>("1");
  const [focus, setFocus] = useState<FocusType>([null, null]);

  const selectedDirection: Direction =
    direction != null ? direction : menu === "1" ? "round" : "flat";

  return (
    <VStack align="left" className={cx(className)} spacing={4}>
      <HStack gap={4} divider={<StackDivider borderColor="gray.300" />}>
        {direction == null && (
          <DisplayOptions
            menu={menu}
            setMenu={setMenu}
            options={["In the round", "Flat"]}
          />
        )}
        <Text>
          Repeat <strong>{stsCount}</strong> stitches x{" "}
          <strong>{chart.length}</strong>
          {selectedDirection === "round" ? " rounds" : " rows"}
          {stEquivCount != null && (
            <span>
              : Equivalent Stockinette stitch count:{" "}
              <strong>{stEquivCount}</strong> sts{" "}
            </span>
          )}
        </Text>
      </HStack>
      <Flex flexWrap="wrap" gap={4}>
        <Box overflowX="auto" pt={1}>
          <Chart
            chart={chart}
            size={glyphSize}
            direction={selectedDirection}
            focus={focus}
            setFocus={setFocus}
          />
        </Box>
        <Written chart={chart} direction={selectedDirection} focus={focus} />
      </Flex>
    </VStack>
  );
}
